import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import {connect} from 'react-redux';
import FileUploader from "../../FileUploader/FileUploader";
import VideoEditTab from "../../VideoEditTab/VideoEditTab";
import UserEditTab from "../UserEditTab/UserEditTab";
import StatisticsTab from "./StatisticsTab/StatisticsTab";
import SettingsTab from './SettingsTab/SettingsTab';
import {withRouter} from 'react-router-dom';

import * as AppActions from '../../../store/ducks/app';

const styles = theme => ({
    root: {
        borderBottom: '2px solid #eee'
    }
});

class AdminPanel extends Component {
    state = {
        currentTab: 0
    };

    componentDidMount() {
        this.props.onDummyPageInit(this.props.history);
    }

    handleTabChange = (event, newValue) => {
        this.setState({ currentTab: newValue });
    };

    render() {

        let panels = [];
        let tabs = [];

        panels = [
            <SettingsTab key={1} />
        ];
        tabs = [
            <Tab label="Settings" key={1} />
        ];

        if(this.props.stateUserType === 2){
            panels = [
                <VideoEditTab key={1} />,
                <SettingsTab key={2} />,
                <StatisticsTab key={3} />
            ];
            tabs = [
                <Tab label="Edit Videos" key={1} />,
                <Tab label="Settings" key={2} />,
                <Tab label="Statistics" key={3} />
            ];
        }else if(this.props.stateUserType === 3){
            panels = [
                <FileUploader key={1} />,
                <VideoEditTab key={2} />,
                <UserEditTab key={3} />,
                <SettingsTab key={4} />,
                <StatisticsTab key={5} />
            ];
            tabs = [
                <Tab label="Upload Videos" key={1} />,
                <Tab label="Edit Videos" key={2} />,
                <Tab label="Edit Users" key={3} />,
                <Tab label="Settings" key={4} />,
                <Tab label="Statistics" key={5} />
            ];
        }

        let tabPanel = panels[this.state.currentTab];

        // let tabPanel = <FileUploader />;
        // if(this.state.currentTab === 1){
        //     tabPanel = <VideoEditTab />;
        // }
        // if(this.state.currentTab === 2){
        //     tabPanel = <UserEditTab />;
        // }
        // if(this.state.currentTab === 3){
        //     tabPanel = <StatisticsTab />;
        // }

        return (
            <div>
                {/* <Paper className={this.props.classes.root}> */}
                    <Tabs
                        value={this.state.currentTab}
                        onChange={this.handleTabChange}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                        className={this.props.classes.root}
                    >
                        {tabs}
                    </Tabs>
                
                <div>
                    {tabPanel}
                </div>
                {/* </Paper> */}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        stateUserType: state.app.userType
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onDummyPageInit: (history) => dispatch(AppActions.dummyPageInit(history)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminPanel)));

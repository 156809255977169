import React, { Component } from 'react';
import { connect } from 'react-redux';
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';
import SubCard from '../../WelcomePage/SubCard/SubCard';
import config from '../../../../config/config';

import * as appActions from '../../../../store/ducks/app';
import * as classes from './ChoosePlanPage.module.css';

const styles = theme => ({
    text: {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
        fontSize: "2.5rem",
        fontWeight: "200",
        lineHeight: "3rem",
        color: '#FFF',
        margin: '1rem'
    },
});

class ChoosePlanPage extends Component {

    state = {
        windowObjectReference: null,
        previousUrl: null
    }

    componentDidMount() {
        this.props.onSignupInfoBegin();
    }

    chooseBasicPlan = () => {
        this.props.onEditChosenPlan(1);
        if(!this.props.stateUserLoggedInStatus){
            this.props.history.push('/signup/createaccount');
        }else{
            this.props.history.push('/signup/addpayment');
        }
    }

    chooseUnlimitedPlan = () => {
        this.props.onEditChosenPlan(2);
        if(!this.props.stateUserLoggedInStatus){
            this.props.history.push('/signup/createaccount');
        }else{
            this.props.history.push('/signup/addpayment');
        }
    }

    render() {
        return (
            <div>
                <div className={classes.pageHeader}>
                    <div className={classes.pageHeaderBar}>
                        <Typography onClick={() => this.props.history.push('/welcome')} className={classes.homeButton}>
                            {config.demo ? 'Home' : 'SprkGlobal'}
                        </Typography>
                        {!this.props.stateUserLoggedInStatus ? <Typography onClick={() => this.props.history.push('/login')} className={classes.loginButton}>
                            LOG IN
                        </Typography> :
                        <Typography onClick={() => this.props.onLogout(this.props.history)} className={classes.loginButton}>
                            LOG OUT
                        </Typography>}
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ borderBottom: '2px solid #ccc', maxWidth: '400px', width: '75%', marginBottom: '1.5rem', display: 'flex', justifyContent: 'center' }}>
                        <Typography style={{ margin: '1rem', marginTop: '2rem', fontSize: '1.5rem', fontWeight: '500' }}>
                            Choose Plan
                        </Typography>
                    </div>
                </div>
                <div className={classes.subCardSection}>
                    <SubCard
                        planName={'BASIC'}
                        cost={this.props.stateSignupBasicCost}
                        onSelect={this.chooseBasicPlan}
                        info={['Get access to 300 minutes per month of exclusive series, hit movies, and more',
                        'Enjoy an ad free viewing experience',
                        'Switch plans or cancel anytime']} />
                    <SubCard
                        planName={'UNLIMITED'}
                        cost={this.props.stateSignupUnlimitedCost}
                        onSelect={this.chooseUnlimitedPlan}
                        info={['Get unlimited access to exclusive series, hit movies, and more',
                        'Enjoy an ad free viewing experience',
                        'Switch plans or cancel anytime']} />
                </div>
            </div>
        );
    }
};



const mapStateToProps = state => {
    return {
        stateSignupBasicCost: state.app.signupBasicCost,
        stateSignupUnlimitedCost: state.app.signupUnlimitedCost,
        stateUserLoggedInStatus: state.app.userLoggedInStatus,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSignupInfoBegin: () => dispatch(appActions.fetchSignupInfoBegin()),
        onFetchUserLoggedInStatus: () => dispatch(appActions.fetchUserLoggedInStatusBegin()),
        onEditChosenPlan: (plan) => dispatch(appActions.editChosenPlan(plan)),
        onLogout: (history) => dispatch(appActions.logout(history)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChoosePlanPage)));
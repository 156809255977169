import React, { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles, fade } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import TablePagination from '@material-ui/core/TablePagination';
import { green } from '@material-ui/core/colors';
import {withRouter} from 'react-router-dom';

import _ from 'lodash';

import UserEditPanel from '../UserEditPanel/UserEditPanel';

import * as AppActions from '../../../store/ducks/app';

const styles = theme => ({
    root: {
        width: '100%',
        position: 'relative',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
    },
    table: {
    },
    tableFade: {
        opacity: .3
    },
    tableProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -24,
        marginLeft: -24,
    },
    progress: {
        margin: theme.spacing(2),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        border: '2px solid #ddd',
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchBar: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end'
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#777',
        cursor: 'pointer'

    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
                width: 200,
            },
        },
    },
});

class UserEditTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userEditId: null,
            gcpUserFolderId: null,
            userEditModalOpen: false,
            currentPage: 0,
            rowsPerPage: 10,
            searchString: '',
        }
        this.userSearchDebounced = _.debounce(this.userSearch, 500);
    }

    

    componentDidMount() {
        this.props.onFetchUserList(this.state.searchString, this.state.currentPage, this.state.rowsPerPage, this.props.history);
    }

    handleSearchChange = (event) => {
        this.userSearchDebounced(event.target.value);
    }

    userSearch = (searchString) => {
        this.setState({searchString, currentPage: 0}, () => this.props.onFetchUserList(this.state.searchString, this.state.currentPage, this.state.rowsPerPage, this.props.history));
    }

    editUser = (id, gcpId) => {
        this.props.onFetchUserPanelInfo(id, this.props.history);
        this.setState({ userEditId: id, userEditModalOpen: true, gcpUserFolderId: gcpId });
    }

    onModalClose = () => {
        this.setState({ userEditId: null, userEditModalOpen: false, gcpUserFolderId: null });
    }

    handleChangePage = (event, newPage) => {
        this.setState({currentPage: newPage}, () => this.props.onFetchUserList(this.state.searchString, this.state.currentPage, this.state.rowsPerPage, this.props.history));
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: +event.target.value, currentPage: 0}, () => this.props.onFetchUserList(this.state.searchString, this.state.currentPage, this.state.rowsPerPage, this.props.history));
    }

    render() {

        let userTable = <CircularProgress className={this.props.classes.progress} />;

            let userRows = this.props.stateUserList.map(row => {

                let type = 'User';
                if(row.type === 2){
                    type = 'Filmmaker';
                }
                if(row.type === 3){
                    type = 'Admin';
                }

                return (
                    <TableRow key={row.id}>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{type}</TableCell>
                        <TableCell align="center">
                            <IconButton aria-label="close" size="small" onClick={() => this.editUser(row.id, row.gcpUserFolderId)}>
                                <EditIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                );
            });

            userTable = (
                <Paper className={this.props.classes.root}>
                    <Table className={this.props.stateUserListLoading ? this.props.classes.tableFade : this.props.classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">Permission Type</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userRows}
                        </TableBody>
                        
                    </Table>
                    {this.props.stateUserListLoading && <CircularProgress size={48} className={this.props.classes.tableProgress} />}
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={this.props.stateUserListCount}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.currentPage}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </Paper>
            );

        return (
            <div>
                <div className={this.props.classes.searchBar}>
                    <div className={this.props.classes.search}>
                        <div className={this.props.classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: this.props.classes.inputRoot,
                                input: this.props.classes.inputInput,
                            }}
                            onChange={this.handleSearchChange}
                        />
                    </div>
                </div>
                {userTable}
                <UserEditPanel 
                    userEditId={this.state.userEditId}
					modalOpen={this.state.userEditModalOpen}
					handleModalClose={this.onModalClose}
					formValues={this.props.stateUserPanelInfo}
					formValuesLoaded={!this.props.stateUserPanelInfoLoading && this.props.stateUserPanelInfoLoaded}
					editUserInfoSending={this.props.stateEditUserInfoSending}
					editUserInfoSent={this.props.stateEditUserInfoSent}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        stateUserList: state.app.userList,
        stateUserListLoading: state.app.userListLoading,
        stateUserListCount: state.app.userListCount,
        stateUserPanelInfo: state.app.userPanelInfo,
        stateUserPanelInfoLoading: state.app.userPanelInfoLoading,
        stateUserPanelInfoLoaded: state.app.userPanelInfoLoaded,
        stateEditUserInfoSending: state.app.editUserInfoSending,
        stateEditUserInfoSent: state.app.editUserInfoSent
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchUserList: (searchString, currentPage, rowsPerPage, history) => dispatch(AppActions.fetchUserlistDataBegin(searchString, currentPage, rowsPerPage, history)),
        onFetchUserPanelInfo: (id, history) => dispatch(AppActions.fetchUserPanelInfoBegin(id, history))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserEditTab)));
import React, { Component } from 'react';
import { injectStripe } from 'react-stripe-elements';

import { CardElement } from 'react-stripe-elements';

class PaymentForm extends Component {

    // handleSubmit = (ev) => {
    //     // We don't want to let default form submission happen here, which would refresh the page.
    //     ev.preventDefault();

    //     // Within the context of `Elements`, this call to createPaymentMethod knows from which Element to
    //     // create the PaymentMethod, since there's only one in this group.
    //     // See our createPaymentMethod documentation for more:
    //     // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
    //     console.log(this.props.stripe.createPaymentMethod)
    //     // this.props.stripe
    //     //   .createPaymentMethod('card')
    //     //   .then((result) => {
    //     //     console.log('Received Stripe PaymentMethod:', result);
    //     //   });

    // };

    handleChange = (value) => {
        this.props.onChange({...value, promise: () => this.props.stripe.createPaymentMethod('card')});
    }

    render() {
        return (
            <label>
                <CardElement 
                    style={{ base: { fontSize: '18px' } }} 
                    onChange={this.handleChange}
                    onBlur={this.props.onBlur} />
            </label>
        );
    }
}

export default injectStripe(PaymentForm);
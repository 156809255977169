import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { green } from '@material-ui/core/colors';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import _ from 'lodash';
import {withRouter} from 'react-router-dom';

import FieldComponent from "../Forms/FieldComponent/FieldComponent";

import * as AppActions from "../../store/ducks/app";

import * as classes from './VideoEditPanel.module.css';

import config from '../../config/config'; 

let URLPrefix = `https://${config.development.cloud_bucket}.storage.googleapis.com/videos/`;
if(config.demo){
    let baseUrl = JSON.stringify(process.env.NODE_ENV) === JSON.stringify('development') ? config.development.proxy : config.production.base_url;
    URLPrefix = `${baseUrl}/files/videos/`;
}

const styles = theme => ({
    root: {
        width: "100%",
        overflowX: "auto"
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    progress: {
        margin: theme.spacing(2)
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

const fields = [
    { label: "Title", name: "name", componentType: "input" },
    { label: "Description", name: "description", componentType: "textarea" },
    { label: "Category", name: "category", componentType: "autocomplete", maxAutocompleteSelections: 1 },
    { label: "Owner", name: "owner", componentType: "autocomplete", maxAutocompleteSelections: 1 },
    { label: "Public", name: "visibility", componentType: "switch" }
]

class VideoEditPanel extends Component {

    constructor(props) {
        super(props);

        this.fileInputRef = React.createRef();
        this.thumbnailImgRef = React.createRef();
        this.handleUserSuggestionsSearchThrottle = _.debounce(this.handleUserSuggestionsSearch, 200);
        this.state = {
            fields: []
        }
    }

    componentDidMount() {
        this.setState((prevState) => {
            return {
                fields: fields.filter(row => this.props.stateUserType === 2 ? row.name !== 'owner' : true)
            }
        })
        this.props.onfetchVideoCategorySuggestions(this.props.history);
    }

    onFormSubmit = (values, actions) => {

        this.props.onEditVideoInfo(this.props.videoEditId, values.name, values.description, values.category.selectedItem[0].id, values.owner && values.owner.selectedItem[0].id, values.visibility ? 2 : 1, this.props.history);
        actions.setSubmitting(false);
    };

    onFormClose = () => {
        this.props.onThumbnailAdd(null, this.props.history);
        this.props.handleModalClose();
    }

    handleThumbnailClick = () => {
        this.fileInputRef.current.click();
    }

    fileListToArray = list => {
        const array = [];
        for (let i = 0; i < list.length; i++) {
            array.push(list.item(i));
        }
        return array;
    };

    onThumbnailAdded = event => {
        const file = event.target.files.item(0);
        this.props.onThumbnailAdd(file, this.props.history);
        this.fileInputRef.current.value = null;
    };

    handleUserSuggestionsSearch = (string, top) => {
        this.props.onfetchUserSuggestions(string, top, this.props.history);
    }

    getValidationSchema = () => {
        return Yup.object().shape({
            name: Yup.string().required("You must provide a value"),
            description: Yup.string().required("You must provide a value"),
            category: Yup.object().shape({
                selectedItem: Yup.array().required("You must provide a value")
            }),
            owner: this.props.stateUserType !== 2 && Yup.object().shape({
                selectedItem: Yup.array().required("You must provide a value")
            })
        });
    }

    renderFields = (values, errors, touched, handleBlur, handleChange, setFieldValue) => {
        return this.state.fields.map(({ label, name, componentType, maxAutocompleteSelections }) => {
            return (
                <FieldComponent
                    key={name}
                    name={name}
                    type="text"
                    componentType={componentType}
                    label={label}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[name]}
                    error={errors[name]}
                    touched={touched[name]}
                    setFieldValue={setFieldValue}
                    maxAutocompleteSelections={maxAutocompleteSelections}
                    userSuggestionsSearch={this.handleUserSuggestionsSearchThrottle}
                />
            );
        });
    };

    render() {
        const DialogTitle = withStyles(theme => ({
            root: {
                margin: 0,
                padding: theme.spacing(2)
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500]
            }
        }))(props => {
            const { children, classes } = props;
            return (
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">{children}</Typography>
                </MuiDialogTitle>
            );
        });

        const DialogContent = withStyles(theme => ({
            root: {
                padding: theme.spacing(2)
            }
        }))(MuiDialogContent);

        const DialogActions = withStyles(theme => ({
            root: {
                margin: 0,
                padding: theme.spacing(1)
            }
        }))(MuiDialogActions);

        let initialValue = {};
        this.state.fields.filter(row => this.props.stateUserType === 2 ? row.name !== 'owner' : true).forEach(elt => {
            if(elt.componentType === "autocomplete"){

                if(elt.name === "category"){
                    const item =  this.props.formValues.category_id ? [{id: this.props.formValues.category_id, name: this.props.formValues.category_name}] : [];
                    initialValue[elt.name] = {inputValue: '', selectedItem: item};
                }else if(elt.name === "owner"){
                    const item =  this.props.formValues.owner_id ? [{id: this.props.formValues.owner_id, name: this.props.formValues.owner_name}] : [];
                    initialValue[elt.name] = {inputValue: '', selectedItem: item};
                }else{
                    initialValue[elt.name] = {inputValue: '', selectedItem: []};
                }
                
            }else if(elt.componentType === "switch"){
                initialValue[elt.name] = this.props.formValues[elt.name] !== 1;
            }else{
                initialValue[elt.name] = this.props.formValues[elt.name] || "";
            }
            
        });

        let thumbnail = (<div style={{backgroundColor: '#555', color: '#ddd', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '210px', height: '118px'}}>
            <Typography>Processing...</Typography>
        </div>);

        if(this.props.thumbnailFile){
            const reader = new FileReader();
            reader.onload = (e) => {
                this.thumbnailImgRef.current.src = e.target.result;
            }
            reader.readAsDataURL(this.props.thumbnailFile);
        }

        if(this.props.thumbnailLoaded || this.props.thumbnailFile){

            thumbnail = (<div onClick={this.handleThumbnailClick} className={classes.Thumbnail} style={{position: 'relative', width: '210px', height: '118px'}}>
                <input
                    ref={this.fileInputRef}
                    type="file"
                    onChange={this.onThumbnailAdded}
                    style={{display: 'none'}}
                />
                <img width="100%" height="100%" alt="" src={`${URLPrefix}${this.props.gcpVideoFolderId}/thumbnail.png?avoidCache=1`} ref={this.thumbnailImgRef} />
                <div  className={classes.Front}>
                    <Typography>Change Thumbnail</Typography>
                </div>
            </div>)
        }

        return (
            <Dialog
                onClose={this.props.handleModalClose}
                open={this.props.modalOpen}
                className={this.props.classes.root}
                fullWidth
                classes={{
                    paper: classes.dialog,
                }}
            >
                {!this.props.formValuesLoaded ? (
                    <CircularProgress className={this.props.classes.progress} />
                ) : (
                    <Formik
                        initialValues={initialValue}
                        onSubmit={this.onFormSubmit}
                        validationSchema={this.getValidationSchema()}
                        render={({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange, 
                            setFieldValue
                        }) => (
                            <Form>
                                <DialogTitle>Edit Video</DialogTitle>
                                <DialogContent dividers>
                                    {this.renderFields(
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        setFieldValue
                                    )}
                                    <div>
                                        <Typography>
                                            Thumbnail
                                        </Typography>
                                        {thumbnail}
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <div className={this.props.classes.wrapper}>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            disabled={this.props.editVideoInfoSending}
                                        >
                                            Save changes
                                        </Button>
                                        {this.props.editVideoInfoSending && <CircularProgress size={24} className={this.props.classes.buttonProgress} />}
                                    </div>
                                    
                                    <Button
                                        onClick={this.onFormClose}
                                        color="secondary"
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    />
                )}
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        stateUserType: state.app.userType
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditVideoInfo: (id, name, description, category, owner, visibility, history) =>
            dispatch(AppActions.editVideoInfoBegin(id, name, description, category, owner, visibility, history)),
        onfetchVideoCategorySuggestions: (history) =>
            dispatch(AppActions.fetchVideoCategorySuggestionsBegin(history)),
        onfetchUserSuggestions: (string, top, history) =>
            dispatch(AppActions.fetchUserSuggestionsBegin(string, top, history)),
        onThumbnailAdd: (file, history) =>
            dispatch(AppActions.thumbnailAdd(file, history))
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(VideoEditPanel)));

import React, { Component } from "react";

import { connect } from "react-redux";

import * as AppActions from '../../../store/ducks/app';

import VideoScroller from './VideoScroller/VideoScroller';
import UserScroller from './UserScroller/UserScroller';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {withRouter} from 'react-router-dom';

const styles = theme => ({
    
    textMoreFrom: {
        // fontSize: "16px",
        // fontWeight: "400",
        // lineHeight: "18px"
        margin: '2rem 0'
    }
});

class BrowseVideoPage extends Component {

    componentDidMount() {
        this.props.onFetchBrowseVideo(this.props.history);
    }

    render() {

        let page = <CircularProgress size={48} />;

        if(this.props.stateBrowseVideoLoaded){
            page = (
                <div style={{ boxSizing: 'border-box', padding: '1.5rem 2.5rem', maxWidth: '1280px', margin: 'auto' }}>
                    <div>
                        <Typography variant='h6' className={this.props.classes.textMoreFrom}>Trending</Typography>
                    </div>
                    <div style={{padding: '.5rem 0', boxSizing: 'border-box'}}>
                        <VideoScroller videos={this.props.stateBrowseVideo.mostPopular} />
                    </div>
                    <div>
                        <Typography variant='h6' className={this.props.classes.textMoreFrom}>Following</Typography> 
                    </div>
                    <div style={{padding: '.5rem 0', boxSizing: 'border-box'}}>
                        <UserScroller videos={this.props.stateBrowseVideo.following} />
                    </div>
                    <div>
                        <Typography variant='h6' className={this.props.classes.textMoreFrom}>Recently Uploaded</Typography>
                    </div>
                    <div style={{padding: '.5rem 0', boxSizing: 'border-box'}}>
                        <VideoScroller videos={this.props.stateBrowseVideo.recentlyUploaded} />
                    </div>
                </div>
            );
        }

        return (
            <div>
                {page}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        stateBrowseVideoLoaded: state.app.browseVideoLoaded,
        stateBrowseVideo: state.app.browseVideo
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchBrowseVideo: (history) => dispatch(AppActions.fetchBrowseVideoBegin(history)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BrowseVideoPage)));

import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from '@material-ui/core/colors';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {withRouter} from 'react-router-dom';

import FieldComponent from "../../Forms/FieldComponent/FieldComponent";

import * as AppActions from "../../../store/ducks/app";

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
        overflowX: "auto"
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    progress: {
        margin: theme.spacing(2)
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

const fields = [
    { label: "Payment", name: "payment", componentType: "payment" }
];

class StripeForm extends Component {

    onFormSubmit = async ({payment}, actions) => {
        const paymentMethod = await payment.promise();
        this.props.onEditPaymentInfo(paymentMethod.paymentMethod, this.props.history, true);
        actions.resetForm();
        //this.props.onEditVideoInfo(this.props.videoEditId, name, description, category.selectedItem[0].id, owner.selectedItem[0].id, visibility ? 2 : 1, this.props.history);
        // this.props.history.push('/videosearch',{videoSearch: {name, category, owner, date: date && moment(date).format('YYYY-MM-DD')}});
        //console.log(name, category, owner, date);
        //this.props.history.push()
        actions.setSubmitting(false);
    };

    handleSetStatus = (name, value, oldStatus, setStatus) => {
        let fixedOldStatus = oldStatus || {};
        setStatus({
            ...fixedOldStatus,
            [name]: value
        });
    }

    renderFields = (values, errors, touched, handleBlur, handleChange, setFieldValue, setStatus, status, setFieldTouched, setFieldError) => {
        // console.log(touched);
        // console.log(values);
        return fields.map(({ label, name, componentType }) => {
            return (
                <FieldComponent
                    key={name}
                    name={name}
                    type="text"
                    componentType={componentType}
                    label={label}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[name]}
                    error={errors[name]}
                    status={status && status[name]}
                    setStatus={(name, value) => this.handleSetStatus(name, value, status, setStatus)}
                    touched={touched[name]}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    setFieldError={setFieldError}
                    apiKey={this.props.apiKey}
                />
            );
        });
    };

    render() {
        

        const DialogContent = withStyles(theme => ({
            root: {
                padding: theme.spacing(2),
                overflowY: 'visible'
            }
        }))(MuiDialogContent);

        const DialogActions = withStyles(theme => ({
            root: {
                margin: 0,
                padding: theme.spacing(1)
            }
        }))(MuiDialogActions);

        let initialValue = {};
        fields.forEach(elt => {
            initialValue[elt.name] = {};
        });

        return (
            <div style={{width: '100%'}}>
                <Formik
                    initialValues={initialValue}
                    onSubmit={this.onFormSubmit}
                    validationSchema={validateSchema}
                    validateOnChange={false}
                    render={({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange, 
                        setFieldValue,
                        setStatus,
                        status,
                        setFieldTouched,
                        setFieldError,
                        isValidating
                    }) => (
                        <Form>
                            <DialogContent>
                                {this.renderFields(
                                    values,
                                    errors,
                                    touched,
                                    handleBlur,
                                    handleChange,
                                    setFieldValue,
                                    setStatus,
                                    status,
                                    setFieldTouched,
                                    setFieldError
                                )}
                            </DialogContent>
                            <DialogActions>
                                <div className={this.props.classes.wrapper}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disabled={this.props.editPaymentInfoSending || isValidating}
                                    >
                                        Save changes
                                    </Button>
                                    {(this.props.editPaymentInfoSending || isValidating) && <CircularProgress size={24} className={this.props.classes.buttonProgress} />}
                                </div>
                            </DialogActions>
                        </Form>
                    )}
                />
            </div>
            
        );
    }
}

const validateSchema = Yup.object().shape({
    payment: Yup.mixed().test({
        message: 'invalid',
        name: 'max',
        test: async function(value) {
            if(!value.promise){
                return this.createError({ message: 'You must provide a value!'});
            }
            let result = await value.promise();
            if(result.error){
                return this.createError({ message: result.error.message});
            }
            return true;
        }
    })
});

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditPaymentInfo: (paymentmethod, history, choosePlan) =>
            dispatch(AppActions.editPaymentInfoBegin(paymentmethod, history, choosePlan)),
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(StripeForm)));

module.exports = {
    // development: {
    //     throttle: 200,
    //     cloud_bucket: 'videostreamingservice_videos',
    //     base_url: 'http://localhost:3000',
    //     proxy: 'http://localhost:5000'
    // },
    // production: {
    //     throttle: 1000,
    //     cloud_bucket: 'videostreamingservice_videos',
    //     base_url: 'https://videostreamingservice.appspot.com'
    // },
    demo: true,
    development: {
        throttle: 200,
        cloud_bucket: 'sprkglobal_videos',
        base_url: 'http://localhost:3000',
        proxy: 'http://localhost:5000'
    },
    // production: {
    //     throttle: 1000,
    //     cloud_bucket: 'sprkglobal_videos',
    //     base_url: 'http://sprkglobal.niftyconsulting.com'
    // },
    production: {
        throttle: 1000,
        cloud_bucket: '',
        base_url: 'https://video-streaming-service.kevinbajzek.com'
    }
};
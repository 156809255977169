import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { green } from '@material-ui/core/colors';
import {withRouter} from 'react-router-dom';

import * as AppActions from "../../../../../store/ducks/app";
import * as classes from './SubscriptionEditPanel.module.css';

const styles = theme => ({
    root: {
        width: "100%",
        overflowX: "auto"
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    progress: {
        margin: theme.spacing(2)
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

class SubscriptionEditPanel extends Component {

    handleSubmit = (planType) => {
        this.props.onEditSubscriptionInfo(planType, this.props.history);
    }

    render() {

        let buttonText1 = "Activate Subscription";
        let buttonColor1 = "primary";
        let buttonText2 = "Activate Subscription";
        let buttonColor2 = "primary";
        let button1 = 0;
        let button2 = 0;

        if(this.props.active && this.props.planType === 1){
            buttonText1 = "Switch to Unlimited";
            buttonColor1 = "primary";
            button1 = 2;
            buttonText2 = "Cancel Subscription";
            buttonColor2 = "secondary";
            button2 = 0;
        }else if(this.props.active && this.props.planType === 2){
            buttonText1 = "Switch to Basic";
            buttonColor1 = "primary";
            button1 = 1;
            buttonText2 = "Cancel Subscription";
            buttonColor2 = "secondary";
            button2 = 0;
        }else if(!this.props.active && this.props.planType === 0){
            buttonText1 = "Activate Basic Subscription";
            buttonColor1 = "primary";
            button1 = 1;
            buttonText2 = "Activate Unlimited Subscription";
            buttonColor2 = "primary";
            button2 = 2;
        }else if(!this.props.active && this.props.planType === 1){
            buttonText1 = "Activate Basic Subscription";
            buttonColor1 = "primary";
            button1 = 1;
            buttonText2 = "Activate Unlimited Subscription";
            buttonColor2 = "primary";
            button2 = 2;
        }else if(!this.props.active && this.props.planType === 2){
            buttonText1 = "Activate Basic Subscription";
            buttonColor1 = "primary";
            button1 = 1;
            buttonText2 = "Activate Unlimited Subscription";
            buttonColor2 = "primary";
            button2 = 2;
        }

        const DialogTitle = withStyles(theme => ({
            root: {
                margin: 0,
                padding: theme.spacing(2)
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500]
            }
        }))(props => {
            const { children, classes } = props;
            return (
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">{children}</Typography>
                </MuiDialogTitle>
            );
        });

        const DialogContent = withStyles(theme => ({
            root: {
                padding: theme.spacing(2)
            }
        }))(MuiDialogContent);

        const DialogActions = withStyles(theme => ({
            root: {
                margin: 0,
                padding: theme.spacing(1)
            }
        }))(MuiDialogActions);

        return (
            <Dialog
                onClose={this.props.handleModalClose}
                open={this.props.modalOpen}
                className={this.props.classes.root}
                fullWidth
                classes={{
                    paper: classes.dialog,
                }}
            >
                <DialogTitle>Edit Subscription Settings</DialogTitle>
                <DialogContent dividers>
                    <div className={this.props.classes.wrapper}>
                        <Button
                            variant="contained"
                            type="submit"
                            color={buttonColor1}
                            disabled={this.props.editSubscriptionInfoSending}
                            onClick={() => this.handleSubmit(button1)}
                        >
                            {buttonText1}
                        </Button>
                        {this.props.editSubscriptionInfoSending && <CircularProgress size={24} className={this.props.classes.buttonProgress} />}
                    </div>
                    <div className={this.props.classes.wrapper}>
                        <Button
                            variant="contained"
                            type="submit"
                            color={buttonColor2}
                            disabled={this.props.editSubscriptionInfoSending}
                            onClick={() => this.handleSubmit(button2)}
                        >
                            {buttonText2}
                        </Button>
                        {this.props.editSubscriptionInfoSending && <CircularProgress size={24} className={this.props.classes.buttonProgress} />}
                    </div>
                </DialogContent>
                <DialogActions>                    
                    <Button
                        onClick={this.props.handleModalClose}
                        color="secondary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditSubscriptionInfo: (planType, history) =>
            dispatch(AppActions.editSubscriptionInfoBegin(planType, history))
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(SubscriptionEditPanel)));

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import {withRouter} from 'react-router-dom';
import config from '../../../config/config';

import SubCard from './SubCard/SubCard';

import * as appActions from '../../../store/ducks/app';
import * as classes from './WelcomePage.module.css';

class WelcomePage extends Component{

    componentDidMount() {
        this.props.onSignupInfoBegin();
    }

    chooseBasicPlan = () => {
        this.props.onEditChosenPlan(1);
        if(!this.props.stateUserLoggedInStatus){
            this.props.history.push('/signup/createaccount');
        }else{
            this.props.history.push('/signup/addpayment');
        }
    }

    chooseUnlimitedPlan = () => {
        this.props.onEditChosenPlan(2);
        if(!this.props.stateUserLoggedInStatus){
            this.props.history.push('/signup/createaccount');
        }else{
            this.props.history.push('/signup/addpayment');
        }
    }

    render(){

        return(
            <div>
                <div className={classes.pageHeader}>
                    <div className={classes.pageHeaderBar}>
                        <Typography onClick={() => this.props.history.push('/welcome')} className={classes.homeButton}>
                            {config.demo ? 'Home' : 'SprkGlobal'}
                        </Typography>
                        {!this.props.stateUserLoggedInStatus ? <Typography onClick={() => this.props.history.push('/login')} className={classes.loginButton}>
                            LOG IN
                        </Typography> :
                        <Typography onClick={() => this.props.onLogout(this.props.history)} className={classes.loginButton}>
                            LOG OUT
                        </Typography>}
                    </div>
                    <div className={classes.headerMain}>
                        <Typography className={classes.headerText}>
                            Your Hub for Independent Filmmaking
                        </Typography>
                        <Button onClick={() => this.props.history.push('/signup/chooseplan')} variant="contained" className={classes.signUpButton}>
                            Sign Up Now
                        </Button>
                    </div>
                </div>
                <div className={classes.subCardSection}>
                    <SubCard
                        planName={'BASIC'}
                        cost={this.props.stateSignupBasicCost}
                        onSelect={this.chooseBasicPlan}
                        info={['Get access to 300 minutes per month of exclusive series, hit movies, and more',
                        'Enjoy an ad free viewing experience',
                        'Switch plans or cancel anytime']} />
                    <SubCard
                        planName={'UNLIMITED'}
                        cost={this.props.stateSignupUnlimitedCost}
                        onSelect={this.chooseUnlimitedPlan}
                        info={['Get unlimited access to exclusive series, hit movies, and more',
                        'Enjoy an ad free viewing experience',
                        'Switch plans or cancel anytime']} />
                </div>
                
            </div>
        );
    }
};



const mapStateToProps = state => {
    return {
        stateSignupBasicCost: state.app.signupBasicCost,
        stateSignupUnlimitedCost: state.app.signupUnlimitedCost,
        stateUserLoggedInStatus: state.app.userLoggedInStatus,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSignupInfoBegin: () => dispatch(appActions.fetchSignupInfoBegin()),
        onEditChosenPlan: (plan) => dispatch(appActions.editChosenPlan(plan)),
        onLogout: (history) => dispatch(appActions.logout(history)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WelcomePage));
import React, { Component } from "react";

import { connect } from "react-redux";

import * as AppActions from '../../../store/ducks/app';

import VideoScroller from '../BrowseVideoPage/VideoScroller/VideoScroller';
import VideoPlayer from '../../VideoPlayer/VideoPlayer';
import FollowButton from '../FollowButton/FollowButton';

import {Link} from 'react-router-dom';

import CircularProgress from "@material-ui/core/CircularProgress";

import config from '../../../config/config';
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";

import {withRouter} from 'react-router-dom';

import moment from 'moment';

import * as classes from './WatchVideoPage.module.css';

let URLPrefix = `https://${config.development.cloud_bucket}.storage.googleapis.com/videos/`;
if(config.demo){
    let baseUrl = JSON.stringify(process.env.NODE_ENV) === JSON.stringify('development') ? config.development.proxy : config.production.base_url;
    URLPrefix = `${baseUrl}/files/videos/`;
}

const styles = theme => ({
    avatar: {
        objectFit: 'cover',
        maxWidth: '100%',
        maxHeight: '100%',
        width: 'auto',
        height: 'auto'
    },
});

class WatchVideoPage extends Component {

    componentDidMount() {
        this.props.onFetchWatchVideo(this.props.videoId, this.props.history);
        // this.props.onFetchWatchVideo(5);
    }

    updateWatchAmount = (amount) => {
        this.props.onUpdateWatchAmount(this.props.stateWatchVideo.viewId, amount, this.props.history);
    }

    render() {

        let page = <CircularProgress size={48} />;

        let userImageUrl = `https://${config.development.cloud_bucket}.storage.googleapis.com/users/${this.props.stateWatchVideo.ownerAvatarGCPid}/avatar.png`;
        if(config.demo){
            let baseUrl = JSON.stringify(process.env.NODE_ENV) === JSON.stringify('development') ? config.development.proxy : config.production.base_url;
            userImageUrl = `${baseUrl}/files/users/${this.props.stateWatchVideo.ownerAvatarGCPid}/avatar.png`;
        }

        if(this.props.stateWatchVideoLoaded){
            page = (
                <div>
                    <div style={{ backgroundColor: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <VideoPlayer
                            manifestUri={`${URLPrefix}${this.props.stateWatchVideo.GCPid}/manifest.mpd`} 
                            manifestUriHLS={`${URLPrefix}${this.props.stateWatchVideo.GCPid}/manifest.m3u8`} 
                            updateWatchAmount={this.updateWatchAmount} />
                    </div>
                    <div style={{ boxSizing: 'border-box', padding: '1.5rem 2.5rem', maxWidth: '1280px', margin: 'auto' }}>
                        <Typography variant='h4' className={classes.textTitle}>{this.props.stateWatchVideo.title}</Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant='h6' style={{ marginRight: '1rem' }} className={classes.textDate}>{`${moment.utc(this.props.stateWatchVideo.uploadDate).local().fromNow()}`}</Typography>
                            <RemoveRedEyeIcon color="disabled" style={{ fontSize: 16 }} />
                            <Typography variant='h6' className={classes.textDate}>{`\u00A0${this.props.stateWatchVideo.views.toLocaleString()}`}</Typography>
                        </div>
                        <div style={{borderBottom: '2px solid #eee', margin: '1rem 0 1rem 0', boxSizing: 'border-box'}}></div>
                        <div className={classes.ownerInfo}>
                            <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={{ pathname: `/user/${this.props.stateWatchVideo.ownerId}` }}>
                                <div style={{display: 'flex', justifyContent: 'flex-begin', alignItems: 'center'}}>
                                    <div style={{width: '48px', height: '48px', display: 'flex', justifyContent: 'center', borderRadius: '50%', overflow: 'hidden', backgroundColor: '#eee', marginRight: '1rem'}}>
                                        <img
                                            className={this.props.classes.avatar}
                                            alt=''
                                            src={userImageUrl}>
                                        </img>
                                    </div>
                                    <Typography className={classes.ownerNameText}>
                                        {this.props.stateWatchVideo.ownerName}
                                    </Typography>
                                </div>
                            </Link>
                            <div className={classes.followButton}>
                                <FollowButton
                                    modalOpen={this.props.stateShowUnfollowModal}
                                    unfollowSending={this.props.stateUpdateFollowSending}
                                    unfollowSent={this.props.stateUpdateFollowSent}
                                    unfollowedName={this.props.stateWatchVideo.ownerName}
                                    isFollowing={this.props.stateWatchVideo.isFollowing}
                                    followedId={this.props.stateWatchVideo.ownerId} 
                                />
                            </div>
                            
                        </div>
                        <div>
                            <Typography variant='h6' className={classes.textDescription}>{this.props.stateWatchVideo.description}</Typography>
                        </div>
                        <div style={{borderBottom: '2px solid #eee', margin: '2rem 0 2rem 0', boxSizing: 'border-box'}}></div>
                        <div>
                            <Typography variant='h6' className={classes.textMoreFrom}>More From User</Typography>
                        </div>
                        <div style={{padding: '.5rem 0', boxSizing: 'border-box'}}>
                            <VideoScroller videos={this.props.stateWatchVideo.moreVideos} />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                {page}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        stateWatchVideo: state.app.watchVideo,
        stateWatchVideoLoaded: state.app.watchVideoLoaded,
        stateShowUnfollowModal: state.app.showUnfollowModal,
        stateUpdateFollowSending: state.app.updateFollowSending,
        stateUpdateFollowSent: state.app.updateFollowSent
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchWatchVideo: (id, history) => dispatch(AppActions.fetchWatchVideoBegin(id, history)),
        onUpdateWatchAmount: (id, amount, history) => dispatch(AppActions.updateViewWatchAmountBegin(id, amount, history)),
        hidePage: (flag) => dispatch(AppActions.hidePage(flag)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WatchVideoPage)));
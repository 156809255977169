import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { green } from '@material-ui/core/colors';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import _ from 'lodash';
import {withRouter} from 'react-router-dom';

import moment from 'moment';

import FieldComponent from "../../Forms/FieldComponent/FieldComponent";

import * as AppActions from "../../../store/ducks/app";

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
        overflowX: "auto"
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    progress: {
        margin: theme.spacing(2)
    },
    padding: {
        margin: '1rem',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

const fields = [
    { label: "Title", name: "name", componentType: "input" },
    { label: "Genre", name: "category", componentType: "autocomplete", maxAutocompleteSelections: 5 },
    { label: "Filmmaker", name: "owner", componentType: "autocomplete", maxAutocompleteSelections: 5 },
    { label: "Date Uploaded", name: "date", componentType: "date" },
];

class VideoSearchPanel extends Component {

    constructor(props) {
        super(props);
        this.handleUserSuggestionsSearchThrottle = _.debounce(this.handleUserSuggestionsSearch, 200);
    }

    componentDidMount() {
        this.props.onfetchVideoCategorySuggestions(this.props.history);
    }

    onFormSubmit = ({ name, category, owner, date }, actions) => {
        this.props.onFormSubmit && this.props.onFormSubmit();
        //this.props.onEditVideoInfo(this.props.videoEditId, name, description, category.selectedItem[0].id, owner.selectedItem[0].id, visibility ? 2 : 1, this.props.history);
        this.props.history.push('/videosearch',{videoSearch: {name, category, owner, date: date && moment(date).format('YYYY-MM-DD')}});
        //console.log(name, category, owner, date);
        //this.props.history.push()
        actions.setSubmitting(false);
    };

    handleUserSuggestionsSearch = (string, top) => {
        this.props.onfetchUserSuggestions(string, top, this.props.history);
    }

    handleSetStatus = (name, value, oldStatus, setStatus) => {
        let fixedOldStatus = oldStatus || {};
        setStatus({
            ...fixedOldStatus,
            [name]: value
        });
    }

    renderFields = (values, errors, touched, handleBlur, handleChange, setFieldValue, setStatus, status) => {
        return fields.map(({ label, name, componentType, maxAutocompleteSelections }) => {
            return (
                <div key={name} style={{margin: '1rem'}}>
                    <FieldComponent
                        key={name}
                        name={name}
                        type="text"
                        componentType={componentType}
                        label={label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[name]}
                        error={errors[name]}
                        status={status && status[name]}
                        setStatus={(name, value) => this.handleSetStatus(name, value, status, setStatus)}
                        touched={touched[name]}
                        setFieldValue={setFieldValue}
                        maxAutocompleteSelections={maxAutocompleteSelections}
                        userSuggestionsSearch={this.handleUserSuggestionsSearchThrottle}
                    />
                </div>
                
            );
        });
    };

    render() {

        const DialogContent = withStyles(theme => ({
            root: {
                padding: theme.spacing(2),
                overflowY: 'visible'
            }
        }))(MuiDialogContent);

        const DialogActions = withStyles(theme => ({
            root: {
                margin: 0,
                padding: theme.spacing(1)
            }
        }))(MuiDialogActions);

        let initialValue = {};
        fields.forEach(elt => {
            let locationState = (this.props.location.state && this.props.location.state.videoSearch) || {};
            if(locationState[elt.name]){
                initialValue[elt.name] = locationState[elt.name];
            }else if(elt.componentType === "autocomplete"){
                initialValue[elt.name] = {inputValue: '', selectedItem: []};
            }else if(elt.componentType === "date"){
                initialValue[elt.name] = null;
            }else{
                initialValue[elt.name] = "";
            }
            
        });

        return (
            <Formik
                initialValues={initialValue}
                onSubmit={this.onFormSubmit}
                validationSchema={validateSchema}
                render={({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange, 
                    setFieldValue,
                    setStatus,
                    status
                }) => (
                    <Form>
                        <DialogContent dividers>
                            {this.renderFields(
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                setFieldValue,
                                setStatus,
                                status
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                className={this.props.classes.padding}
                            >
                                Search
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            />
        );
    }
}

const validateSchema = Yup.object().shape({});

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onfetchVideoCategorySuggestions: (history) =>
            dispatch(AppActions.fetchVideoCategorySuggestionsBegin(history)),
        onfetchUserSuggestions: (string, top, history) =>
            dispatch(AppActions.fetchUserSuggestionsBegin(string, top, history)),
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(VideoSearchPanel)));

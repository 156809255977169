import React, { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles, fade } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import TablePagination from '@material-ui/core/TablePagination';
import { green } from '@material-ui/core/colors';
import {withRouter} from 'react-router-dom';

import moment from 'moment';
import _ from 'lodash';

import VideoEditPanel from '../VideoEditPanel/VideoEditPanel';

import * as AppActions from '../../store/ducks/app';
import * as classes from './VideoEditTab.module.css';

const styles = theme => ({
    root: {
        width: '100%',
        position: 'relative',
        marginTop: theme.spacing(1),
        overflowX: 'auto',
    },
    table: {
    },
    tableFade: {
        opacity: .3
    },
    tableProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -24,
        marginLeft: -24,
    },
    progress: {
        margin: theme.spacing(2),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        border: '2px solid #ddd',
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchBar: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end'
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#777',
        cursor: 'pointer'

    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
                width: 200,
            },
        },
    },
});

class VideoEditTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            videoEditId: null,
            gcpVideoFolderId: null,
            videoEditModalOpen: false,
            thumbnailLoaded: false,
            currentPage: 0,
            rowsPerPage: 10,
            searchString: '',
        }
        this.videoSearchDebounced = _.debounce(this.videoSearch, 500);
    }

    

    componentDidMount() {
        this.props.onFetchVideoList(this.state.searchString, this.state.currentPage, this.state.rowsPerPage, this.props.history);
    }

    handleSearchChange = (event) => {
        this.videoSearchDebounced(event.target.value);
    }

    videoSearch = (searchString) => {
        this.setState({searchString, currentPage: 0}, () => this.props.onFetchVideoList(this.state.searchString, this.state.currentPage, this.state.rowsPerPage, this.props.history));
    }

    editVideo = (id, gcpId, thumbnailLoaded) => {
        this.props.onFetchVideoInfo(id, this.props.history);
        this.setState({ videoEditId: id, videoEditModalOpen: true, gcpVideoFolderId: gcpId, thumbnailLoaded });
    }

    onModalClose = () => {
        this.setState({ videoEditId: null, videoEditModalOpen: false, gcpVideoFolderId: null, thumbnailLoaded: false });
    }

    handleChangePage = (event, newPage) => {
        this.setState({currentPage: newPage}, () => this.props.onFetchVideoList(this.state.searchString, this.state.currentPage, this.state.rowsPerPage, this.props.history));
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: +event.target.value, currentPage: 0}, () => this.props.onFetchVideoList(this.state.searchString, this.state.currentPage, this.state.rowsPerPage, this.props.history));
    }

    render() {

        let videoTable = <CircularProgress className={this.props.classes.progress} />;

            let videoRows = this.props.stateVideoList.map(row => {

                let status = `Processing ${row.progress.step - 1}/3 (${row.progress.percentage}%)`;
                if (row.progress.step === 4 && row.progress.percentage === 100) {
                    status = "Processed";
                }

                return (
                    <TableRow key={row.id}>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell className={classes.disappear2} align="center">{status}</TableCell>
                        <TableCell className={classes.disappear1} align="center">{moment.utc(row.uploadDate).local().format('MM/DD/YYYY')}</TableCell>
                        <TableCell align="center">
                            <IconButton aria-label="close" size="small" onClick={() => this.editVideo(row.id, row.gcpVideoFolderId, status === "Processed")}>
                                <EditIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                );
            });

            videoTable = (
                <Paper className={this.props.classes.root}>
                    <Table className={this.props.stateVideoListLoading ? this.props.classes.tableFade : this.props.classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Name</TableCell>
                                <TableCell className={classes.disappear2} align="center">Status</TableCell>
                                <TableCell className={classes.disappear1} align="center">Date Uploaded</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {videoRows}
                        </TableBody>
                        
                    </Table>
                    {this.props.stateVideoListLoading && <CircularProgress size={48} className={this.props.classes.tableProgress} />}
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={this.props.stateVideoListCount}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.currentPage}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </Paper>
            );

        return (
            <div>
                <div className={this.props.classes.searchBar}>
                    <div className={this.props.classes.search}>
                        <div className={this.props.classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: this.props.classes.inputRoot,
                                input: this.props.classes.inputInput,
                            }}
                            onChange={this.handleSearchChange}
                        />
                    </div>
                </div>
                {videoTable}
                {this.state.videoEditModalOpen &&
                    <VideoEditPanel
                        videoEditId={this.state.videoEditId}
                        gcpVideoFolderId={this.state.gcpVideoFolderId}
                        modalOpen={this.state.videoEditModalOpen}
                        handleModalClose={this.onModalClose}
                        formValues={this.props.stateVideoInfo}
                        formValuesLoaded={!this.props.stateVideoInfoLoading && this.props.stateVideoInfoLoaded}
                        editVideoInfoSending={this.props.stateEditVideoInfoSending}
                        editVideoInfoSent={this.props.stateEditVideoInfoSent}
                        thumbnailLoaded={this.state.thumbnailLoaded}
                        thumbnailFile={this.props.stateVideoThumbnail}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        stateVideoList: state.app.videoList,
        stateVideoListLoading: state.app.videoListLoading,
        stateVideoListCount: state.app.videoListCount,
        stateVideoInfo: state.app.videoInfo,
        stateVideoInfoLoading: state.app.videoInfoLoading,
        stateVideoInfoLoaded: state.app.videoInfoLoaded,
        stateEditVideoInfoSending: state.app.editVideoInfoSending,
        stateEditVideoInfoSent: state.app.editVideoInfoSent,
        stateVideoThumbnail: state.app.videoThumbnail
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchVideoList: (searchString, currentPage, rowsPerPage, history) => dispatch(AppActions.fetchVideolistDataBegin(searchString, currentPage, rowsPerPage, history)),
        onFetchVideoInfo: (id, history) => dispatch(AppActions.fetchVideoInfoBegin(id, history))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VideoEditTab)));
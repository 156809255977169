import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import { withStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { green } from '@material-ui/core/colors';

import * as appActions from '../../../../store/ducks/app';
import config from '../../../../config/config';
import * as classes from './SettingsTab.module.css';

import StripeForm from '../../Stripe/StripeForm';
import SubscriptionEditPanel from './SubscriptionEditPanel/SubscriptionEditPanel';
import UserSettingsEditPanel from './UserSettingsEditPanel/UserSettingsEditPanel';
import ConfigEditPanel from './ConfigEditPanel/ConfigEditPanel';

const styles = theme => ({
    tableProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -24,
        marginLeft: -24,
    },
    avatar: {
        objectFit: 'cover',
        maxWidth: '100%',
        maxHeight: '100%',
        width: 'auto',
        height: 'auto'
    }
});

class SettingsTab extends Component {

    state = {
        paymentEditModalOpen: false,
        subscriptionEditModalOpen: false,
        userSettingsEditModalOpen: false,
        configEditModalOpen: false
        // imageHash: Date.now(),
        // interval: null
    }

    componentDidMount(){
        this.props.onFetchUserSettings(this.props.history);
        // let interval = setInterval(this.updateAvatar, 1000);
        // this.setState({interval});
    }

    // componentWillUnmount(){
    //     clearInterval(this.state.interval);
    // }

    editPayment = () => {
        this.setState({paymentEditModalOpen: true});
    }

    editSubscription = () => {
        this.setState({subscriptionEditModalOpen: true});
    }

    editUserSettings = () => {
        this.setState({userSettingsEditModalOpen: true});
    }

    editConfig = () => {
        this.setState({configEditModalOpen: true});
    }

    onPaymentModalClose = () => {
        this.setState({paymentEditModalOpen: false});
    }

    onSubscriptionModalClose = () => {
        this.setState({subscriptionEditModalOpen: false});
    }

    onUserSettingsModalClose = () => {
        this.setState({userSettingsEditModalOpen: false});
    }

    onConfigModalClose = () => {
        this.setState({configEditModalOpen: false});
    }

    // updateAvatar = () => {
    //     this.setState({imageHash: Date.now()});
    // }

    render(){

        let userImageUrl = `https://${config.development.cloud_bucket}.storage.googleapis.com/users/${this.props.stateAccountInfo.userFolderId}/avatar.png?avoidCache=1&reload8=${this.props.stateEditUserSettingsInfoSending}`;
        if(config.demo){
            let baseUrl = JSON.stringify(process.env.NODE_ENV) === JSON.stringify('development') ? config.development.proxy : config.production.base_url;
            userImageUrl = `${baseUrl}/files/users/${this.props.stateAccountInfo.userFolderId}/avatar.png?reload8=${this.props.stateEditUserSettingsInfoSending}`;
        }

        return(
            <div className={classes.page}>
                {this.props.stateAccountInfoLoading && <CircularProgress size={48} className={this.props.classes.tableProgress} />}
                <div style={{opacity: `${this.props.stateAccountInfoLoading ? .3 : 1}`}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography style={{fontWeight: 500, fontSize: '22px', marginBottom: '1rem'}}>
                            User Settings
                        </Typography>
                        <div style={{width: '24px', height: '24px'}}>
                            <IconButton size='small' onClick={() => this.editUserSettings()}>
                                <EditIcon />
                            </IconButton>
                        </div>
                    </div>
                    
                    <div style={{padding: '1rem', paddingBottom: '0'}}>
                        <div className={classes.settingItem}>
                            <Typography className={classes.settingName}>
                                Username:
                            </Typography>
                            <Typography className={classes.settingValue}>
                                {this.props.stateAccountInfo.userName}
                            </Typography>
                            
                        </div>
                        <Typography style={{fontWeight: 500, marginBottom: '.5rem'}}>
                            Profile Picture:
                        </Typography>
                        {this.props.stateAccountInfoLoaded && <div style={{width: '128px', height: '128px', display: 'flex', justifyContent: 'center', borderRadius: '50%', overflow: 'hidden', backgroundColor: '#eee'}}>
                            <img
                                className={this.props.classes.avatar}
                                alt=''
                                src={userImageUrl}>
                            </img>
                        </div>}
                    </div>

                    <Divider variant="middle" style={{margin: '2rem'}} />

                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography style={{fontWeight: 500, fontSize: '22px', marginBottom: '1rem'}}>
                            Payment Settings
                        </Typography>
                        {!!this.props.stateAccountInfo.apiKey && <div style={{width: '24px', height: '24px'}}>
                            <IconButton size='small' onClick={() => this.editPayment()}>
                                <EditIcon />
                            </IconButton>
                        </div>}
                    </div>

                    <div style={{padding: '1rem', paddingBottom: '0'}}>
                        <div className={classes.settingItem}>
                            <Typography className={classes.settingName}>
                                Card:
                            </Typography>
                            <Typography className={classes.settingValue}>
                                {this.props.stateAccountInfo.apiKey && !this.props.stateAccountInfo.apierror ? (this.props.stateAccountInfo.last4 ? 'xxxx-xxxx-xxxx-' + this.props.stateAccountInfo.last4 : 'none') : '*API Disabled*'}
                            </Typography>
                        </div>
                    </div>
                    
                    <Divider variant="middle" style={{margin: '2rem'}} />

                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography style={{fontWeight: 500, fontSize: '22px', marginBottom: '1rem'}}>
                            Subscription Settings
                        </Typography>
                        {(this.props.stateAccountInfo.apiKey && this.props.stateAccountInfo.last4) && <div style={{width: '24px', height: '24px'}}>
                            <IconButton size='small' onClick={() => this.editSubscription()}>
                                <EditIcon />
                            </IconButton>
                        </div>}
                    </div>

                    <div style={{padding: '1rem', paddingBottom: '0'}}>
                        <div className={classes.settingItem}>
                            <Typography className={classes.settingName}>
                                Subscription Type:
                            </Typography>
                            <Typography className={classes.settingValue}>
                                {this.props.stateAccountInfo.apiKey && !this.props.stateAccountInfo.apierror ? (this.props.stateAccountInfo.planType === 0 ? 'None' : this.props.stateAccountInfo.planType === 1 ? 'Basic' : 'Unlimited') : '*API Disabled*'}
                            </Typography>
                        </div>
                        <div className={classes.settingItem}>
                            <Typography className={classes.settingName}>
                                Subscription Status:
                            </Typography>
                            <Typography className={classes.settingValue}>
                                {this.props.stateAccountInfo.apiKey && !this.props.stateAccountInfo.apierror ? this.props.stateAccountInfo.subscriptionStatus : '*API Disabled*'}
                            </Typography>
                        </div>
                        <div className={classes.settingItem}>
                            <Typography className={classes.settingName}>
                                Subscription Expiration Date:
                            </Typography>
                            <Typography className={classes.settingValue}>
                                {this.props.stateAccountInfo.apiKey && !this.props.stateAccountInfo.apierror ? (this.props.stateAccountInfo.subscriptionEndDate ? moment.utc(this.props.stateAccountInfo.subscriptionEndDate*1000).local().format('MM-DD-YYYY') : 'Never') : '*API Disabled*'}
                            </Typography>
                        </div>
                    </div>
                    
                    {this.props.stateUserType === 3 && <Divider variant="middle" style={{margin: '2rem'}} />}

                    {this.props.stateUserType === 3 && <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography style={{fontWeight: 500, fontSize: '22px', marginBottom: '1rem'}}>
                            Config Settings
                        </Typography>
                        <div style={{width: '24px', height: '24px'}}>
                            <IconButton size='small' onClick={() => this.editConfig()}>
                                <EditIcon />
                            </IconButton>
                        </div>
                    </div>}

                    {this.props.stateUserType === 3 && <div style={{padding: '1rem', paddingBottom: '0'}}>
                        <div className={classes.settingItem}>
                            <Typography className={classes.settingName}>
                                Public API Key:
                            </Typography>
                            <Typography className={classes.settingValue}>
                                {this.props.stateAccountInfo.apiKey || 'not set'}
                            </Typography>
                        </div>
                        <div className={classes.settingItem}>
                            <Typography className={classes.settingName}>
                                Private API Key:
                            </Typography>
                            <Typography className={classes.settingValue}>
                                {this.props.stateAccountInfo.apiKeyPrivate || 'not set'}
                            </Typography>
                        </div>
                        <div className={classes.settingItem}>
                            <Typography className={classes.settingName}>
                                Basic Monthly Cost:
                            </Typography>
                            <Typography className={classes.settingValue}>
                                {this.props.stateAccountInfo.apiKey && !this.props.stateAccountInfo.apierror ? (this.props.stateAccountInfo.planAmountBasic ? '$' + this.props.stateAccountInfo.planAmountBasic : 'not set') : '*API Disabled*'}
                            </Typography>
                        </div>
                        <div className={classes.settingItem}>
                            <Typography className={classes.settingName}>
                                Unlimited Monthly Cost:
                            </Typography>
                            <Typography className={classes.settingValue}>
                                {this.props.stateAccountInfo.apiKey && !this.props.stateAccountInfo.apierror ? (this.props.stateAccountInfo.planAmount ? '$' + this.props.stateAccountInfo.planAmount : 'not set') : '*API Disabled*'}
                            </Typography>
                        </div>
                    </div>}
                    <StripeForm
                        modalOpen={this.state.paymentEditModalOpen}
                        handleModalClose={this.onPaymentModalClose}
                        editPaymentInfoSending={this.props.stateEditPaymentInfoSending}
                        editPaymentInfoSent={this.props.stateEditPaymentInfoSent}
                        apiKey={this.props.stateAccountInfo.apiKey}/>
                    <SubscriptionEditPanel
                        modalOpen={this.state.subscriptionEditModalOpen}
                        handleModalClose={this.onSubscriptionModalClose}
                        editSubscriptionInfoSending={this.props.stateEditSubscriptionInfoSending}
                        editSubscriptionInfoSent={this.props.stateEditSubscriptionInfoSent}
                        apiKey={this.props.stateAccountInfo.apiKey}
                        planType={this.props.stateAccountInfo.planType}
                        active={this.props.stateAccountInfo.subscriptionStatus === "Active" || this.props.stateAccountInfo.subscriptionStatus === "Payment Missed"} />
                    <UserSettingsEditPanel
                        gcpUserFolderId={this.props.stateAccountInfo.userFolderId}
                        modalOpen={this.state.userSettingsEditModalOpen}
                        handleModalClose={this.onUserSettingsModalClose}
                        formValues={{username: this.props.stateAccountInfo.userName}}
                        formValuesLoaded={true}
                        editUserSettingsInfoSending={this.props.stateEditUserSettingsInfoSending}
                        editUserSettingsInfoSent={this.props.stateEditUserSettingsInfoSent}
                        avatarLoaded={true}
                        avatarFile={this.props.stateUserAvatar} />
                    <ConfigEditPanel
                        modalOpen={this.state.configEditModalOpen}
                        handleModalClose={this.onConfigModalClose}
                        formValues={{publickey: this.props.stateAccountInfo.apiKey, privatekey: this.props.stateAccountInfo.apiKeyPrivate, cost_basic: this.props.stateAccountInfo.planAmountBasic, cost: this.props.stateAccountInfo.planAmount}}
                        formValuesLoaded={true}
                        editConfigInfoSending={this.props.stateEditConfigInfoSending}
                        editConfigInfoSent={this.props.stateEditConfigInfoSent} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        stateAccountInfo: state.app.accountInfo,
        stateEditPaymentInfoSending: state.app.editPaymentInfoSending,
        stateEditPaymentInfoSent: state.app.editPaymentInfoSent,
        stateEditSubscriptionInfoSending: state.app.editSubscriptionInfoSending,
        stateEditSubscriptionInfoSent: state.app.editSubscriptionInfoSent,
        stateEditUserSettingsInfoSending: state.app.editUserSettingsInfoSending,
        stateEditUserSettingsInfoSent: state.app.editUserSettingsInfoSent,
        stateEditConfigInfoSending: state.app.editConfigInfoSending,
        stateEditConfigInfoSent: state.app.editConfigInfoSent,
        stateUserAvatar: state.app.userAvatar,
        stateAccountInfoLoading: state.app.accountInfoLoading,
        stateAccountInfoLoaded: state.app.accountInfoLoaded,
        stateUserType: state.app.userType
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchUserSettings: history => dispatch(appActions.fetchUserSettingsBegin(history))
    };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(SettingsTab)));
import React from 'react';

import moment from 'moment';

import { Link, withRouter } from 'react-router-dom';

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";

import config from '../../../../../config/config';

const cardWidth = 210;
const cardPad = 3;

const useStyles = makeStyles(theme => ({
    card: {
        width: cardWidth
    },
    media: {
        height: 118,
        backgroundSize: "100% 100%"
    },
    twoLines: {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "16px",
        minHeight: '32px'
    },
    textSmaller: {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
        fontSize: "13px",
        fontWeight: "400",
        lineHeight: "15px"
    },
    textOwner: {
        marginTop: '1rem',
        marginBottom: '4px',
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "15px"
    }
}));

const VideoCard = (props) => {

    const classes = useStyles();

    let imageUrl = `https://${config.development.cloud_bucket}.storage.googleapis.com/videos/${props.GCPid}/thumbnail.png?avoidCache=1`;
    if(config.demo){
        let baseUrl = JSON.stringify(process.env.NODE_ENV) === JSON.stringify('development') ? config.development.proxy : config.production.base_url;
        imageUrl = `${baseUrl}/files/videos/${props.GCPid}/thumbnail.png`;
    }

    return (
        <div
            key={props.id}
            style={{
                boxSizing: "border-box",
                padding: `${cardPad}px ${cardPad}px`
            }}
        >
            {/* <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={{ pathname: `/watch/${props.id}` }}> */}
                <Card className={classes.card} onClick={() => props.history.push(`/watch/${props.id}`)}>
                    <CardActionArea>
                        <CardMedia
                            className={classes.media}
                            image={imageUrl}
                        />
                        <CardContent>
                            <Typography
                                gutterBottom
                                className={classes.twoLines}
                            >
                                {props.title}
                            </Typography>
                            <Link onClick={(e) => e.stopPropagation()} style={{ color: 'inherit', textDecoration: 'inherit' }} to={{ pathname: `/user/${props.ownerId}` }}>
                                <Typography
                                    color="textSecondary"
                                    className={classes.textOwner}
                                >
                                    {props.ownerName}
                                </Typography>
                            </Link>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                            >
                                <Typography
                                    color="textSecondary"
                                    className={
                                        classes.textSmaller
                                    }
                                >
                                    {moment.utc(props.dateUploaded).local().fromNow()}
                                </Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: 'center'
                                    }}>
                                    <RemoveRedEyeIcon color="disabled" style={{ fontSize: 14 }} />
                                    <Typography
                                        color="textSecondary"
                                        className={
                                            classes.textSmaller
                                        }
                                    >
                                        {'\u00A0'+props.views.toLocaleString()}
                                    </Typography>
                                </div>
                            </div>
                        </CardContent>
                    </CardActionArea>
                </Card>
            {/* </Link> */}
        </div>
    );
}

export default withRouter(VideoCard);
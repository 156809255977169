import React from 'react';

import { withRouter } from 'react-router-dom';

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import config from '../../../../../config/config';

const cardWidth = 210;
const cardPad = 3;

const useStyles = makeStyles(theme => ({
    card: {
        width: cardWidth
    },
    media: {
        height: 118,
        backgroundSize: "100% 100%"
    },
    twoLines: {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "16px",
        minHeight: '32px'
    },
    textSmaller: {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
        fontSize: "13px",
        fontWeight: "400",
        lineHeight: "15px"
    },
    textOwner: {
        marginTop: '1rem',
        marginBottom: '4px',
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "15px"
    }
}));

const UserCard = (props) => {

    const classes = useStyles();

    let userImageUrl = `https://${config.development.cloud_bucket}.storage.googleapis.com/users/${props.GCPid}/avatar.png?avoidCache=1`;
    if(config.demo){
        let baseUrl = JSON.stringify(process.env.NODE_ENV) === JSON.stringify('development') ? config.development.proxy : config.production.base_url;
        userImageUrl = `${baseUrl}/files/users/${props.GCPid}/avatar.png`;
    }

    return (
        <div
            key={props.id}
            style={{
                boxSizing: "border-box",
                padding: `${cardPad}px ${cardPad}px`
            }}
        >
            {/* <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={{ pathname: `/watch/${props.id}` }}> */}
                <Card className={classes.card} onClick={() => props.history.push(`/user/${props.id}`)}>
                    <CardActionArea>
                        <CardMedia
                            className={classes.media}
                            image={userImageUrl}
                        />
                        <CardContent>
                            <Typography
                                gutterBottom
                                className={classes.twoLines}
                            >
                                {props.name}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            {/* </Link> */}
        </div>
    );
}

export default withRouter(UserCard);
import React, { Component } from "react";

import _ from "lodash";

import { withStyles } from "@material-ui/core/styles";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Fab from "@material-ui/core/Fab";

import VideoCard from './VideoCard/VideoCard';

const cardWidth = 210;
const cardPad = 3;

const styles = theme => ({
    card: {
        width: cardWidth
    },
    media: {
        height: 118,
        backgroundSize: "100% 100%"
    },
    twoLines: {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "16px",
        minHeight: '32px'
    },
    textSmaller: {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
        fontSize: "13px",
        fontWeight: "400",
        lineHeight: "18px"
    }
});

class VideoScroller extends Component {
    // render: function() {
    //     return <span>{this.state.width} x {this.state.height}</span>;
    // },

    constructor(props) {
        super(props);
        this.slider = React.createRef();
        this.slidingContent = React.createRef();
        this.state = {
            sliderWidth: 0,
            scrolling: false
        };
        this.updateDimensionsThrottle = _.debounce(this.updateDimensions, 200);
    }

    scrollContent = (left) => {
        if (left) {

        } else {

        }
    }

    cardCountToWidth = (count) => {
        return Math.min(Math.max(count, 1), 5) * cardWidth + Math.min(Math.max(count, 1), 5) * cardPad * 2;
    }

    cardCountToWidthNoClamp = (count) => {
        return count * cardWidth + count * cardPad * 2;
    }

    scrollRight = () => {
        if (this.state.scrolling) return;
        this.setState({ scrolling: true });
        let start = this.slidingContent.current.scrollLeft,
            currentTime = 0,
            increment = 10,
            duration = 300;

        let change = Math.max(Math.min(this.cardCountToWidthNoClamp(this.props.videos.length) - (this.state.sliderWidth - 48) - start, this.state.sliderWidth - 48), 0);

        let animateScroll = () => {
            currentTime += increment;
            this.slidingContent.current.scrollLeft = this.easeInOutQuad(currentTime, start, change, duration);
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            } else {
                this.setState({ scrolling: false });
            }
        };
        animateScroll();
    }

    scrollLeft = () => {
        if (this.state.scrolling) return;
        this.setState({ scrolling: true });
        let start = this.slidingContent.current.scrollLeft,
            currentTime = 0,
            increment = 10,
            duration = 300;

        let change = Math.max(Math.min(start, this.state.sliderWidth - 48), 0);

        let animateScroll = () => {
            currentTime += increment;
            this.slidingContent.current.scrollLeft = this.easeInOutQuad(currentTime, start, -change, duration);
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            } else {
                this.setState({ scrolling: false });
            }
        };
        animateScroll();
    }

    //t = current time
    //b = start value
    //c = change in value
    //d = duration
    easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };

    updateDimensions = () => {
        if(!this.slider || !this.slider.current) return;
        let width = this.slider.current.offsetWidth;
        const cardAmount = Math.floor((width - 48) / (cardWidth + cardPad * 2));
        this.setState({ sliderWidth: this.cardCountToWidth(cardAmount) + 48 });
    };

    componentDidMount = () => {
        this.updateDimensionsThrottle();
        window.addEventListener("resize", this.updateDimensionsThrottle);
    };

    componentWillUnmount = () => {
        window.removeEventListener("resize", this.updateDimensionsThrottle);
    };

    render() {
        let videoCardList = this.props.videos.map(row => {
            return (
                <VideoCard 
                    key={row.id}
                    id={row.id}
                    views={row.views}
                    GCPid={row.GCPid}
                    title={row.title}
                    dateUploaded={row.dateUploaded}
                    ownerId={row.ownerId}
                    ownerName={row.ownerName}
                />
            );
        });

        return (
            <div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        height: '238px',
                        marginBottom: '2rem'
                    }}
                    ref={this.slider}
                >
                    <div
                        style={{
                            width: `${this.state.sliderWidth}px`,
                            position: "relative"
                        }}
                    >
                        <div
                            style={{
                                margin: "0 24px",
                                overflow: "hidden"
                            }}
                            ref={this.slidingContent}
                        >
                            <div
                                style={{
                                    display: "flex"
                                }}

                            >
                                {videoCardList}
                            </div>
                        </div>

                        <Fab
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "0",
                                marginTop: "-24px",
                                marginLeft: "4px"
                            }}
                            onClick={this.scrollLeft}
                        >
                            <KeyboardArrowLeftIcon />
                        </Fab>
                        <Fab
                            style={{
                                position: "absolute",
                                top: "50%",
                                right: "0",
                                marginTop: "-24px",
                                marginRight: "4px"
                            }}
                            onClick={this.scrollRight}
                        >
                            <KeyboardArrowRightIcon />
                        </Fab>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(VideoScroller);

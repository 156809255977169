import React, { Component } from 'react';
import { connect } from 'react-redux';
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';
import SignupStripeForm from '../Stripe/SignupStripeForm';
import config from '../../../config/config';

import * as appActions from '../../../store/ducks/app';

import * as classes from './PaymentMethodPage.module.css';

const styles = theme => ({
    text: {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
        fontSize: "2.5rem",
        fontWeight: "200",
        lineHeight: "3rem",
        color: '#FFF',
        margin: '1rem'
    },
});

class PaymentMethodPage extends Component {

    state = {
        windowObjectReference: null,
        previousUrl: null
    }

    componentDidMount() {
        this.props.onSignupInfoBegin();
    }

    render() {
        return (
            <div>
                <div style={{ backgroundImage: 'linear-gradient(to bottom right, blue, purple)', width: '100vw', height: '20vh' }}>
                    <div style={{ width: '100%', height: '10%', display: 'flex', justifyContent: 'space-between' }}>
                        <Typography onClick={() => this.props.history.push('/welcome')} style={{ margin: '1rem', color: '#fff', fontSize: '1.5rem', fontWeight: '500', cursor: 'pointer' }}>
                            {config.demo ? 'Home' : 'SprkGlobal'}
                        </Typography>
                        {!this.props.stateUserLoggedInStatus ? <Typography onClick={() => this.props.history.push('/login')} style={{margin: '1rem', color: '#fff', fontSize: '1rem', fontWeight: '400', cursor: 'pointer'}}>
                            LOG IN
                        </Typography> :
                        <Typography onClick={() => this.props.onLogout(this.props.history)} style={{margin: '1rem', color: '#fff', fontSize: '1rem', fontWeight: '400', cursor: 'pointer'}}>
                            LOG OUT
                        </Typography>}
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ borderBottom: '2px solid #ccc', width: '80%', maxWidth: '450px', marginBottom: '1.5rem', display: 'flex', justifyContent: 'center' }}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Typography className={classes.headingText}>
                                Add Payment Method
                            </Typography>
                            {config.demo && <Typography style={{ marginBottom: '.5rem', fontSize: '.8rem' }}>
                                * For demo purposes, use test card 4242-4242-4242-4242.
                            </Typography>}
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                    <div style={{width: '100%',  maxWidth: '450px'}}>
                        {this.props.stateSignupApiKey && <SignupStripeForm
                            editPaymentInfoSending={this.props.stateEditPaymentInfoSending}
                            editPaymentInfoSent={this.props.stateEditPaymentInfoSent}
                            apiKey={this.props.stateSignupApiKey}/>}
                    </div>
                    
                </div>
            </div>
        );
    }
};



const mapStateToProps = state => {
    return {
        stateSignupBasicCost: state.app.signupBasicCost,
        stateSignupUnlimitedCost: state.app.signupUnlimitedCost,
        stateEditPaymentInfoSending: state.app.editPaymentInfoSending,
        stateEditPaymentInfoSent: state.app.editPaymentInfoSent,
        stateSignupApiKey: state.app.signupApiKey,
        stateUserLoggedInStatus: state.app.userLoggedInStatus,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSignupInfoBegin: () => dispatch(appActions.fetchSignupInfoBegin()),
        onFetchUserLoggedInStatus: () => dispatch(appActions.fetchUserLoggedInStatusBegin()),
        onEditChosenPlan: (plan) => dispatch(appActions.editChosenPlan(plan)),
        onLogout: (history) => dispatch(appActions.logout(history)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaymentMethodPage)));
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import GoogleButton from 'react-google-button';
import { withRouter } from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";
import config from '../../../config/config';

import * as classes from './CreateAccountPage.module.css';

import * as appActions from '../../../store/ducks/app';

const styles = theme => ({
    text: {
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
        fontSize: "2.5rem",
        fontWeight: "200",
        lineHeight: "3rem",
        color: '#FFF',
        margin: '1rem'
    },
});

class CreateAccountPage extends Component {

    state = {
        windowObjectReference: null,
        previousUrl: null,
        loading: false,
    }

    receiveMessage = event => {
        // Do we trust the sender of this message? (might be
        // different from what we originally opened, for example).
        if (event.origin !== window.location.origin || !event.data.loginSuccess) {
            return;
        }
        window.removeEventListener('message', this.receiveMessage);
        this.props.onFetchUserLoggedInStatus(this.props.history, true);
        this.setState({loading: true});
    };

    openSignInWindow = (url, name) => {
        // remove any existing event listeners
        window.removeEventListener('message', this.receiveMessage);

        // window features
        const strWindowFeatures =
            'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

        if (this.state.windowObjectReference === null || this.state.windowObjectReference.closed) {
            /* if the pointer to the window object in memory does not exist
             or if such pointer exists but the window was closed */
            let windowObjectReference = window.open(url, name, strWindowFeatures);
            this.setState({ windowObjectReference });
        } else if (this.state.previousUrl !== url) {
            /* if the resource to load is different,
             then we load it in the already opened secondary window and then
             we bring such window back on top/in front of its parent window. */
            let windowObjectReference = window.open(url, name, strWindowFeatures);
            this.setState({ windowObjectReference });
            windowObjectReference.focus();
        } else {
            /* else the window reference must exist and the window
             is not closed; therefore, we can bring it back on top of any other
             window with the focus() method. There would be no need to re-create
             the window or to reload the referenced resource. */
            this.state.windowObjectReference.focus();
        }

        // add the listener for receiving a message from the popup
        window.addEventListener('message', this.receiveMessage, false);
        // assign the previous URL
        this.setState({ previousUrl: url });
    };

    componentDidMount() {
        this.props.onDummyPageInit(this.props.history);
    }

    render() {
        return (
            <div>
                <div className={classes.pageHeader}>
                    <div className={classes.pageHeaderBar}>
                        <Typography onClick={() => this.props.history.push('/welcome')} className={classes.homeButton}>
                            {config.demo ? 'Home' : 'SprkGlobal'}
                        </Typography>
                    </div>
                </div>
                {!this.state.loading ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ borderBottom: '2px solid #ccc', maxWidth: '400px', width: '75%', marginBottom: '1.5rem', display: 'flex', justifyContent: 'center' }}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Typography style={{ margin: '1rem', marginTop: '2rem', fontSize: '1.5rem', fontWeight: '500' }}>
                                Create Account
                            </Typography>
                            {config.demo && <Typography style={{ marginBottom: '.5rem', fontSize: '.8rem' }}>
                                * For demo purposes, your account is initially set to admin.
                            </Typography>}
                        </div>
                        
                    </div>
                    <GoogleButton
                        type="dark"
                        onClick={() => {
                            // this.props.history.push('/auth/google'); 
                            this.openSignInWindow('/auth/google', 'Sign In');
                            // window.location.reload();
                        }}
                    />
                </div> : <div style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}><CircularProgress /></div>}
            </div>
        );
    }
};



const mapStateToProps = state => {
    return {
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onDummyPageInit: (history) => dispatch(appActions.dummyPageInit(history)),
        onFetchUserLoggedInStatus: (history, isCreatingAccount) => dispatch(appActions.fetchUserLoggedInStatusBegin(history, isCreatingAccount)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateAccountPage)));
import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CheckIcon from '@material-ui/icons/Check';

import * as classes from './SubCard.module.css';

class SubCard extends Component {
    render() {
        return (
            <Card className={classes.card} onClick={() => { }}>
                <CardContent>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Typography
                            gutterBottom
                            className={classes.planName}
                        >
                            {this.props.planName}
                            </Typography>
                        <div style={{display: 'flex', alignItems: 'baseline'}}>
                            <Typography
                                className={classes.price}
                            >
                                {'$' + this.props.cost + '/'}
                            </Typography>
                            <Typography
                                className={classes.month}
                            >
                                month
                            </Typography>
                        </div>
                        <Button variant="outlined" size="large" color="primary" className={classes.chooseButton} onClick={this.props.onSelect}>
                            Select Plan
                        </Button>
                        <Divider style={{marginBottom: '1.5rem', width: '100%'}} />
                    </div>
                    {this.props.info.map(row => {
                        return(<div key={row} style={{display: 'flex', alignItems: 'flex-start', marginBottom: '1rem'}}>
                            <CheckIcon style={{color: '#4CB521', marginRight: '.5rem'}} />
                            <Typography
                                className={classes.month}
                            >
                                {row}
                            </Typography>
                        </div>);
                    })}
                </CardContent>
            </Card>
        );
    }
}

export default SubCard;
import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { green } from '@material-ui/core/colors';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {withRouter} from 'react-router-dom';
import axios from 'axios';

import FieldComponent from "../../../../Forms/FieldComponent/FieldComponent";

import * as AppActions from "../../../../../store/ducks/app";

import * as classes from './UserSettingsEditPanel.module.css';

import config from '../../../../../config/config'; 

let URLPrefix = `https://${config.development.cloud_bucket}.storage.googleapis.com/users/`;
if(config.demo){
    let baseUrl = JSON.stringify(process.env.NODE_ENV) === JSON.stringify('development') ? config.development.proxy : config.production.base_url;
    URLPrefix = `${baseUrl}/files/users/`;
}

const styles = theme => ({
    root: {
        width: "100%",
        overflowX: "auto"
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    progress: {
        margin: theme.spacing(2)
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

const fields = [
    { label: "Username", name: "username", componentType: "input" }
]

class UserSettingsEditPanel extends Component {

    constructor(props) {
        super(props);

        this.fileInputRef = React.createRef();
        this.avatarImgRef = React.createRef();
    }

    onFormSubmit = (values, actions) => {
        this.props.onEditUserSettingsInfo(values.username, this.props.history);
        actions.setSubmitting(false);
    };

    onFormClose = () => {
        this.props.onAvatarAdd(null, this.props.history);
        this.props.handleModalClose();
    }

    handleAvatarClick = () => {
        this.fileInputRef.current.click();
    }

    fileListToArray = list => {
        const array = [];
        for (let i = 0; i < list.length; i++) {
            array.push(list.item(i));
        }
        return array;
    };

    onAvatarAdded = event => {
        const file = event.target.files.item(0);
        this.props.onAvatarAdd(file, this.props.history);
        this.fileInputRef.current.value = null;
    };

    getValidationSchema = () => {
        return Yup.object().shape({
            username: Yup.string().required("You must provide a value").test(
                'duplicate',
                'Duplicate Username',
                async (value) => (await axios.get('/api/checkduplicateusername/' + btoa(value))).data.duplicate === false,
            )
        });
    }

    renderFields = (values, errors, touched, handleBlur, handleChange, setFieldValue) => {
        return fields.map(({ label, name, componentType, maxAutocompleteSelections }) => {
            return (
                <FieldComponent
                    key={name}
                    name={name}
                    type="text"
                    componentType={componentType}
                    label={label}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[name]}
                    error={errors[name]}
                    touched={touched[name]}
                    setFieldValue={setFieldValue}
                    maxAutocompleteSelections={maxAutocompleteSelections}
                />
            );
        });
    };

    render() {
        const DialogTitle = withStyles(theme => ({
            root: {
                margin: 0,
                padding: theme.spacing(2)
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500]
            }
        }))(props => {
            const { children, classes } = props;
            return (
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">{children}</Typography>
                </MuiDialogTitle>
            );
        });

        const DialogContent = withStyles(theme => ({
            root: {
                padding: theme.spacing(2)
            }
        }))(MuiDialogContent);

        const DialogActions = withStyles(theme => ({
            root: {
                margin: 0,
                padding: theme.spacing(1)
            }
        }))(MuiDialogActions);

        let initialValue = {};
        fields.forEach(elt => {
            initialValue[elt.name] = this.props.formValues[elt.name] || "";
            
        });

        let avatar = (<div style={{backgroundColor: '#555', color: '#ddd', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '210px', height: '118px'}}>
            <Typography>Processing...</Typography>
        </div>);

        if(this.props.avatarFile){
            const reader = new FileReader();
            reader.onload = (e) => {
                if(this.avatarImgRef.current){
                    this.avatarImgRef.current.src = e.target.result;
                }
            }
            reader.readAsDataURL(this.props.avatarFile);
        }

        if(this.props.avatarLoaded || this.props.avatarFile){

            avatar = (<div onClick={this.handleAvatarClick} className={classes.Avatar} style={{position: 'relative', width: '128px', height: '128px', display: 'flex', justifyContent: 'center', borderRadius: '50%', overflow: 'hidden', backgroundColor: '#eee'}}>
                <input
                    ref={this.fileInputRef}
                    type="file"
                    onChange={this.onAvatarAdded}
                    style={{display: 'none'}}
                />
                <img width="100%" height="100%" alt="" src={`${URLPrefix}${this.props.gcpUserFolderId}/avatar.png?avoidCache=1`} ref={this.avatarImgRef} />
                <div  className={classes.Front}>
                    <Typography>Change Avatar</Typography>
                </div>
            </div>)
        }

        return (
            <Dialog
                onClose={this.props.handleModalClose}
                open={this.props.modalOpen}
                className={this.props.classes.root}
                fullWidth
                classes={{
                    paper: classes.dialog,
                }}
            >
                {!this.props.formValuesLoaded ? (
                    <CircularProgress className={this.props.classes.progress} />
                ) : (
                    <Formik
                        initialValues={initialValue}
                        onSubmit={this.onFormSubmit}
                        validationSchema={this.getValidationSchema()}
                        render={({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange, 
                            setFieldValue
                        }) => (
                            <Form>
                                <DialogTitle>Edit User Settings</DialogTitle>
                                <DialogContent dividers>
                                    {this.renderFields(
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        setFieldValue
                                    )}
                                    <div>
                                        <Typography>
                                            Avatar
                                        </Typography>
                                        {avatar}
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <div className={this.props.classes.wrapper}>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            disabled={this.props.editUserSettingsInfoSending}
                                        >
                                            Save changes
                                        </Button>
                                        {this.props.editUserSettingsInfoSending && <CircularProgress size={24} className={this.props.classes.buttonProgress} />}
                                    </div>
                                    
                                    <Button
                                        onClick={this.onFormClose}
                                        color="secondary"
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    />
                )}
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditUserSettingsInfo: (username, history) =>
            dispatch(AppActions.editUserSettingsInfoBegin(username, history)),
        onAvatarAdd: (file, history) =>
            dispatch(AppActions.avatarAdd(file, history))
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(UserSettingsEditPanel)));

import React, { Component } from "react";

import * as classes from "./Dropzone.module.css";

import Typography from '@material-ui/core/Typography';

import CloudUpload from "../../UI/CloudUpload/CloudUpload";

class Dropzone extends Component {
    constructor(props) {
        super(props);

        this.state = {
            highlight: false
        };

        this.fileInputRef = React.createRef();
    }

    openFileDialog = () => {
        this.fileInputRef.current.click();
    };

    fileListToArray = list => {
        const array = [];
        for (let i = 0; i < list.length; i++) {
            array.push(list.item(i));
        }
        return array;
    };

    onFilesAdded = event => {
        const files = event.target.files;
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array);
        }
        this.fileInputRef.current.value = null;
    };

    onDragOver = event => {
        event.preventDefault();
        this.setState({ highlight: true });
    };

    onDragLeave = () => {
        this.setState({ highlight: false });
    };

    onDrop = event => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array);
        }
        this.setState({ highlight: false });
    };

    render() {
        let dropzoneClasses = [];
        dropzoneClasses.push(classes.Dropzone);
        if (this.state.highlight) {
            dropzoneClasses.push(classes.Highlight);
        }

        return (
            <div
                className={dropzoneClasses.join(" ")}
                style={{ cursor: "pointer" }}
                onClick={this.openFileDialog}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
            >
                <div 
                    className={classes.Icon}
                >
                    <CloudUpload />
                </div>
                <input
                    ref={this.fileInputRef}
                    className={classes.FileInput}
                    type="file"
                    multiple
                    onChange={this.onFilesAdded}
                />
                <Typography>Upload Files</Typography>
            </div>
        );
    }
}

export default Dropzone;

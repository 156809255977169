import React, { Component } from "react";
import {connect} from 'react-redux';
import * as classes from "./FileUploader.module.css";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import {withRouter} from 'react-router-dom';

import Dropzone from "./Dropzone/Dropzone";
import * as appActions from '../../store/ducks/app';

const styles = theme => ({
    closeButton: {
      color: theme.palette.grey[500],
    },
  });

class Upload extends Component {

    uploadFiles = async () => {
        this.props.stateUploadVideoList.forEach(video => {
            this.props.uploadVideo(video, this.props.history);
        });
    };

    onFilesAdded = files => {
        const duplicateRemoved = files.reduce((accumulator, file) => {
            if(!this.props.stateUploadVideoList.some(({file: video}) => video.name === file.name)){
                return accumulator.concat([file]);
            }
            return accumulator;
        }, []);
        this.props.uploadVideoAdd(duplicateRemoved, this.props.history);
    };

    onFilesRemoved = keys => {
        this.props.uploadVideoRemove(keys, this.props.history);
    };

    renderActions = () => {
        if (this.props.stateUploadVideoList.length > 0 && this.props.stateUploadVideoList.every(elt => elt.uploaded)) {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    style={{margin: '1rem'}}
                    onClick={() =>
                        this.onFilesRemoved(this.props.stateUploadVideoList.map(elt => elt.key))
                    }
                >
                    Clear
                </Button>
            );
        } else {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    style={{margin: '1rem'}}
                    disabled={
                        this.props.stateUploadVideoList.length <= 0 || this.props.stateUploadVideoList.some(elt => elt.uploading)
                    }
                    onClick={this.uploadFiles}
                >
                    Upload
                </Button>
            );
        }
    };

    render() {
        let files = this.props.stateUploadVideoList.map(video => {

            let progressBar = video.uploading && (
                <div
                    style={{width: '100%', height: '100%', marginTop: '.5rem'}}
                >
                    <LinearProgress variant="determinate" value={video.uploadProgress.percentage} />
                    {/* <ProgressBar 
                        percentage={video.uploadProgress.percentage} 
                    /> */}
                </div>
            );

            return (
                <div 
                    key={video.file.name} 
                    className={classes.Row}
                >
                    <div
                        style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                    >
                        <Typography>
                            {video.file.name + (video.uploading ? (' - ' + Math.round(video.uploadProgress.percentage)) + '%' : '')}
                        </Typography>
                        {!(video.uploading || video.uploaded) && <IconButton aria-label="close" size="small" className={this.props.classes.closeButton} onClick={() => this.onFilesRemoved([video.key])}>
                            <CloseIcon />
                        </IconButton>}
                    </div>
                    {progressBar}
                </div>
            );
        });

        return (
            <div className={classes.Upload}>
                <div className={classes.Content}>
                    <Dropzone
                        onFilesAdded={this.onFilesAdded}
                    />
                    <div className={classes.Files}>{files}</div>
                </div>
                <div className={classes.Actions}>{this.renderActions()}</div>                
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        stateUploadVideoList: state.app.uploadVideoList
    };
}

const mapDispatchToProps = dispatch => {
    return {
        uploadVideo: (file, history) => dispatch(appActions.uploadVideoBegin(file, history)),
        uploadVideoAdd: (files, history) => dispatch(appActions.uploadVideoAdd(files, history)),
        uploadVideoRemove: (keys, history) => dispatch(appActions.uploadVideoRemove(keys, history))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Upload)));
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import AutoCompleteSuggestions from './AutoCompleteSuggestions/AutoCompleteSuggestions';
import {StripeProvider} from 'react-stripe-elements';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import Downshift from 'downshift';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';
import { FormLabel } from '@material-ui/core';

import Stripe from '../../App/Stripe/Stripe';

import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const FieldComponent = ({ name, type, componentType, label, onChange, onBlur, value, error, touched, setFieldValue, maxAutocompleteSelections, userSuggestionsSearch, status, setStatus, setFieldTouched, setFieldError, apiKey}) => {

    const UIClasses = makeStyles(theme => ({
        container: {
          display: 'flex',
          flexWrap: 'wrap',
        },
        formControl: {
          padding: theme.spacing(1),
          boxSizing: 'border-box',
          position: 'relative'
        },
        formControlSelect: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        rootSelect: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        root: {
            flexGrow: 1,
            height: 250,
        },
        inputContainer: {
            flexGrow: 1,
            position: 'relative',
        },
        chip: {
            margin: theme.spacing(0.5, 0.25),
        },
      }))();

    let componentInput = (
        <FormControl className={UIClasses.formControl} error={touched && error && true} fullWidth>
            <InputLabel htmlFor="component-error">{label}</InputLabel>
            <Input
                autoComplete={'off'}
                multiline={componentType === "textarea"} rows={componentType === "textarea" ? "4" : "1"}
                name={name} type={type} onChange={onChange} onBlur={onBlur} value={value}
            />
            {touched && error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );

    if(componentType === "switch"){
        componentInput = (
            <FormControl className={UIClasses.formControl} error={touched && error && true} fullWidth>
                <FormLabel htmlFor="component-error">{label}</FormLabel>
                <Switch
                    checked={value}
                    name={name}
                    color="primary"
                    onChange={onChange} />
                {touched && error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        );
    }

    if(componentType === "payment"){
        // console.log(touched)
        // value.promise instanceof Function && (value.promise)().then(res => res.error && console.log(res.error.message));
        const handleChangePayment = (value) => {
            // console.log(value)
            setFieldError(name, value.error && value.error.message);
            setFieldValue(name, value);
        }
        const handleBlurPayment = () => {
            setFieldTouched(name, true);
        }
        componentInput = (
            <FormControl className={UIClasses.formControl} error={touched && (error || status) && true} fullWidth>
                <FormLabel style={{marginBottom: '.5rem'}} htmlFor="component-error">{label}</FormLabel>
                <StripeProvider apiKey={apiKey}>
                    <Stripe
                        onChange={handleChangePayment}
                        onBlur={handleBlurPayment}
                    />
                </StripeProvider>
                {touched && (error || status) && <FormHelperText>{(error || status)}</FormHelperText>}
            </FormControl>
            
        );
    }

    if(componentType === "date"){

        const handleChangeDate = (item) => {
            setFieldValue(name, item);
        }
        const handleOnError = (error) => {
            if(error !== status){
                setStatus(name, error);
            }            
        }
        componentInput = (
            <div>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        margin="normal"
                        label={label}
                        value={value}
                        onChange={handleChangeDate}
                        error={!!((touched && error) || status)}
                        helperText={error || status}
                        onError={handleOnError}
                    />
                </MuiPickersUtilsProvider>
            </div>
        );
    }

    if(componentType === "dropdown"){

        const handleInputChange = (event) => {
            const changeValue = event.target.value;
            setFieldValue(name, changeValue);
        }

        componentInput = (
            <FormControl className={UIClasses.formControl} error={touched && error && true} fullWidth>
                <FormLabel htmlFor="component-error">{label}</FormLabel>
                <Select
                    value={value}
                    onChange={handleInputChange}
                    >
                    <MenuItem value={1}>User</MenuItem>
                    <MenuItem value={2}>Filmmaker</MenuItem>
                    <MenuItem value={3}>Admin</MenuItem>
                </Select>
                {touched && error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        );
    }

    if(componentType === "autocomplete"){
        const handleKeyDown = (event) => {
            if (value.selectedItem.length && !value.inputValue.length && event.key === 'Backspace') {
                setFieldValue(name, {...value, selectedItem: value.selectedItem.slice(0, value.selectedItem.length - 1)});
            }
        }
    
        const handleInputChange = (event) => {
            const changeValue = event.target.value;
            if(changeValue && name === "owner"){
                userSuggestionsSearch(changeValue, 3);
            }
            setFieldValue(name, { ...value, inputValue: changeValue});
        }
    
        const handleChange = (item) => {
            let newSelectedItem = [...value.selectedItem];
            if (newSelectedItem.findIndex((elt) => elt.id === item.id) === -1) {
                newSelectedItem = [...newSelectedItem, item];
            }
            setFieldValue(name, {
                ...value,
                inputValue: '',
                selectedItem: newSelectedItem
            });
        }
    
        const handleDelete = item => () => {
            const newSelectedItem = [...value.selectedItem];
            newSelectedItem.splice(newSelectedItem.findIndex((elt) => elt.id === item.id), 1);
            setFieldValue(name, {
                ...value,
                selectedItem: newSelectedItem
            });
        };
        componentInput = (
            
                <Downshift
					inputValue={value.inputValue}
					onChange={handleChange}
                    selectedItem={value.selectedItem}
                    itemToString={item => (item ? item.id : '')}
					>
					{({
						getInputProps,
						isOpen,
						inputValue: inputValue2,
						selectedItem: selectedItem2,
                        highlightedIndex,
                        selectItem
					}) => {
						const { onBlur: onBlurInput, onChange: onChangeInput, onFocus: onFocusInput, onKeyDown: onKeyDownInput } = getInputProps({
                            onKeyDown: handleKeyDown,
                            onBlur,
                        });
						return (
						<div>
                            <FormControl className={UIClasses.formControl} error={touched && error && true} fullWidth>
                                <InputLabel htmlFor="component-error">{label}</InputLabel>
                                <Input
                                    
                                    readOnly={value.selectedItem.length > maxAutocompleteSelections-1}
                                    name={name}
                                    type={type}
                                    value={value.inputValue}
                                    startAdornment={value.selectedItem.map(item => (
                                        <Chip
                                            key={item.id}
                                            tabIndex={-1}
                                            label={item.name}
                                            className={UIClasses.chip}
                                            onDelete={handleDelete(item)}
                                        />
                                    ))}
                                    onBlur={onBlurInput}
                                    onChange={event => {
                                        handleInputChange(event);
                                        onChangeInput(event);
                                    }}
                                    onFocus={onFocusInput}
                                    onKeyDown={onKeyDownInput}
                                    autoComplete={'off'}
                                />
                                <div className={UIClasses.inputContainer}>
                                    {isOpen ? (
                                        <AutoCompleteSuggestions
                                            name={name}
                                            inputValue={inputValue2}
                                            highlightedIndex={highlightedIndex}
                                            selectedItem={selectedItem2}
                                            selectItem={selectItem}
                                        />
                                    ) : null}
                                </div>
                                
                                {touched && error && <FormHelperText>{error.selectedItem}</FormHelperText>}
                            </FormControl>
						</div>
						);
					}}
				</Downshift>
        );

    }

    return (
        <div>
            {componentInput}
        </div>
    );
}

export default FieldComponent;
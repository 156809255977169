import React, { Component } from "react";
import {connect} from 'react-redux';

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {withRouter} from 'react-router-dom';
import { green } from '@material-ui/core/colors';

import * as AppActions from '../../../store/ducks/app';
import * as classes from './FollowButton.module.css';

const styles = theme => ({
    root: {
        width: "100%",
        overflowX: "auto"
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

class ModalUnfollow extends Component {

    onModalFollowClose = () => {
        this.props.onUpdateShowUnfollowModal(false);
    }

    handleFollowClicked = () => {
        if(this.props.isFollowing){
            this.props.onUpdateShowUnfollowModal(true);
        }else{
            this.props.onUpdateFollow(this.props.followedId, true, this.props.history);
        }
    }

    handleUnfollow = () => {
        this.props.onUpdateFollow(this.props.followedId, false, this.props.history);
    }

    onFormClose = () => {
        this.onModalFollowClose();
    }
    
    render() {

        const DialogTitle = withStyles(theme => ({
            root: {
                margin: 0,
                padding: theme.spacing(2)
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500]
            }
        }))(props => {
            const { children, classes } = props;
            return (
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">{children}</Typography>
                </MuiDialogTitle>
            );
        });

        const DialogContent = withStyles(theme => ({
            root: {
                padding: theme.spacing(2)
            }
        }))(MuiDialogContent);

        const DialogActions = withStyles(theme => ({
            root: {
                margin: 0,
                padding: theme.spacing(1)
            }
        }))(MuiDialogActions);

        return (
            <div>
                <div className={this.props.classes.wrapper}>
                    <Button 
                        onClick={this.handleFollowClicked} 
                        disabled={this.props.unfollowSending}
                        variant="contained" 
                        style={{backgroundColor: this.props.isFollowing ? '#cccccc' : '#006666', color: this.props.isFollowing ? '#888888' : '#fff'}}
                    >
                        {this.props.isFollowing ? 'Following' : 'Follow'}
                    </Button>
                    {this.props.unfollowSending && <CircularProgress size={24} className={this.props.classes.buttonProgress} />}
                </div>
                {this.props.modalOpen && <Dialog
                    onClose={this.onModalFollowClose}
                    open={this.props.modalOpen}
                    className={this.props.classes.root}
                    fullWidth
                    classes={{
                        paper: classes.dialog
                    }}
                >
                    <DialogTitle>Unfollow</DialogTitle>
                    <DialogContent dividers>
                        <Typography>
                            {`Are you sure you want to unfollow ${this.props.unfollowedName} ?`}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <div className={this.props.classes.wrapper}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={this.props.unfollowSending}
                                onClick={this.handleUnfollow}
                            >
                                Unfollow
                            </Button>
                            {this.props.unfollowSending && <CircularProgress size={24} className={this.props.classes.buttonProgress} />}
                        </div>
                        
                        <Button
                            onClick={this.onFormClose}
                            disabled={this.props.unfollowSending}
                            color="secondary"
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>}
            </div>
            
        );
    }
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateShowUnfollowModal: (show) => dispatch(AppActions.updateShowUnfollowModal(show)),
        onUpdateFollow: (user, follow, history) => dispatch(AppActions.updateFollowBegin(user, follow, history)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModalUnfollow)));
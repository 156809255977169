import React, { Component } from 'react';
import { fade, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import BuildIcon from '@material-ui/icons/Build';
import AdminPanel from '../App/AdminPanel/AdminPanel';
import Tooltip from '@material-ui/core/Tooltip';

import {connect} from 'react-redux';
import { Route, Switch, withRouter, Link, Redirect } from 'react-router-dom';
import BrowseVideoPage from '../App/BrowseVideoPage/BrowseVideoPage';
import WatchVideoPage from '../App/WatchVideoPage/WatchVideoPage';
import UserPage from '../App/UserPage/UserPage';
import {Transition, TransitionGroup} from 'react-transition-group';
import SignInPage from '../App/SignInPage/SignInPage';
import ChoosePlanPage from '../App/SignInPage/ChoosePlanPage/ChoosePlanPage';
import WelcomPage from '../App/WelcomePage/WelcomePage';
import VideoSearchPage from '../App/VideoSearchPage/VideoSearchPage';
import VideoSearchPanel from '../App/VideoSearchPanel/VideoSearchPanel';
import SubscriptionRequiredPage from '../App/SubscriptionRequiredPage/SubscriptionRequiredPage';

import * as AppActions from '../../store/ducks/app';
import MaxMinutesPage from '../App/MaxMinutesPage/MaxMinutesPage';
import CreateAccountPage from '../App/CreateAccountPage/CreateAccountPage';
import PaymentMethodPage from '../App/PaymentMethodPage/PaymentMethodPage';
import config from '../../config/config';

import * as classes from './Layout.module.css';

const styles = theme => ({
    header: {
        position: 'fixed',
        display: 'flex',
        minHeight: '56px'
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
            display: 'block',
            marginRight: '1rem'
        // },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: '75%',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        zIndex: 1
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    inputRoot2: {
        color: 'inherit',
        width: '100%',
        height: '56px',
        display: 'flex',
        alignItems: 'center',
    },
    inputInput: {
        padding: theme.spacing(1, 7, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
});

class Layout extends Component {

    state = {
        anchorEl: null,
        mobileMoreAnchorEl: null,
        searchExpanded: false,
        searchOn: false
    }

    componentDidMount(){
        if(!this.props.stateUserLoggedInStatusLoaded){
            if(!this.props.stateUserLoggedInStatusLoading){
                this.props.onFetchUserLoggedInStatus();
            }
        }
    }

    handleProfileMenuOpen = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    }

    handleLogout = () => {
        this.handleMenuClose();
        this.props.onLogout(this.props.history)
    }

    onExit = () => {
        this.props.waitOnExitingPage(true);
        this.props.hidePage(true);
    }

    onExited = () => {
        this.props.waitOnExitingPage(false);
        window.scrollTo(0,0);
    }

    onEntering = (node, isAppearing) => {
        if(isAppearing){
            this.props.hidePage(true);
            this.props.waitOnExitingPage(false);
        }
    }

    toggleSearchExpand = () => {
        this.setState((prevState) => ({...prevState, searchExpanded: !prevState.searchExpanded}));
    }

    toggleSearchOn = () => {
        this.setState((prevState) => ({...prevState, searchOn: !prevState.searchOn}));
    }

    onFormSubmit = () => {
        this.setState({searchExpanded: false, searchOn: false});
    }

    onHomeClicked = () => {
        // if(this.props.location.pathname === "/home"){
        //     this.props.hidePage(true);
        //     this.props.onFetchBrowseVideo();
        // }
    }

    handleSearchKeyDown = (e) => {
        if(e.keyCode === 13){
            this.setState({searchExpanded: false, searchOn: false});
            this.props.history.push('/videosearch', {videoSearch: {name: e.target.value}})
         }
    } 

    render() {

        const isMenuOpen = Boolean(this.state.anchorEl);

        const renderMenu = (
            <Menu
                anchorEl={this.state.anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
            </Menu>
        );

        const pageMain = (
            <div className={!['/welcome', '/login', '/auth/google', '/afterlogin', '/signup/chooseplan', '/signup/createaccount', '/signup/addpayment'].includes(this.props.location.pathname) ? 
            classes.appBarShift : classes.appBarShiftNone}>
                <Switch location={this.props.location}>
                    <Route path="/auth/google" />
                    <Route path="/afterlogin" exact render={() => {
                        this.props.hidePage(false);
                        if (window.opener) {
                            // send them to the opening window
                            window.opener.postMessage({loginSuccess: true});
                            // close the popup
                            window.close();
                        }
                        return <p>Please wait...</p>;
                    }} />
                    <Route path="/admin" exact render={() => {
                        return (
                            <div style={{ maxWidth: '1280px', margin: 'auto' }}>
                                <AdminPanel />
                            </div>
                        )
                    }} />
                    <Route path="/home" exact render={() => {
                        return (
                            <div style={{ maxWidth: '1280px', margin: 'auto' }}>
                                <BrowseVideoPage />
                            </div>
                        )
                    }} />
                    <Route path="/videosearch" exact render={() => {
                        return (
                            <div>
                                <VideoSearchPage />
                            </div>
                        )
                    }} />
                    <Route path="/watch/:id" exact render={({match}) => {
                        return (
                            <div>
                                <WatchVideoPage id={match.params.id} videoId={match.params.id} />
                                {/* {(!this.props.stateUserActiveSub && this.props.stateUserType !== 3) && <Redirect to='/error' />} */}
                            </div>
                            
                        )
                    }} />
                    <Route path="/user/:id" exact render={({match}) => {
                        return (
                            <div>
                                <UserPage id={match.params.id} />
                            </div>
                            
                        )
                    }} />
                    <Route path="/welcome" exact render={() => {
                        return (
                            <div>
                                <WelcomPage />
                            </div>
                        )
                    }} />
                    <Route path="/signup/chooseplan" exact render={() => {
                        return (
                            <div>
                                <ChoosePlanPage />
                            </div>
                        )
                    }} />
                    <Route path="/signup/createaccount" exact render={() => {
                        return (
                            <div>
                                {/* {(this.props.stateUserLoggedInStatus || this.props.stateSignupChosenPlan === 0) && <Redirect to='/welcome' />} */}
                                <CreateAccountPage />
                            </div>
                        )
                    }} />
                    <Route path="/signup/addpayment" exact render={() => {
                        return (
                            <div>
                                <PaymentMethodPage />
                            </div>
                        )
                    }} />
                    <Route path="/login" exact render={() => {
                        return (
                            <div>
                                <SignInPage />
                            </div>
                        )
                    }} />
                    <Route path="/subscription-required" exact render={() => {
                        return (
                            <div>
                                <SubscriptionRequiredPage />
                            </div>
                        )
                    }} />
                    <Route path="/max-minutes" exact render={() => {
                        return (
                            <div>
                                <MaxMinutesPage />
                            </div>
                        )
                    }} />
                    <Route path="/" exact render={() => {
                        if(!this.props.stateUserLoggedInStatusLoaded){
                            if(!this.props.stateUserLoggedInStatusLoading){
                                this.props.onFetchUserLoggedInStatus();
                            }
                            return <div></div>;
                        }else if(this.props.stateUserLoggedInStatus){
                            return <Redirect to="/home" />;
                        }else{
                            return <Redirect to="/welcome" />;
                        }
                    }} />
                    <Redirect to="/home" />
                </Switch>
            </div>
            
        );
        const duration = 400;
        let opac = 1;
        let vis = 'visible';
        if(this.props.stateHidePage){
            opac = 0;
            vis = 'hidden';
        }

        const transitionStyles = {
            entering: { opacity: 0, visibility: 'hidden' },
            entered:  { opacity: opac, transition: `opacity ${duration}ms, visibility 0ms ${duration}ms`, visibility: vis },
            exiting:  { opacity: 0, transition: `opacity ${duration}ms, visibility 0ms ${duration}ms` },
            exited:   { opacity: 0, transition: `opacity ${duration}ms, visibility 0ms ${duration}ms` }
        };

        const fadeStyle = { opacity: `${this.props.stateHidePage ? 0 : 1}`, transition: `opacity ${duration}ms, visibility 0ms ${duration}ms`, visibility: 'visible' };

        const defaultStyle = {
            transition: `opacity ${duration}ms, visibility 0ms ${duration}ms`,
            opacity: 1,
            position: 'relative'
        }

        let locationKey = this.props.location.pathname + " " + this.props.location.key;

        let appBarElement = (
            <AppBar position="static" className={this.props.classes.header}>
                <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to={{pathname: '/home'}} onClick={this.onHomeClicked} >
                        <Typography className={this.props.classes.title} variant="h6" noWrap>
                            {config.demo ? 'Home' : 'SprkGlobal'}
                        </Typography>
                    </Link>
                    <div className={classes.search}>
                        <div className={this.props.classes.search}>
                            <div className={this.props.classes.searchIcon}>
                                <KeyboardArrowDownIcon 
                                    onClick={this.toggleSearchExpand}/>
                            </div>
                            <InputBase
                                onKeyDown={this.handleSearchKeyDown}
                                placeholder="Search…"
                                classes={{
                                    root: this.props.classes.inputRoot,
                                    input: this.props.classes.inputInput,
                                }}
                            />
                            {this.state.searchExpanded && <div className={classes.videoSearchPanel}>
                                <VideoSearchPanel 
                                    onFormSubmit={this.onFormSubmit}/>
                            </div>}
                        </div>
                    </div>
                    
                    {/* <div className={this.props.classes.grow} /> */}
                    <div style={{display: 'flex'}}>
                        <Tooltip title="Search">
                            <IconButton
                                    onClick={this.toggleSearchOn}
                                    color="inherit"
                                    className={classes.searchIcon}
                                >
                                    <SearchIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Configure">
                            <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to={{pathname: '/admin'}}>
                                <IconButton color="inherit">
                                    <Badge color="secondary">
                                        <BuildIcon />
                                    </Badge>
                                </IconButton>
                            </Link>
                        </Tooltip>
                        <IconButton
                            edge="end"
                            onClick={this.handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>


                    </div>
                </Toolbar>
            </AppBar>
        );

        if(this.state.searchOn){
            appBarElement = (
                <AppBar position="static" className={this.props.classes.header}>
                    <div className={this.props.classes.searchIcon}>
                        <KeyboardArrowDownIcon 
                            onClick={this.toggleSearchExpand}/>
                    </div>
                    <InputBase
                        onKeyDown={this.handleSearchKeyDown}
                        placeholder="Search…"
                        classes={{
                            root: this.props.classes.inputRoot2,
                            input: this.props.classes.inputInput,
                        }}
                    />
                    <IconButton
                        onClick={this.onFormSubmit}
                        color="inherit"
                        style={{position: 'absolute', top: '0', right: '0', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                    >
                        <CloseIcon />
                    </IconButton>
                    {this.state.searchExpanded && <div className={classes.videoSearchPanel}>
                        <VideoSearchPanel 
                            onFormSubmit={this.onFormSubmit}/>
                    </div>}
                </AppBar>
            );
        }
        
        return (
            <div>
                {!['/', '/welcome', '/login', '/auth/google', '/afterlogin', '/signup/chooseplan', '/signup/createaccount', '/signup/addpayment'].includes(this.props.location.pathname) && appBarElement}
                <div>
                    <div>
                        <TransitionGroup>
                            <Transition key={locationKey} timeout={duration} onExit={this.onExit} onExited={this.onExited} onEntering={this.onEntering} unmountOnExit appear>
                                {(state) => {
                                    return (
                                        <div style={{
                                            ...defaultStyle,
                                            ...transitionStyles[state]
                                            }}>
                                            <div style={{
                                                ...fadeStyle
                                                }}>
                                                {pageMain}
                                            </div>
                                        </div>
                                    )
                                }}
                            </Transition>
                        </TransitionGroup>
                    </div>
                    {renderMenu}
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        stateUserLoggedInStatusLoaded: state.app.userLoggedInStatusLoaded,
        stateUserLoggedInStatusLoading: state.app.userLoggedInStatusLoading,
        stateUserLoggedInStatus: state.app.userLoggedInStatus,
        stateSignupChosenPlan: state.app.signupChosenPlan,
        stateHidePage: state.app.hidePage,
        stateUserActiveSub: state.app.userActiveSub,
        stateUserType: state.app.userType
    }
}

const mapDispatchToProps = (dispatch) => {
	return {
        onLogout: (history) => dispatch(AppActions.logout(history)),
        onFetchUserLoggedInStatus: () => dispatch(AppActions.fetchUserLoggedInStatusBegin()),
        waitOnExitingPage: (flag) => dispatch(AppActions.waitOnExitingPage(flag)),
        hidePage: (flag) => dispatch(AppActions.hidePage(flag)),
        onFetchBrowseVideo: (history) => dispatch(AppActions.fetchBrowseVideoBegin(history)),
	}
}

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Layout)));
import React, { Component } from "react";

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import WarningIcon from '@material-ui/icons/Warning';

import * as AppActions from '../../../store/ducks/app';

class SubscriptionRequiredPage extends Component {
    state = {
        currentTab: 0
    };

    componentDidMount() {
        this.props.onDummyPageInit(this.props.history);
    }

    render() {

        return (
            <div style={{margin: '5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <WarningIcon style={{transform: 'scale(3)', marginBottom: '2rem'}}></WarningIcon>
                <Typography style={{fontSize: '18px'}}>You must be subscribed to view this page!</Typography>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onDummyPageInit: (history) => dispatch(AppActions.dummyPageInit(history)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubscriptionRequiredPage));

import { put, takeEvery, delay, take, select, fork, all, takeLatest } from "redux-saga/effects";

import axios from '../../axios.js';
import config from '../../config/config';
import { eventChannel, END } from "redux-saga";


// Action Types

export const FETCH_VIDEOLIST_DATA_BEGIN = 'FETCH_VIDEOLIST_DATA_BEGIN';
export const FETCH_VIDEOLIST_DATA_SUCCESS = 'FETCH_VIDEOLIST_DATA_SUCCESS';
export const FETCH_VIDEOLIST_DATA_FAILED = 'FETCH_VIDEOLIST_DATA_FAILED';
export const FETCH_VIDEOLIST_DATA_DISMISS_ERROR = 'FETCH_VIDEOLIST_DISMISS_ERROR';

export const FETCH_USERLIST_DATA_BEGIN = 'FETCH_USERLIST_DATA_BEGIN';
export const FETCH_USERLIST_DATA_SUCCESS = 'FETCH_USERLIST_DATA_SUCCESS';
export const FETCH_USERLIST_DATA_FAILED = 'FETCH_USERLIST_DATA_FAILED';
export const FETCH_USERLIST_DATA_DISMISS_ERROR = 'FETCH_USERLIST_DISMISS_ERROR';

export const UPLOAD_VIDEO_BEGIN = 'UPLOAD_VIDEO_BEGIN';
export const UPLOAD_VIDEO_SUCCESS = 'UPLOAD_VIDEO_SUCCESS';
export const UPLOAD_VIDEO_FAILED = 'UPLOAD_VIDEO_FAILED';
export const UPLOAD_VIDEO_DISMISS_ERROR = 'UPLOAD_VIDEO_DISMISS_ERROR';

export const FETCH_VIDEO_INFO_BEGIN = 'FETCH_VIDEO_INFO_BEGIN';
export const FETCH_VIDEO_INFO_SUCCESS = 'FETCH_VIDEO_INFO_SUCCESS';
export const FETCH_VIDEO_INFO_FAILED = 'FETCH_VIDEO_INFO_FAILED';
export const FETCH_VIDEO_INFO_DISMISS_ERROR = 'FETCH_VIDEO_INFO_DISMISS_ERROR';

export const FETCH_USER_PANEL_INFO_BEGIN = 'FETCH_USER_PANEL_INFO_BEGIN';
export const FETCH_USER_PANEL_INFO_SUCCESS = 'FETCH_USER_PANEL_INFO_SUCCESS';
export const FETCH_USER_PANEL_INFO_FAILED = 'FETCH_USER_PANEL_INFO_FAILED';
export const FETCH_USER_PANEL_INFO_DISMISS_ERROR = 'FETCH_USER_PANEL_INFO_DISMISS_ERROR';

export const EDIT_VIDEO_INFO_BEGIN = 'EDIT_VIDEO_INFO_BEGIN';
export const EDIT_VIDEO_INFO_SUCCESS = 'EDIT_VIDEO_INFO_SUCCESS';
export const EDIT_VIDEO_INFO_FAILED = 'EDIT_VIDEO_INFO_FAILED';
export const EDIT_VIDEO_INFO_DISMISS_ERROR = 'EDIT_VIDEO_INFO_DISMISS_ERROR';

export const EDIT_USER_SETTINGS_INFO_BEGIN = 'EDIT_USER_SETTINGS_INFO_BEGIN';
export const EDIT_USER_SETTINGS_INFO_SUCCESS = 'EDIT_USER_SETTINGS_INFO_SUCCESS';
export const EDIT_USER_SETTINGS_INFO_FAILED = 'EDIT_USER_SETTINGS_INFO_FAILED';
export const EDIT_USER_SETTINGS_INFO_DISMISS_ERROR = 'EDIT_USER_SETTINGS_INFO_DISMISS_ERROR';

export const EDIT_CONFIG_INFO_BEGIN = 'EDIT_CONFIG_INFO_BEGIN';
export const EDIT_CONFIG_INFO_SUCCESS = 'EDIT_CONFIG_INFO_SUCCESS';
export const EDIT_CONFIG_INFO_FAILED = 'EDIT_CONFIG_INFO_FAILED';
export const EDIT_CONFIG_INFO_DISMISS_ERROR = 'EDIT_CONFIG_INFO_DISMISS_ERROR';

export const EDIT_PAYMENT_INFO_BEGIN = 'EDIT_PAYMENT_INFO_BEGIN';
export const EDIT_PAYMENT_INFO_SUCCESS = 'EDIT_PAYMENT_INFO_SUCCESS';
export const EDIT_PAYMENT_INFO_FAILED = 'EDIT_PAYMENT_INFO_FAILED';
export const EDIT_PAYMENT_INFO_DISMISS_ERROR = 'EDIT_PAYMENT_INFO_DISMISS_ERROR';

export const EDIT_USER_INFO_BEGIN = 'EDIT_USER_INFO_BEGIN';
export const EDIT_USER_INFO_SUCCESS = 'EDIT_USER_INFO_SUCCESS';
export const EDIT_USER_INFO_FAILED = 'EDIT_USER_INFO_FAILED';
export const EDIT_USER_INFO_DISMISS_ERROR = 'EDIT_USER_INFO_DISMISS_ERROR';

export const FETCH_BROWSE_VIDEO_BEGIN = 'FETCH_BROWSE_VIDEO_BEGIN';
export const FETCH_BROWSE_VIDEO_SUCCESS = 'FETCH_BROWSE_VIDEO_SUCCESS';
export const FETCH_BROWSE_VIDEO_FAILED = 'FETCH_BROWSE_VIDEO_FAILED';
export const FETCH_BROWSE_VIDEO_DISMISS_ERROR = 'FETCH_BROWSE_VIDEO_DISMISS_ERROR';

export const FETCH_WATCH_VIDEO_BEGIN = 'FETCH_WATCH_VIDEO_BEGIN';
export const FETCH_WATCH_VIDEO_SUCCESS = 'FETCH_WATCH_VIDEO_SUCCESS';
export const FETCH_WATCH_VIDEO_FAILED = 'FETCH_WATCH_VIDEO_FAILED';
export const FETCH_WATCH_VIDEO_DISMISS_ERROR = 'FETCH_WATCH_VIDEO_DISMISS_ERROR';

export const UPDATE_VIEW_WATCH_AMOUNT_BEGIN = 'UPDATE_VIEW_WATCH_AMOUNT_BEGIN';
export const UPDATE_VIEW_WATCH_AMOUNT_SUCCESS = 'UPDATE_VIEW_WATCH_AMOUNT_SUCCESS';
export const UPDATE_VIEW_WATCH_AMOUNT_FAILED = 'UPDATE_VIEW_WATCH_AMOUNT_FAILED';
export const UPDATE_VIEW_WATCH_AMOUNT_DISMISS_ERROR = 'UPDATE_VIEW_WATCH_AMOUNT_DISMISS_ERROR';

export const FETCH_USER_INFO_BEGIN = 'FETCH_USER_INFO_BEGIN';
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_FAILED = 'FETCH_USER_INFO_FAILED';
export const FETCH_USER_INFO_DISMISS_ERROR = 'FETCH_USER_INFO_DISMISS_ERROR';

export const FETCH_MORE_USER_VIDEOS_BEGIN = 'FETCH_MORE_USER_VIDEOS_BEGIN';
export const FETCH_MORE_USER_VIDEOS_SUCCESS = 'FETCH_MORE_USER_VIDEOS_SUCCESS';
export const FETCH_MORE_USER_VIDEOS_FAILED = 'FETCH_MORE_USER_VIDEOS_FAILED';
export const FETCH_MORE_USER_VIDEOS_DISMISS_ERROR = 'FETCH_MORE_USER_VIDEOS_DISMISS_ERROR';

export const FETCH_USER_SUGGESTIONS_BEGIN = 'FETCH_USER_SUGGESTIONS_BEGIN';
export const FETCH_USER_SUGGESTIONS_SUCCESS = 'FETCH_USER_SUGGESTIONS_SUCCESS';
export const FETCH_USER_SUGGESTIONS_FAILED = 'FETCH_USER_SUGGESTIONS_FAILED';
export const FETCH_USER_SUGGESTIONS_DISMISS_ERROR = 'FETCH_USER_SUGGESTIONS_DISMISS_ERROR';

export const FETCH_VIDEO_CATEGORY_SUGGESTIONS_BEGIN = 'FETCH_VIDEO_CATEGORY_SUGGESTIONS_BEGIN';
export const FETCH_VIDEO_CATEGORY_SUGGESTIONS_SUCCESS = 'FETCH_VIDEO_CATEGORY_SUGGESTIONS_SUCCESS';
export const FETCH_VIDEO_CATEGORY_SUGGESTIONS_FAILED = 'FETCH_VIDEO_CATEGORY_SUGGESTIONS_FAILED';
export const FETCH_VIDEO_CATEGORY_SUGGESTIONS_DISMISS_ERROR = 'FETCH_VIDEO_CATEGORY_SUGGESTIONS_DISMISS_ERROR';

export const UPDATE_FOLLOW_BEGIN = 'UPDATE_FOLLOW_BEGIN';
export const UPDATE_FOLLOW_SUCCESS = 'UPDATE_FOLLOW_SUCCESS';
export const UPDATE_FOLLOW_FAILED = 'UPDATE_FOLLOW_FAILED';
export const UPDATE_FOLLOW_DISMISS_ERROR = 'UPDATE_FOLLOW_DISMISS_ERROR';

export const FETCH_USER_LOGGED_IN_STATUS_BEGIN = 'FETCH_USER_LOGGED_IN_STATUS_BEGIN';
export const FETCH_USER_LOGGED_IN_STATUS_SUCCESS = 'FETCH_USER_LOGGED_IN_STATUS_SUCCESS';
export const FETCH_USER_LOGGED_IN_STATUS_FAILED = 'FETCH_USER_LOGGED_IN_STATUS_FAILED';
export const FETCH_USER_LOGGED_IN_STATUS_DISMISS_ERROR = 'FETCH_USER_LOGGED_IN_STATUS_DISMISS_ERROR';

export const FETCH_SEARCH_VIDEOS_BEGIN = 'FETCH_SEARCH_VIDEOS_BEGIN';
export const FETCH_SEARCH_VIDEOS_SUCCESS = 'FETCH_SEARCH_VIDEOS_SUCCESS';
export const FETCH_SEARCH_VIDEOS_FAILED = 'FETCH_SEARCH_VIDEOS_FAILED';
export const FETCH_SEARCH_VIDEOS_DISMISS_ERROR = 'FETCH_SEARCH_VIDEOS_DISMISS_ERROR';

export const FETCH_MORE_SEARCH_VIDEOS_BEGIN = 'FETCH_MORE_SEARCH_VIDEOS_BEGIN';
export const FETCH_MORE_SEARCH_VIDEOS_SUCCESS = 'FETCH_MORE_SEARCH_VIDEOS_SUCCESS';
export const FETCH_MORE_SEARCH_VIDEOS_FAILED = 'FETCH_MORE_SEARCH_VIDEOS_FAILED';
export const FETCH_MORE_SEARCH_VIDEOS_DISMISS_ERROR = 'FETCH_MORE_SEARCH_VIDEOS_DISMISS_ERROR';

export const FETCH_STATISTICS_BEGIN = 'FETCH_STATISTICS_BEGIN';
export const FETCH_STATISTICS_SUCCESS = 'FETCH_STATISTICS_SUCCESS';
export const FETCH_STATISTICS_FAILED = 'FETCH_STATISTICS_FAILED';
export const FETCH_STATISTICS_DISMISS_ERROR = 'FETCH_STATISTICS_DISMISS_ERROR';

export const FETCH_USER_SETTINGS_BEGIN = 'FETCH_USER_SETTINGS_BEGIN';
export const FETCH_USER_SETTINGS_SUCCESS = 'FETCH_USER_SETTINGS_SUCCESS';
export const FETCH_USER_SETTINGS_FAILED = 'FETCH_USER_SETTINGS_FAILED';
export const FETCH_USER_SETTINGS_DISMISS_ERROR = 'FETCH_USER_SETTINGS_DISMISS_ERROR';

export const EDIT_SUBSCRIPTION_INFO_BEGIN = 'EDIT_SUBSCRIPTION_INFO_BEGIN';
export const EDIT_SUBSCRIPTION_INFO_SUCCESS = 'EDIT_SUBSCRIPTION_INFO_SUCCESS';
export const EDIT_SUBSCRIPTION_INFO_FAILED = 'EDIT_SUBSCRIPTION_INFO_FAILED';
export const EDIT_SUBSCRIPTION_INFO_DISMISS_ERROR = 'EDIT_SUBSCRIPTION_INFO_DISMISS_ERROR';

export const FETCH_SIGNUP_INFO_BEGIN = 'FETCH_SIGNUP_INFO_BEGIN';
export const FETCH_SIGNUP_INFO_SUCCESS = 'FETCH_SIGNUP_INFO_SUCCESS';
export const FETCH_SIGNUP_INFO_FAILED = 'FETCH_SIGNUP_INFO_FAILED';
export const FETCH_SIGNUP_INFO_DISMISS_ERROR = 'FETCH_SIGNUP_INFO_DISMISS_ERROR';

export const UPDATE_SHOW_UNFOLLOW_MODAL = 'UPDATE_SHOW_UNFOLLOW_MODAL';

export const UPLOAD_VIDEO_ADD = 'UPLOAD_VIDEO_ADD';
export const UPLOAD_VIDEO_REMOVE = 'UPLOAD_VIDEO_REMOVE';

export const THUMBNAIL_ADD = 'THUMBNAIL_ADD';

export const AVATAR_ADD = 'AVATAR_ADD';

export const EDIT_CHOSEN_PLAN = 'EDIT_CHOSEN_PLAN';

export const UPDATE_VIDEO_PROGRESS = 'UPDATE_VIDEO_PROGRESS';

export const WAIT_ON_EXITING_PAGE = 'WAIT_ON_EXITING_PAGE';
export const HIDE_PAGE = 'HIDE_PAGE'; 

export const LOGOUT = 'LOGOUT'; 
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'; 

export const DUMMY_PAGE_INIT = 'DUMMY_PAGE_INIT'; 

// Actions

export const fetchVideolistDataBegin = (searchString, currentPage, rowsPerPage, history) => ({ type: FETCH_VIDEOLIST_DATA_BEGIN, searchString, currentPage, rowsPerPage, history });
export const fetchVideolistDataSuccess = (result) => ({ type: FETCH_VIDEOLIST_DATA_SUCCESS, result });
export const fetchVideolistDataFailed = (error) => ({ type: FETCH_VIDEOLIST_DATA_FAILED, error });
export const fetchVideolistDataDismissError = () => ({ type: FETCH_VIDEOLIST_DATA_DISMISS_ERROR });

export const fetchUserlistDataBegin = (searchString, currentPage, rowsPerPage, history) => ({ type: FETCH_USERLIST_DATA_BEGIN, searchString, currentPage, rowsPerPage, history });
export const fetchUserlistDataSuccess = (result) => ({ type: FETCH_USERLIST_DATA_SUCCESS, result });
export const fetchUserlistDataFailed = (error) => ({ type: FETCH_USERLIST_DATA_FAILED, error });
export const fetchUserlistDataDismissError = () => ({ type: FETCH_USERLIST_DATA_DISMISS_ERROR });

export const uploadVideoBegin = (video, history) => ({ type: UPLOAD_VIDEO_BEGIN, video, history });
export const uploadVideoSuccess = (video) => ({ type: UPLOAD_VIDEO_SUCCESS, video });
export const uploadVideoFailed = (video, error) => ({ type: UPLOAD_VIDEO_FAILED, video, error });
export const uploadVideoDismissError = (video) => ({ type: UPLOAD_VIDEO_DISMISS_ERROR, video });

export const fetchSignupInfoBegin = () => ({ type: FETCH_SIGNUP_INFO_BEGIN });
export const fetchSignupInfoSuccess = (result) => ({ type: FETCH_SIGNUP_INFO_SUCCESS, result });
export const fetchSignupInfoFailed = (error) => ({ type: FETCH_SIGNUP_INFO_FAILED, error });
export const fetchSignupInfoDismissError = () => ({ type: FETCH_SIGNUP_INFO_DISMISS_ERROR });

export const fetchVideoInfoBegin = (id, history) => ({ type: FETCH_VIDEO_INFO_BEGIN, id, history });
export const fetchVideoInfoSuccess = (result) => ({ type: FETCH_VIDEO_INFO_SUCCESS, result });
export const fetchVideoInfoFailed = (error) => ({ type: FETCH_VIDEO_INFO_FAILED, error });
export const fetchVideoInfoDismissError = () => ({ type: FETCH_VIDEO_INFO_DISMISS_ERROR });

export const fetchUserPanelInfoBegin = (id, history) => ({ type: FETCH_USER_PANEL_INFO_BEGIN, id, history });
export const fetchUserPanelInfoSuccess = (result) => ({ type: FETCH_USER_PANEL_INFO_SUCCESS, result });
export const fetchUserPanelInfoFailed = (error) => ({ type: FETCH_USER_PANEL_INFO_FAILED, error });
export const fetchUserPanelInfoDismissError = () => ({ type: FETCH_USER_PANEL_INFO_DISMISS_ERROR });

export const editVideoInfoBegin = (id, name, description, category, owner, visibility, history) => ({ type: EDIT_VIDEO_INFO_BEGIN, id, name, description, category, owner, visibility, history });
export const editVideoInfoSuccess = (id, name, description) => ({ type: EDIT_VIDEO_INFO_SUCCESS, id, name, description });
export const editVideoInfoFailed = (error) => ({ type: EDIT_VIDEO_INFO_FAILED, error });
export const editVideoInfoDismissError = () => ({ type: EDIT_VIDEO_INFO_DISMISS_ERROR });

export const editUserSettingsInfoBegin = (username, history) => ({ type: EDIT_USER_SETTINGS_INFO_BEGIN, username, history });
export const editUserSettingsInfoSuccess = (username) => ({ type: EDIT_USER_SETTINGS_INFO_SUCCESS, username });
export const editUserSettingsInfoFailed = (error) => ({ type: EDIT_USER_SETTINGS_INFO_FAILED, error });
export const editUserSettingsInfoDismissError = () => ({ type: EDIT_USER_SETTINGS_INFO_DISMISS_ERROR });

export const editConfigInfoBegin = (publickey, privatekey, cost_basic, cost, history) => ({ type: EDIT_CONFIG_INFO_BEGIN, publickey, privatekey, cost_basic, cost, history });
export const editConfigInfoSuccess = (publickey, privatekey, cost_basic, cost) => ({ type: EDIT_CONFIG_INFO_SUCCESS, publickey, privatekey, cost_basic, cost });
export const editConfigInfoFailed = (error) => ({ type: EDIT_CONFIG_INFO_FAILED, error });
export const editConfigInfoDismissError = () => ({ type: EDIT_CONFIG_INFO_DISMISS_ERROR });

export const editPaymentInfoBegin = (paymentmethod, history, choosePlan) => ({ type: EDIT_PAYMENT_INFO_BEGIN, paymentmethod, history, choosePlan });
export const editPaymentInfoSuccess = (paymentmethod) => ({ type: EDIT_PAYMENT_INFO_SUCCESS, paymentmethod });
export const editPaymentInfoFailed = (error) => ({ type: EDIT_PAYMENT_INFO_FAILED, error });
export const editPaymentInfoDismissError = () => ({ type: EDIT_PAYMENT_INFO_DISMISS_ERROR });

export const editSubscriptionInfoBegin = (planType, history) => ({ type: EDIT_SUBSCRIPTION_INFO_BEGIN, planType, history });
export const editSubscriptionInfoSuccess = (subscriptionStatus, subscriptionEndDate, userActiveSub, userSubType) => ({ type: EDIT_SUBSCRIPTION_INFO_SUCCESS, subscriptionStatus, subscriptionEndDate, userActiveSub, userSubType });
export const editSubscriptionInfoFailed = (error) => ({ type: EDIT_SUBSCRIPTION_INFO_FAILED, error });
export const editSubscriptionInfoDismissError = () => ({ type: EDIT_SUBSCRIPTION_INFO_DISMISS_ERROR });

export const editUserInfoBegin = (id, userType, history) => ({ type: EDIT_USER_INFO_BEGIN, id, userType, history });
export const editUserInfoSuccess = (id, userType) => ({ type: EDIT_USER_INFO_SUCCESS, id, userType });
export const editUserInfoFailed = (error) => ({ type: EDIT_USER_INFO_FAILED, error });
export const editUserInfoDismissError = () => ({ type: EDIT_USER_INFO_DISMISS_ERROR });

export const fetchBrowseVideoBegin = (history) => ({ type: FETCH_BROWSE_VIDEO_BEGIN, history });
export const fetchBrowseVideoSuccess = (result) => ({ type: FETCH_BROWSE_VIDEO_SUCCESS, result });
export const fetchBrowseVideoFailed = (error) => ({ type: FETCH_BROWSE_VIDEO_FAILED, error });
export const fetchBrowseVideoDismissError = () => ({ type: FETCH_BROWSE_VIDEO_DISMISS_ERROR });

export const fetchWatchVideoBegin = (id, history) => ({ type: FETCH_WATCH_VIDEO_BEGIN, id, history });
export const fetchWatchVideoSuccess = (result) => ({ type: FETCH_WATCH_VIDEO_SUCCESS, result });
export const fetchWatchVideoFailed = (error) => ({ type: FETCH_WATCH_VIDEO_FAILED, error });
export const fetchWatchVideoDismissError = () => ({ type: FETCH_WATCH_VIDEO_DISMISS_ERROR });

export const updateViewWatchAmountBegin = (id, amount, history) => ({ type: UPDATE_VIEW_WATCH_AMOUNT_BEGIN, id, amount, history });
export const updateViewWatchAmountSuccess = () => ({ type: UPDATE_VIEW_WATCH_AMOUNT_SUCCESS });
export const updateViewWatchAmountFailed = (error) => ({ type: UPDATE_VIEW_WATCH_AMOUNT_FAILED, error });
export const updateViewWatchAmountDismissError = () => ({ type: UPDATE_VIEW_WATCH_AMOUNT_DISMISS_ERROR });

export const fetchUserInfoBegin = (user, top, skip, history) => ({ type: FETCH_USER_INFO_BEGIN, user, top, skip, history });
export const fetchUserInfoSuccess = (result) => ({ type: FETCH_USER_INFO_SUCCESS, result });
export const fetchUserInfoFailed = (error) => ({ type: FETCH_USER_INFO_FAILED, error });
export const fetchUserInfoDismissError = () => ({ type: FETCH_USER_INFO_DISMISS_ERROR });

export const fetchMoreUserVideosBegin = (user, top, skip, history) => ({ type: FETCH_MORE_USER_VIDEOS_BEGIN, user, top, skip, history });
export const fetchMoreUserVideosSuccess = (result) => ({ type: FETCH_MORE_USER_VIDEOS_SUCCESS,result });
export const fetchMoreUserVideosFailed = (error) => ({ type: FETCH_MORE_USER_VIDEOS_FAILED, error });
export const fetchMoreUserVideosDismissError = () => ({ type: FETCH_MORE_USER_VIDEOS_DISMISS_ERROR });

export const fetchUserSuggestionsBegin = (string, top, history) => ({ type: FETCH_USER_SUGGESTIONS_BEGIN, string, top, history });
export const fetchUserSuggestionsSuccess = (result) => ({ type: FETCH_USER_SUGGESTIONS_SUCCESS,result });
export const fetchUserSuggestionsFailed = (error) => ({ type: FETCH_USER_SUGGESTIONS_FAILED, error });
export const fetchUserSuggestionsDismissError = () => ({ type: FETCH_USER_SUGGESTIONS_DISMISS_ERROR });

export const fetchVideoCategorySuggestionsBegin = (history) => ({ type: FETCH_VIDEO_CATEGORY_SUGGESTIONS_BEGIN, history });
export const fetchVideoCategorySuggestionsSuccess = (result) => ({ type: FETCH_VIDEO_CATEGORY_SUGGESTIONS_SUCCESS,result });
export const fetchVideoCategorySuggestionsFailed = (error) => ({ type: FETCH_VIDEO_CATEGORY_SUGGESTIONS_FAILED, error });
export const fetchVideoCategorySuggestionsDismissError = () => ({ type: FETCH_VIDEO_CATEGORY_SUGGESTIONS_DISMISS_ERROR });

export const updateFollowBegin = (user, follow, history) => ({ type: UPDATE_FOLLOW_BEGIN, user, follow, history });
export const updateFollowSuccess = (follow) => ({ type: UPDATE_FOLLOW_SUCCESS, follow });
export const updateFollowFailed = (error) => ({ type: UPDATE_FOLLOW_FAILED, error });
export const updateFollowDismissError = () => ({ type: UPDATE_FOLLOW_DISMISS_ERROR });

export const fetchUserLoggedInStatusBegin = (history, isCreatingAccount, isSigningIn) => ({ type: FETCH_USER_LOGGED_IN_STATUS_BEGIN, history, isCreatingAccount, isSigningIn });
export const fetchUserLoggedInStatusSuccess = (result) => ({ type: FETCH_USER_LOGGED_IN_STATUS_SUCCESS, result });
export const fetchUserLoggedInStatusFailed = (error) => ({ type: FETCH_USER_LOGGED_IN_STATUS_FAILED, error });
export const fetchUserLoggedInStatusDismissError = () => ({ type: FETCH_USER_LOGGED_IN_STATUS_DISMISS_ERROR });

export const fetchSearchVideosBegin = (name, category, owner, date, top, skip, history) => ({ type: FETCH_SEARCH_VIDEOS_BEGIN, name, category, owner, date, top, skip, history });
export const fetchSearchVideosSuccess = (result) => ({ type: FETCH_SEARCH_VIDEOS_SUCCESS, result });
export const fetchSearchVideosFailed = (error) => ({ type: FETCH_SEARCH_VIDEOS_FAILED, error });
export const fetchSearchVideosDismissError = () => ({ type: FETCH_SEARCH_VIDEOS_DISMISS_ERROR });

export const fetchMoreSearchVideosBegin = (name, category, owner, date, top, skip, history) => ({ type: FETCH_MORE_SEARCH_VIDEOS_BEGIN, name, category, owner, date, top, skip, history });
export const fetchMoreSearchVideosSuccess = (result) => ({ type: FETCH_MORE_SEARCH_VIDEOS_SUCCESS, result });
export const fetchMoreSearchVideosFailed = (error) => ({ type: FETCH_MORE_SEARCH_VIDEOS_FAILED, error });
export const fetchMoreSearchVideosDismissError = () => ({ type: FETCH_MORE_SEARCH_VIDEOS_DISMISS_ERROR });

export const fetchStatisticsBegin = (history) => ({ type: FETCH_STATISTICS_BEGIN, history });
export const fetchStatisticsSuccess = (result) => ({ type: FETCH_STATISTICS_SUCCESS, result });
export const fetchStatisticsFailed = (error) => ({ type: FETCH_STATISTICS_FAILED, error });
export const fetchStatisticsDismissError = () => ({ type: FETCH_STATISTICS_DISMISS_ERROR });

export const fetchUserSettingsBegin = (history) => ({ type: FETCH_USER_SETTINGS_BEGIN, history });
export const fetchUserSettingsSuccess = (result) => ({ type: FETCH_USER_SETTINGS_SUCCESS, result });
export const fetchUserSettingsFailed = (error) => ({ type: FETCH_USER_SETTINGS_FAILED, error });
export const fetchUserSettingsDismissError = () => ({ type: FETCH_USER_SETTINGS_DISMISS_ERROR });

export const updateShowUnfollowModal = (show) => ({ type: UPDATE_SHOW_UNFOLLOW_MODAL, show });

export const uploadVideoAdd = (files, history) => ({ type: UPLOAD_VIDEO_ADD, files, history });
export const uploadVideoRemove = (keys, history) => ({ type: UPLOAD_VIDEO_REMOVE, keys, history });

export const thumbnailAdd = (file, history) => ({ type: THUMBNAIL_ADD, file, history });

export const avatarAdd = (file, history) => ({ type: AVATAR_ADD, file, history });

export const editChosenPlan = (plan) => ({ type: EDIT_CHOSEN_PLAN, plan });

export const updateVideoProgress = (videoId, progress, uploadVideoList, history) => ({ type: UPDATE_VIDEO_PROGRESS, videoId, progress, uploadVideoList, history }); // uploadVideoList indicates updating uploadVideoList or videoList

export const waitOnExitingPage = (flag) => ({ type: WAIT_ON_EXITING_PAGE, flag });
export const hidePage = (flag) => ({ type: HIDE_PAGE, flag });

export const dummyPageInit = (history) => ({ type: DUMMY_PAGE_INIT, history });

export const logout = (history) => ({ type: LOGOUT, history });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });

// Selectors

// Reducer

// uploadVideoList:
//
// {
//     key
//     file
//     uploadProgress
//     uploading
//     uploaded 
//     errors: []
// }

const initialState = {

    signupApiKey: null,
    signupChosenPlan: 0, // 0 = no plan chosen, 1 = basic, 2 = unlimited
    signupBasicCost: '4.99',
    signupUnlimitedCost: '9.99',
    signupInfoLoading: false,
    signupInfoLoaded: false,
    signupInfoErrors: [],

    userHasPaymentMethod: false,
    userLoggedInStatus: false,
    userType: 1,
    userActiveSub: false,
    userSubType: 0,
    userLoggedInStatusLoading: false,
    userLoggedInStatusLoaded: false,
    userLoggedInStatusErrors: [],

    waitOnExitingPage: false,
    hidePage: false,

    uploadVideoList: [],

    userList: [],
    userListCount: 0,
    userListLoading: false,
    userListLoaded: false,
    userListErrors: [],

    videoList: [],
    videoListCount: 0,
    videoListLoading: false,
    videoListLoaded: false,
    videoListErrors: [],

    userPanelInfo: {},
    userPanelInfoLoading: false,
    userPanelInfoLoaded: false,
    userPanelInfoErrors: [],

    editUserInfoSending: false,
    editUserInfoSent: false,
    editUserInfoErrors: [],

    editUserSettingsInfoSending: false,
    editUserSettingsInfoSent: false,
    editUserSettingsInfoErrors: [],

    editConfigInfoSending: false,
    editConfigInfoSent: false,
    editConfigInfoErrors: [],

    editPaymentInfoSending: false,
    editPaymentInfoSent: false,
    editPaymentInfoErrors: [],

    editSubscriptionInfoSending: false,
    editSubscriptionInfoSent: false,
    editSubscriptionInfoErrors: [],

    accountInfo: {},
    accountInfoLoading: false,
    accountInfoLoaded: false,
    accountInfoErrors: [],

    videoInfo: {},
    videoInfoLoading: false,
    videoInfoLoaded: false,
    videoInfoErrors: [],

    videoThumbnail: null,

    userAvatar: null,

    editVideoInfoSending: false,
    editVideoInfoSent: false,
    editVideoInfoErrors: [],

    showUnfollowModal: false,
    updateFollowSending: false,
    updateFollowSent: false,
    updateFollowErrors: [],

    updateViewWatchAmountSending: false,
    updateViewWatchAmountSent: false,
    updateViewWatchAmountErrors: [],

    browseVideo: {},
    browseVideoLoading: false,
    browseVideoLoaded: false,
    browseVideoErrors: [],

    watchVideo: {},
    watchVideoLoading: false,
    watchVideoLoaded: false,
    watchVideoErrors: [],

    userInfo: {},
    userInfoLoading: false,
    userInfoLoaded: false,
    userInfoErrors: [],

    fetchMoreUserVideosLoading: false,
    fetchMoreUserVideosLoaded: false,
    fetchMoreUserVideosErrors: [],

    userSuggestions: [],
    userSuggestionsLoading: false,
    userSuggestionsLoaded: false,
    userSuggestionsErrors: [],

    videoCategorySuggestions: [],
    videoCategorySuggestionsLoading: false,
    videoCategorySuggestionsLoaded: false,
    videoCategorySuggestionsErrors: [],

    searchVideos: [],
    searchVideosLoading: false,
    searchVideosLoaded: false,
    searchVideosErrors: [],

    moreSearchVideosLoading: false,
    moreSearchVideosLoaded: false,
    moreSearchVideosErrors: [],

    statistics: {},
    statisticsLoading: false,
    statisticsLoaded: false,
    statisticsErrors: [],
};

const reducer = (state = initialState, action) => {

    let updatedState;
    let newVideoList;
    let newUserList;
    let newUploadVideoList;
    let newUserVideosList;
    let newSearchVideosList;
    let oldVideos;

    switch (action.type) {
        case LOGOUT_SUCCESS:
            updatedState = {
                ...state,
                ...initialState,
                userLoggedInStatus: false
            }
            return updatedState;
        case EDIT_CHOSEN_PLAN:
            updatedState = {
                ...state,
                signupChosenPlan: action.plan
            }
            return updatedState;
        case WAIT_ON_EXITING_PAGE:
            updatedState = {
                ...state,
                waitOnExitingPage: action.flag,
            }
            return updatedState;
        case HIDE_PAGE:
            updatedState = {
                ...state,
                hidePage: action.flag,
            }
            return updatedState;
        case FETCH_USER_LOGGED_IN_STATUS_BEGIN:
            updatedState = {
                ...state,
                userLoggedInStatusLoading: true,
                userLoggedInStatusErrors: []
            }
            return updatedState;
        case FETCH_USER_LOGGED_IN_STATUS_SUCCESS:
            updatedState = {
                ...state,
                ...action.result,
                userLoggedInStatusLoading: false,
                userLoggedInStatusLoaded: true,
                userLoggedInStatusErrors: []
            }
            return updatedState;
        case FETCH_USER_LOGGED_IN_STATUS_FAILED:
            updatedState = {
                ...state,
                userLoggedInStatusLoading: false,
                userLoggedInStatusErrors: [...state.userLoggedInStatusErrors, action.error]
            }
            return updatedState;
        case FETCH_USER_LOGGED_IN_STATUS_DISMISS_ERROR:
            updatedState = {
                ...state,
                userLoggedInStatusErrors: []
            }
            return updatedState;
        case FETCH_SIGNUP_INFO_BEGIN:
            updatedState = {
                ...state,
                signupInfoLoading: true,
                signupInfoErrors: []
            }
            return updatedState;
        case FETCH_SIGNUP_INFO_SUCCESS:
            updatedState = {
                ...state,
                ...action.result,
                signupInfoLoading: false,
                signupInfoLoaded: true,
                signupInfoErrors: []
            }
            return updatedState;
        case FETCH_SIGNUP_INFO_FAILED:
            updatedState = {
                ...state,
                signupInfoLoading: false,
                signupInfoErrors: [...state.signupInfoErrors, action.error]
            }
            return updatedState;
        case FETCH_SIGNUP_INFO_DISMISS_ERROR:
            updatedState = {
                ...state,
                signupInfoErrors: []
            }
            return updatedState;
        case FETCH_STATISTICS_BEGIN:
            updatedState = {
                ...state,
                statisticsLoading: true,
                statisticsErrors: []
            }
            return updatedState;
        case FETCH_STATISTICS_SUCCESS:
            updatedState = {
                ...state,
                ...action.result,
                statisticsLoading: false,
                statisticsLoaded: true,
                statisticsErrors: []
            }
            return updatedState;
        case FETCH_STATISTICS_FAILED:
            updatedState = {
                ...state,
                statisticsLoading: false,
                statisticsErrors: [...state.statisticsErrors, action.error]
            }
            return updatedState;
        case FETCH_STATISTICS_DISMISS_ERROR:
            updatedState = {
                ...state,
                statisticsErrors: []
            }
            return updatedState;
        case FETCH_USER_SETTINGS_BEGIN:
            updatedState = {
                ...state,
                accountInfoLoading: true,
                accountInfoErrors: []
            }
            return updatedState;
        case FETCH_USER_SETTINGS_SUCCESS:
            updatedState = {
                ...state,
                ...action.result,
                accountInfoLoading: false,
                accountInfoLoaded: true,
                accountInfoErrors: []
            }
            return updatedState;
        case FETCH_USER_SETTINGS_FAILED:
            updatedState = {
                ...state,
                accountInfoLoading: false,
                accountInfoErrors: [...state.accountInfoErrors, action.error]
            }
            return updatedState;
        case FETCH_USER_SETTINGS_DISMISS_ERROR:
            updatedState = {
                ...state,
                accountInfoErrors: []
            }
            return updatedState;
        case FETCH_USER_SUGGESTIONS_BEGIN:
            updatedState = {
                ...state,
                userSuggestionsLoading: true,
                userSuggestionsErrors: []
            }
            return updatedState;
        case FETCH_USER_SUGGESTIONS_SUCCESS:
            updatedState = {
                ...state,
                ...action.result,
                userSuggestionsLoading: false,
                userSuggestionsLoaded: true,
                userSuggestionsErrors: []
            }
            return updatedState;
        case FETCH_USER_SUGGESTIONS_FAILED:
            updatedState = {
                ...state,
                userSuggestionsLoading: false,
                userSuggestionsErrors: [...state.userSuggestionsErrors, action.error]
            }
            return updatedState;
        case FETCH_USER_SUGGESTIONS_DISMISS_ERROR:
            updatedState = {
                ...state,
                userSuggestionsErrors: []
            }
            return updatedState;
        case FETCH_VIDEO_CATEGORY_SUGGESTIONS_BEGIN:
            updatedState = {
                ...state,
                videoCategorySuggestionsLoading: true,
                videoCategorySuggestionsErrors: []
            }
            return updatedState;
        case FETCH_VIDEO_CATEGORY_SUGGESTIONS_SUCCESS:
            updatedState = {
                ...state,
                ...action.result,
                videoCategorySuggestionsLoading: false,
                videoCategorySuggestionsLoaded: true,
                videoCategorySuggestionsErrors: []
            }
            return updatedState;
        case FETCH_VIDEO_CATEGORY_SUGGESTIONS_FAILED:
            updatedState = {
                ...state,
                videoCategorySuggestionsLoading: false,
                videoCategorySuggestionsErrors: [...state.videoCategorySuggestionsErrors, action.error]
            }
            return updatedState;
        case FETCH_VIDEO_CATEGORY_SUGGESTIONS_DISMISS_ERROR:
            updatedState = {
                ...state,
                videoCategorySuggestionsErrors: []
            }
            return updatedState;
        case FETCH_USER_PANEL_INFO_BEGIN:
            updatedState = {
                ...state,
                userPanelInfoLoading: true,
                userPanelInfoErrors: []
            }
            return updatedState;
        case FETCH_USER_PANEL_INFO_SUCCESS:
            updatedState = {
                ...state,
                ...action.result,
                userPanelInfoLoading: false,
                userPanelInfoLoaded: true,
                userPanelInfoErrors: []
            }
            return updatedState;
        case FETCH_USER_PANEL_INFO_FAILED:
            updatedState = {
                ...state,
                userPanelInfoLoading: false,
                userPanelInfoErrors: [...state.userPanelInfoErrors, action.error]
            }
            return updatedState;
        case FETCH_USER_PANEL_INFO_DISMISS_ERROR:
            updatedState = {
                ...state,
                userPanelInfoErrors: []
            }
            return updatedState;
        case FETCH_VIDEO_INFO_BEGIN:
            updatedState = {
                ...state,
                videoInfoLoading: true,
                videoInfoErrors: []
            }
            return updatedState;
        case FETCH_VIDEO_INFO_SUCCESS:
            updatedState = {
                ...state,
                ...action.result,
                videoInfoLoading: false,
                videoInfoLoaded: true,
                videoInfoErrors: []
            }
            return updatedState;
        case FETCH_VIDEO_INFO_FAILED:
            updatedState = {
                ...state,
                videoInfoLoading: false,
                videoInfoErrors: [...state.videoInfoErrors, action.error]
            }
            return updatedState;
        case FETCH_VIDEO_INFO_DISMISS_ERROR:
            updatedState = {
                ...state,
                videoInfoErrors: []
            }
            return updatedState;
        case EDIT_VIDEO_INFO_BEGIN:
            updatedState = {
                ...state,
                editVideoInfoSending: true,
                editVideoInfoErrors: []
            }
            return updatedState;
        case EDIT_VIDEO_INFO_SUCCESS:

            newVideoList = state.videoList.map(elt => {
                if(elt.id === action.id){
                    return {
                        ...elt,
                        name: action.name
                    };
                }
                return elt;
            });

            updatedState = {
                ...state,
                videoList: newVideoList,
                videoInfo: {
                    id: action.id,
                    name: action.name,
                    description: action.description
                },
                editVideoInfoSending: false,
                editVideoInfoSent: true,
                editVideoInfoErrors: []
            }
            return updatedState;
        case EDIT_VIDEO_INFO_FAILED:
            updatedState = {
                ...state,
                editVideoInfoSending: false,
                editVideoInfoErrors: [...state.editVideoInfoErrors, action.error]
            }
            return updatedState;
        case EDIT_VIDEO_INFO_DISMISS_ERROR:
            updatedState = {
                ...state,
                editVideoInfoErrors: []
            }
            return updatedState;
        case EDIT_PAYMENT_INFO_BEGIN:
            updatedState = {
                ...state,
                editPaymentInfoSending: true,
                editPaymentInfoErrors: []
            }
            return updatedState;
        case EDIT_PAYMENT_INFO_SUCCESS:

            updatedState = {
                ...state,
                accountInfo: {
                    ...state.accountInfo,
                    last4: action.paymentmethod.card.last4
                },
                editPaymentInfoSending: false,
                editPaymentInfoSent: true,
                editPaymentInfoErrors: []
            }
            return updatedState;
        case EDIT_PAYMENT_INFO_FAILED:
            updatedState = {
                ...state,
                editPaymentInfoSending: false,
                editPaymentInfoErrors: [...state.editPaymentInfoErrors, action.error]
            }
            return updatedState;
        case EDIT_PAYMENT_INFO_DISMISS_ERROR:
            updatedState = {
                ...state,
                editPaymentInfoErrors: []
            }
            return updatedState;
        case EDIT_USER_SETTINGS_INFO_BEGIN:
            updatedState = {
                ...state,
                editUserSettingsInfoSending: true,
                editUserSettingsInfoErrors: []
            }
            return updatedState;
        case EDIT_USER_SETTINGS_INFO_SUCCESS:
            updatedState = {
                ...state,
                accountInfo: {
                    ...state.accountInfo,
                    userName: action.username
                },
                editUserSettingsInfoSending: false,
                editUserSettingsInfoSent: true,
                editUserSettingsInfoErrors: []
            }
            return updatedState;
        case EDIT_USER_SETTINGS_INFO_FAILED:
            updatedState = {
                ...state,
                editUserSettingsInfoSending: false,
                editUserSettingsInfoErrors: [...state.editUserSettingsInfoErrors, action.error]
            }
            return updatedState;
        case EDIT_USER_SETTINGS_INFO_DISMISS_ERROR:
            updatedState = {
                ...state,
                editUserSettingsInfoErrors: []
            }
            return updatedState;
        case EDIT_CONFIG_INFO_BEGIN:
            updatedState = {
                ...state,
                editConfigInfoSending: true,
                editConfigInfoErrors: []
            }
            return updatedState;
        case EDIT_CONFIG_INFO_SUCCESS:
            updatedState = {
                ...state,
                accountInfo: {
                    ...state.accountInfo,
                    apiKey: action.publickey,
                    apiKeyPrivate: action.privatekey,
                    planAmount: action.cost,
                    planAmountBasic: action.cost_basic
                },
                editConfigInfoSending: false,
                editConfigInfoSent: true,
                editConfigInfoErrors: []
            }
            return updatedState;
        case EDIT_CONFIG_INFO_FAILED:
            updatedState = {
                ...state,
                editConfigInfoSending: false,
                editConfigInfoErrors: [...state.editConfigInfoErrors, action.error]
            }
            return updatedState;
        case EDIT_CONFIG_INFO_DISMISS_ERROR:
            updatedState = {
                ...state,
                editConfigInfoErrors: []
            }
            return updatedState;
        case EDIT_SUBSCRIPTION_INFO_BEGIN:
            updatedState = {
                ...state,
                editSubscriptionInfoSending: true,
                editSubscriptionInfoErrors: []
            }
            return updatedState;
        case EDIT_SUBSCRIPTION_INFO_SUCCESS:

            updatedState = {
                ...state,
                accountInfo: {
                    ...state.accountInfo,
                    subscriptionStatus: action.subscriptionStatus,
                    subscriptionEndDate: action.subscriptionEndDate,
                    planType: action.userSubType
                },
                userSubType: action.userSubType,
                userActiveSub: action.userActiveSub,
                editSubscriptionInfoSending: false,
                editSubscriptionInfoSent: true,
                editSubscriptionInfoErrors: []
            }
            return updatedState;
        case EDIT_SUBSCRIPTION_INFO_FAILED:
            updatedState = {
                ...state,
                editSubscriptionInfoSending: false,
                editSubscriptionInfoErrors: [...state.editSubscriptionInfoErrors, action.error]
            }
            return updatedState;
        case EDIT_SUBSCRIPTION_INFO_DISMISS_ERROR:
            updatedState = {
                ...state,
                editSubscriptionInfoErrors: []
            }
            return updatedState;
        case EDIT_USER_INFO_BEGIN:
            updatedState = {
                ...state,
                editUserInfoSending: true,
                editUserInfoErrors: []
            }
            return updatedState;
        case EDIT_USER_INFO_SUCCESS:

            newUserList = state.userList.map(elt => {
                if(elt.id === action.id){
                    return {
                        ...elt,
                        type: action.userType
                    };
                }
                return elt;
            });

            updatedState = {
                ...state,
                userList: newUserList,
                userInfo: {
                    id: action.id,
                    type: action.userType
                },
                editUserInfoSending: false,
                editUserInfoSent: true,
                editUserInfoErrors: []
            }
            return updatedState;
        case EDIT_USER_INFO_FAILED:
            updatedState = {
                ...state,
                editUserInfoSending: false,
                editUserInfoErrors: [...state.editUserInfoErrors, action.error]
            }
            return updatedState;
        case EDIT_USER_INFO_DISMISS_ERROR:
            updatedState = {
                ...state,
                editUserInfoErrors: []
            }
            return updatedState;
        case UPDATE_FOLLOW_BEGIN:
            updatedState = {
                ...state,
                updateFollowSending: true,
                updateFollowErrors: []
            }
            return updatedState;
        case UPDATE_FOLLOW_SUCCESS:
            updatedState = {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    isFollowing: action.follow,
                    followerCount: (state.userInfo.followerCount || 0) + (action.follow ? 1 : -1)
                },
                watchVideo: {
                    ...state.watchVideo,
                    isFollowing: action.follow
                },
                showUnfollowModal: false,
                updateFollowSending: false,
                updateFollowSent: true,
                updateFollowErrors: []
            }
            return updatedState;
        case UPDATE_FOLLOW_FAILED:
            updatedState = {
                ...state,
                updateFollowSending: false,
                updateFollowErrors: [...state.updateFollowErrors, action.error]
            }
            return updatedState;
        case UPDATE_FOLLOW_DISMISS_ERROR:
            updatedState = {
                ...state,
                updateFollowErrors: []
            }
            return updatedState;
        case UPDATE_VIEW_WATCH_AMOUNT_BEGIN:
            updatedState = {
                ...state,
                updateViewWatchAmountSending: true,
                updateViewWatchAmountErrors: []
            }
            return updatedState;
        case UPDATE_VIEW_WATCH_AMOUNT_SUCCESS:
            updatedState = {
                ...state,
                updateViewWatchAmountSending: false,
                updateViewWatchAmountSent: true,
                updateViewWatchAmountErrors: []
            }
            return updatedState;
        case UPDATE_VIEW_WATCH_AMOUNT_FAILED:
            updatedState = {
                ...state,
                updateViewWatchAmountSending: false,
                updateViewWatchAmountErrors: [...state.updateViewWatchAmountErrors, action.error]
            }
            return updatedState;
        case UPDATE_VIEW_WATCH_AMOUNT_DISMISS_ERROR:
            updatedState = {
                ...state,
                updateViewWatchAmountErrors: []
            }
            return updatedState;
        case FETCH_VIDEOLIST_DATA_BEGIN:
            updatedState = {
                ...state,
                videoListLoading: true,
                videoListErrors: []
            }
            return updatedState;
        case FETCH_VIDEOLIST_DATA_SUCCESS:
            updatedState = {
                ...state,
                ...action.result,
                videoListLoading: false,
                videoListLoaded: true,
                videoListErrors: []
            }
            return updatedState;
        case FETCH_VIDEOLIST_DATA_FAILED:
            updatedState = {
                ...state,
                videoListLoading: false,
                videoListErrors: [...state.videoListErrors, action.error]
            }
            return updatedState;
        case FETCH_VIDEOLIST_DATA_DISMISS_ERROR:
            updatedState = {
                ...state,
                videoListErrors: []
            }
            return updatedState;
        case FETCH_USERLIST_DATA_BEGIN:
            updatedState = {
                ...state,
                userListLoading: true,
                userListErrors: []
            }
            return updatedState;
        case FETCH_USERLIST_DATA_SUCCESS:
            updatedState = {
                ...state,
                ...action.result,
                userListLoading: false,
                userListLoaded: true,
                userListErrors: []
            }
            return updatedState;
        case FETCH_USERLIST_DATA_FAILED:
            updatedState = {
                ...state,
                userListLoading: false,
                userListErrors: [...state.userListErrors, action.error]
            }
            return updatedState;
        case FETCH_USERLIST_DATA_DISMISS_ERROR:
            updatedState = {
                ...state,
                userListErrors: []
            }
            return updatedState;
        case FETCH_BROWSE_VIDEO_BEGIN:
            updatedState = {
                ...state,
                browseVideoLoading: true,
                browseVideoErrors: []
            }
            return updatedState;
        case FETCH_BROWSE_VIDEO_SUCCESS:
            updatedState = {
                ...state,
                ...action.result,
                browseVideoLoading: false,
                browseVideoLoaded: true,
                browseVideoErrors: []
            }
            return updatedState;
        case FETCH_BROWSE_VIDEO_FAILED:
            updatedState = {
                ...state,
                browseVideoLoading: false,
                browseVideoErrors: [...state.browseVideoErrors, action.error]
            }
            return updatedState;
        case FETCH_BROWSE_VIDEO_DISMISS_ERROR:
            updatedState = {
                ...state,
                browseVideoErrors: []
            }
            return updatedState;
        case FETCH_WATCH_VIDEO_BEGIN:
            updatedState = {
                ...state,
                watchVideoLoading: true,
                watchVideoErrors: []
            }
            return updatedState;
        case FETCH_WATCH_VIDEO_SUCCESS:
            updatedState = {
                ...state,
                ...action.result,
                watchVideoLoading: false,
                watchVideoLoaded: true,
                watchVideoErrors: []
            }
            return updatedState;
        case FETCH_WATCH_VIDEO_FAILED:
            updatedState = {
                ...state,
                watchVideoLoading: false,
                watchVideoErrors: [...state.watchVideoErrors, action.error]
            }
            return updatedState;
        case FETCH_WATCH_VIDEO_DISMISS_ERROR:
            updatedState = {
                ...state,
                watchVideoErrors: []
            }
            return updatedState;
        case FETCH_USER_INFO_BEGIN:
            updatedState = {
                ...state,
                userInfoLoading: true,
                userInfoErrors: []
            }
            return updatedState;
        case FETCH_USER_INFO_SUCCESS:
            updatedState = {
                ...state,
                ...action.result,
                userInfoLoading: false,
                userInfoLoaded: true,
                userInfoErrors: []
            }
            return updatedState;
        case FETCH_USER_INFO_FAILED:
            updatedState = {
                ...state,
                userInfoLoading: false,
                userInfoErrors: [...state.userInfoErrors, action.error]
            }
            return updatedState;
        case FETCH_USER_INFO_DISMISS_ERROR:
            updatedState = {
                ...state,
                userInfoErrors: []
            }
            return updatedState;
        case FETCH_MORE_USER_VIDEOS_BEGIN:
            updatedState = {
                ...state,
                fetchMoreUserVideosLoading: true,
                fetchMoreUserVideosErrors: []
            }
            return updatedState;
        case FETCH_MORE_USER_VIDEOS_SUCCESS:
            oldVideos = state.userInfo.videos;
            if(!oldVideos){
                oldVideos = [];
            }
            newUserVideosList = oldVideos.concat(action.result.moreVideos);
            updatedState = {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    videos: newUserVideosList
                },
                fetchMoreUserVideosLoading: false,
                fetchMoreUserVideosLoaded: true,
                fetchMoreUserVideosErrors: []
            }
            return updatedState;
        case FETCH_MORE_USER_VIDEOS_FAILED:
            updatedState = {
                ...state,
                fetchMoreUserVideosLoading: false,
                fetchMoreUserVideosErrors: [...state.fetchMoreUserVideosErrors, action.error]
            }
            return updatedState;
        case FETCH_MORE_USER_VIDEOS_DISMISS_ERROR:
            updatedState = {
                ...state,
                fetchMoreUserVideosErrors: []
            }
            return updatedState;
        case FETCH_SEARCH_VIDEOS_BEGIN:
            updatedState = {
                ...state,
                searchVideosLoading: true,
                searchVideosErrors: []
            }
            return updatedState;
        case FETCH_SEARCH_VIDEOS_SUCCESS:
            updatedState = {
                ...state,
                ...action.result,
                searchVideosLoading: false,
                searchVideosLoaded: true,
                searchVideosErrors: []
            }
            return updatedState;
        case FETCH_SEARCH_VIDEOS_FAILED:
            updatedState = {
                ...state,
                searchVideosLoading: false,
                searchVideosErrors: [...state.searchVideosErrors, action.error]
            }
            return updatedState;
        case FETCH_SEARCH_VIDEOS_DISMISS_ERROR:
            updatedState = {
                ...state,
                searchVideosErrors: []
            }
            return updatedState;
        case FETCH_MORE_SEARCH_VIDEOS_BEGIN:
            updatedState = {
                ...state,
                moreSearchVideosLoading: true,
                moreSearchVideosErrors: []
            }
            return updatedState;
        case FETCH_MORE_SEARCH_VIDEOS_SUCCESS:
            oldVideos = state.searchVideos.videos;
            if(!oldVideos){
                oldVideos = [];
            }
            newSearchVideosList = oldVideos.concat(action.result.moreSearchVideos);
            updatedState = {
                ...state,
                searchVideos: {
                    ...state.searchVideos,
                    videos: newSearchVideosList
                },
                moreSearchVideosLoading: false,
                moreSearchVideosLoaded: true,
                moreSearchVideosErrors: []
            }
            return updatedState;
        case FETCH_MORE_SEARCH_VIDEOS_FAILED:
            updatedState = {
                ...state,
                moreSearchVideosLoading: false,
                moreSearchVideosErrors: [...state.moreSearchVideosErrors, action.error]
            }
            return updatedState;
        case FETCH_MORE_SEARCH_VIDEOS_DISMISS_ERROR:
            updatedState = {
                ...state,
                moreSearchVideosErrors: []
            }
            return updatedState;
        case UPLOAD_VIDEO_BEGIN:
            newUploadVideoList = state.uploadVideoList.map(elt => {
                return elt.key === action.video.key ?
                {
                    ...elt,
                    uploadProgress: {
                        step: 1,
                        percentage: 0
                    },
                    uploading: true,
                    errors: []
                } :
                elt;
            });

            updatedState = {
                ...state,
                uploadVideoList: newUploadVideoList
            }
            return updatedState;
        case UPLOAD_VIDEO_SUCCESS:
            newUploadVideoList = state.uploadVideoList.filter(elt => {
                return elt.key !== action.video.key
            });

            updatedState = {
                ...state,
                uploadVideoList: newUploadVideoList
            }
            return updatedState;
        case UPLOAD_VIDEO_FAILED:
            newUploadVideoList = state.uploadVideoList.map(elt => {
                return elt.key === action.video.key ?
                {
                    ...elt,
                    uploadProgress: {
                        step: 1,
                        percentage: 0
                    },
                    uploading: false,
                    errors: [...elt.errors, action.error]
                } :
                elt;
            });

            updatedState = {
                ...state,
                uploadVideoList: newUploadVideoList
            }
            return updatedState;
        case UPLOAD_VIDEO_DISMISS_ERROR:
            newUploadVideoList = state.uploadVideoList.map(elt => {
                return elt.key === action.video.key ?
                {
                    ...elt,
                    errors: []
                } :
                elt;
            });

            updatedState = {
                ...state,
                uploadVideoList: newUploadVideoList
            }
            return updatedState;
        case UPDATE_SHOW_UNFOLLOW_MODAL:
            updatedState = {
                ...state,
                showUnfollowModal: action.show
            }
            return updatedState;
        case UPLOAD_VIDEO_ADD:
            const time = Date.now();
            newUploadVideoList = state.uploadVideoList.concat(action.files.map((elt, index) => {
                return {
                    key: `${time} ${index}`,
                    file: elt,
                    uploadProgress: {
                        step: 1,
                        percentage: 0
                    },
                    uploading: false,
                    uploaded: false,
                    errors: []
                }
            }));

            updatedState = {
                ...state,
                uploadVideoList: newUploadVideoList
            }
            return updatedState;
        case THUMBNAIL_ADD:
            updatedState = {
                ...state,
                videoThumbnail: action.file
            }
            return updatedState;
        case AVATAR_ADD:
            updatedState = {
                ...state,
                userAvatar: action.file
            }
            return updatedState;
        case UPLOAD_VIDEO_REMOVE:
            newUploadVideoList = state.uploadVideoList.filter(elt => {
                return !action.keys.some(keyElt => keyElt === elt.key);
            });

            updatedState = {
                ...state,
                uploadVideoList: newUploadVideoList
            }
            return updatedState;
        case UPDATE_VIDEO_PROGRESS:
            if(action.uploadVideoList){
                newUploadVideoList = state.uploadVideoList.map(elt => {
                    return elt.key === action.videoId ?
                    {
                        ...elt,
                        uploadProgress: action.progress
                    } :
                    elt;
                });
    
                updatedState = {
                    ...state,
                    uploadVideoList: newUploadVideoList
                }
                return updatedState;
            }else{
                newVideoList = state.videoList.map(elt => {
                    return elt.id === action.videoId ?
                    {
                        ...elt,
                        progress: action.progress
                    } :
                    elt;
                });
    
                updatedState = {
                    ...state,
                    videoList: newVideoList
                }
                return updatedState;
            }
        default: return state;
    }
};

function* logoutSaga(action) {
    try {

        yield axios.get(
            "/api/logout"
        );

        action.history.push('/welcome');

        yield put(logoutSuccess());

    } catch (error) {

    }
}

function* fetchUserLoggedInStatusSaga(action) {
    try {

        const response = yield axios.get(
            "/api/userloggedinstatus"
        );

        yield put(fetchUserLoggedInStatusSuccess(response.data));

        if(action.isCreatingAccount){
            if(!response.data.userHasPaymentMethod){
                action.history.push("/signup/addpayment");
            }else{
                action.history.push("/home");
            }
        }

        if(action.isSigningIn){
            if(!response.data.userHasPaymentMethod){
                action.history.push("/signup/chooseplan");
            }else{
                action.history.push("/home");
            }
        }

    } catch (error) {

        yield put(fetchUserLoggedInStatusFailed(error));

    }
}

function* fetchVideoInfoSaga(action) {
    try {

        const response = yield axios.get(
            "/api/videoinfo/" + action.id
        );

        yield put(fetchVideoInfoSuccess(response.data));

    } catch (error) {

        yield put(fetchVideoInfoFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* fetchUserPanelInfoSaga(action) {
    try {

        const response = yield axios.get(
            "/api/userpanelinfo/" + action.id
        );

        yield put(fetchUserPanelInfoSuccess(response.data));

    } catch (error) {

        yield put(fetchUserPanelInfoFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* fetchUserSettingsSaga(action) {
    try {

        const response = yield axios.get(
            "/api/accountsettings"
        );

        yield put(fetchUserSettingsSuccess(response.data));

    } catch (error) {

        yield put(fetchUserSettingsFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* updateFollowSaga(action) {
    try {

        const queries = [];
        queries.push(`user=${action.user}`);
        queries.push(`follow=${action.follow ? 1 : 0}`);

        const URL = `/api/updatefollow${queries.length > 0 && '?'}${queries.join('&')}`;

        yield axios.get(
            URL
        );

        yield put(updateFollowSuccess(action.follow));

    } catch (error) {

        yield put(updateFollowFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* fetchVideoCategorySuggestionsSaga(action) {
    try {

        const response = yield axios.get(
            "/api/videocategorysuggestions"
        );

        yield put(fetchVideoCategorySuggestionsSuccess(response.data));

    } catch (error) {

        yield put(fetchVideoCategorySuggestionsFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* fetchSignupInfoSaga(action) {
    try {

        const response = yield axios.get(
            "/api/signupinfo"
        );
        const state = yield select();
        if(state.app.waitOnExitingPage || !state.app.hidePage){
            let flag = true;
            while(flag){
                const action = yield take(WAIT_ON_EXITING_PAGE);
                flag = action.flag;
            }
        }

        yield put(fetchSignupInfoSuccess(response.data));
        yield put(hidePage(false));

    } catch (error) {

        yield put(fetchSignupInfoFailed(error));
        yield put(hidePage(false));

    }
}

function* fetchUserSuggestionsSaga(action) {
    try {

        const queries = [];
        queries.push(`string=${btoa(action.string)}`);
        queries.push(`top=${action.top}`);

        const URL = `/api/usersuggestions${queries.length > 0 && '?'}${queries.join('&')}`;

        const response = yield axios.get(
            URL
        );

        yield put(fetchUserSuggestionsSuccess(response.data));

    } catch (error) {

        yield put(fetchUserSuggestionsFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* editVideoInfoSaga(action) {
    try {

        const state = yield select();

        if(state.app.videoThumbnail){

            let baseURL = `${config.development.base_url}/api/videoinfoedit`;
            if (JSON.stringify(process.env.NODE_ENV) !== JSON.stringify('development')) {
                baseURL = `${config.production.base_url}/api/videoinfoedit`;
            }

            const formData = new FormData();
            formData.append("id", action.id);
            formData.append("name", action.name);
            formData.append("description", action.description);
            formData.append("category", action.category);
            formData.append("owner", action.owner);
            formData.append("visibility", action.visibility);
            formData.append("file", state.app.videoThumbnail, "thumbnail.png");

            yield axios({
                method: 'post',
                url: baseURL,
                data: formData,
            });

        } else {

            let baseURL = `${config.development.base_url}/api/videoinfoedit2`;
            if (JSON.stringify(process.env.NODE_ENV) !== JSON.stringify('development')) {
                baseURL = `${config.production.base_url}/api/videoinfoedit2`;
            }

            yield axios.post(
                baseURL, {
                    id: action.id,
                    name: action.name,
                    description: action.description,
                    category: action.category,
                    owner: action.owner,
                    visibility: action.visibility
                }
            );

        }

        yield put(editVideoInfoSuccess(action.id, action.name, action.description));

    } catch (error) {

        yield put(editVideoInfoFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* editUserSettingsInfoSaga(action) {
    try {

        const state = yield select();

        if(state.app.userAvatar){

            let baseURL = `${config.development.base_url}/api/usersettingsinfoedit`;
            if (JSON.stringify(process.env.NODE_ENV) !== JSON.stringify('development')) {
                baseURL = `${config.production.base_url}/api/usersettingsinfoedit`;
            }

            const formData = new FormData();
            formData.append("username", action.username);
            formData.append("file", state.app.userAvatar, "avatar.png");

            yield axios({
                method: 'post',
                url: baseURL,
                data: formData,
            });

        } else {

            let baseURL = `${config.development.base_url}/api/usersettingsinfoedit2`;
            if (JSON.stringify(process.env.NODE_ENV) !== JSON.stringify('development')) {
                baseURL = `${config.production.base_url}/api/usersettingsinfoedit2`;
            }

            yield axios.post(
                baseURL, {
                    username: action.username
                }
            );

        }

        yield put(editUserSettingsInfoSuccess(action.username));

    } catch (error) {

        yield put(editUserSettingsInfoFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* editUserInfoSaga(action) {
    try {

        yield axios.post(
            '/api/userinfoedit', {
                id: action.id,
                type: action.userType
            }
        );

        yield put(editUserInfoSuccess(action.id, action.userType));

    } catch (error) {

        yield put(editUserInfoFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* editConfigInfoSaga(action) {
    try {

        yield axios.post(
            '/api/configinfoedit', {
                publickey: action.publickey,
                privatekey: action.privatekey,
                cost_basic: action.cost_basic,
                cost: action.cost
            }
        );

        yield put(editConfigInfoSuccess(action.publickey, action.privatekey, action.cost_basic, action.cost));

    } catch (error) {

        yield put(editConfigInfoFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* editSubscriptionInfoSaga(action) {
    try {

        const result = yield axios.post(
            '/api/subscriptioninfoedit', {
                plan: action.planType
            }
        );

        yield put(editSubscriptionInfoSuccess(result.data.subscriptionStatus, result.data.subscriptionEndDate, result.data.userActiveSub, result.data.userSubType));

    } catch (error) {

        yield put(editSubscriptionInfoFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* editPaymentInfoSaga(action) {
    try {

        yield axios.post(
            '/api/upsertpaymentmethod', {
                id: action.paymentmethod.id
            }
        );

        if(action.choosePlan){
            const state = yield select();
            yield axios.post(
                '/api/subscriptioninfoedit', {
                    plan: state.app.signupChosenPlan
                }
            );
        }

        yield put(editPaymentInfoSuccess(action.paymentmethod));

        if(action.choosePlan){
            action.history.push('/home');
        }

    } catch (error) {

        yield put(editPaymentInfoFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* updateViewWatchAmountSaga(action) {
    try {

        let baseURL = `${config.development.base_url}/api/updatevideowatchamount`;
        if (JSON.stringify(process.env.NODE_ENV) !== JSON.stringify('development')) {
            baseURL = `${config.production.base_url}/api/updatevideowatchamount`;
        }

        yield axios.post(
            baseURL,{
                viewId: action.id,
                watchAmount: action.amount
            }
        );

        yield put(updateViewWatchAmountSuccess());

    } catch (error) {

        yield put(updateViewWatchAmountFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* updateVideolistProgress(row) {

    let percentage = row.progress.percentage;
    let step = row.progress.step;

    while (step !== 4 || percentage !== 100) {

        yield delay(config.development.throttle);
        const response = yield axios.get(
            `/api/progress/${row.id}`
        );
        percentage = response.data.progress.percentage;
        step = response.data.progress.step;
        yield put(updateVideoProgress(row.id, response.data.progress, false));
    }
    yield put(updateVideoProgress(row.id, {percentage: 100, step: 4}, false));
}

function* fetchVideolistDataSaga(action) {
    try {

        const queries = [];
        if(action.searchString){
            queries.push(`searchString=${btoa(action.searchString)}`);
        }
        queries.push(`currentPage=${action.currentPage}`);
        queries.push(`rowsPerPage=${action.rowsPerPage}`);

        const URL = `/api/videolist${queries.length > 0 && '?'}${queries.join('&')}`;

        const response = yield axios.get(
            URL
        );

        yield put(fetchVideolistDataSuccess(response.data));

        yield all(response.data.videoList.map(row => fork(updateVideolistProgress, row)));

    } catch (error) {

        yield put(fetchVideolistDataFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* fetchUserlistDataSaga(action) {
    try {

        const queries = [];
        if(action.searchString){
            queries.push(`searchString=${btoa(action.searchString)}`);
        }
        queries.push(`currentPage=${action.currentPage}`);
        queries.push(`rowsPerPage=${action.rowsPerPage}`);

        const URL = `/api/userlist${queries.length > 0 && '?'}${queries.join('&')}`;

        const response = yield axios.get(
            URL
        );

        yield put(fetchUserlistDataSuccess(response.data));

    } catch (error) {

        yield put(fetchUserlistDataFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* fetchStatisticsSaga(action) {
    try {

        const response = yield axios.get(
            '/api/statistics'
        );

        yield put(fetchStatisticsSuccess(response.data));

    } catch (error) {

        yield put(fetchStatisticsFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* fetchBrowseVideoSaga(action) {
    try {

        const URL = '/api/browsevideos';

        const response = yield axios.get(
            URL
        );
        const state = yield select();
        if(state.app.waitOnExitingPage || !state.app.hidePage){
            let flag = true;
            while(flag){
                const action = yield take(WAIT_ON_EXITING_PAGE);
                flag = action.flag;
            }
        }

        yield put(fetchBrowseVideoSuccess(response.data));
        yield put(hidePage(false));

    } catch (error) {

        yield put(fetchBrowseVideoFailed(error));
        yield put(hidePage(false));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* fetchWatchVideoSaga(action) {
    try {

        const response = yield axios.get(
            "/api/watchvideo/" + action.id
        );
        const state = yield select();
        if(state.app.waitOnExitingPage || !state.app.hidePage){
            let flag = true;
            while(flag){
                const action = yield take(WAIT_ON_EXITING_PAGE);
                flag = action.flag;
            }
        }

        yield put(fetchWatchVideoSuccess(response.data));
        yield put(hidePage(false));

    } catch (error) {

        yield put(fetchWatchVideoFailed(error));
        yield put(hidePage(false));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }
        if(error.response && error.response.data && error.response.data.errorType === 3){
            action.history.push('/subscription-required');
        }
        if(error.response && error.response.data && error.response.data.errorType === 4){
            action.history.push('/max-minutes');
        }

    }
}

function* fetchUserInfoSaga(action) {
    try {

        const queries = [];
        queries.push(`top=${action.top}`);
        queries.push(`skip=${action.skip}`);

        const URL = `/api/userpageinfo/${action.user}${queries.length > 0 && '?'}${queries.join('&')}`;

        const response = yield axios.get(
            URL
        );
        const state = yield select();
        if(state.app.waitOnExitingPage || !state.app.hidePage){
            let flag = true;
            while(flag){
                const action = yield take(WAIT_ON_EXITING_PAGE);
                flag = action.flag;
            }
        }

        yield put(fetchUserInfoSuccess(response.data));
        yield put(hidePage(false));

    } catch (error) {

        yield put(fetchUserInfoFailed(error));
        yield put(hidePage(false));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* fetchMoreUserVideosSaga(action) {
    try {

        const queries = [];
        queries.push(`top=${action.top}`);
        queries.push(`skip=${action.skip}`);

        const URL = `/api/userpagemorevideos/${action.user}${queries.length > 0 && '?'}${queries.join('&')}`;

        const response = yield axios.get(
            URL
        );

        yield put(fetchMoreUserVideosSuccess(response.data));

    } catch (error) {

        yield put(fetchMoreUserVideosFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* fetchSearchVideosSaga(action) {
    try {

        const queries = [];
        if(action.name){
            queries.push(`name=${btoa(action.name)}`);
        }
        if(action.category){
            if(action.category.inputValue){
                queries.push(`categoryString=${btoa(action.category.inputValue)}`);
            }
            queries.push(action.category.selectedItem.map(row => `categoryIds=${row.id}`).join('&'));
        }
        if(action.owner){
            if(action.owner.inputValue){
                queries.push(`ownerString=${btoa(action.owner.inputValue)}`);
            }
            queries.push(action.owner.selectedItem.map(row => `ownerIds=${row.id}`).join('&'));
        }
        if(action.date){
            queries.push(`date=${btoa(action.date)}`);
        }
        queries.push(`top=${action.top}`);
        queries.push(`skip=${action.skip}`);

        const URL = `/api/searchvideopage${queries.length > 0 && '?'}${queries.join('&')}`;

        const response = yield axios.get(
            URL
        );

        const state = yield select();
        if(state.app.waitOnExitingPage || !state.app.hidePage){
            let flag = true;
            while(flag){
                const action = yield take(WAIT_ON_EXITING_PAGE);
                flag = action.flag;
            }
        }

        yield put(fetchSearchVideosSuccess(response.data));
        yield put(hidePage(false));

    } catch (error) {

        yield put(fetchSearchVideosFailed(error));
        yield put(hidePage(false));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* fetchMoreSearchVideosSaga(action) {
    try {

        const queries = [];
        if(action.name){
            queries.push(`name=${btoa(action.name)}`);
        }
        if(action.category){
            if(action.category.inputValue){
                queries.push(`categoryString=${btoa(action.category.inputValue)}`);
            }
            queries.push(action.category.selectedItem.map(row => `categoryIds=${row.id}`).join('&'));
        }
        if(action.owner){
            if(action.owner.inputValue){
                queries.push(`ownerString=${btoa(action.owner.inputValue)}`);
            }
            queries.push(action.owner.selectedItem.map(row => `ownerIds=${row.id}`).join('&'));
        }
        if(action.date){
            queries.push(`date=${btoa(action.date)}`);
        }
        queries.push(`top=${action.top}`);
        queries.push(`skip=${action.skip}`);

        const URL = `/api/searchmorevideopage${queries.length > 0 && '?'}${queries.join('&')}`;

        const response = yield axios.get(
            URL
        );

        yield put(fetchMoreSearchVideosSuccess(response.data));

    } catch (error) {

        yield put(fetchMoreSearchVideosFailed(error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* dummyPageInitSaga(action) {
    try {

        const state = yield select();
        if(state.app.waitOnExitingPage || !state.app.hidePage){
            let flag = true;
            while(flag){
                const action = yield take(WAIT_ON_EXITING_PAGE);
                flag = action.flag;
            }
        }
        
        yield put(hidePage(false));

    } catch (error) {

        yield put(hidePage(false));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }

    }
}

function* watchOnProgress(chan, video) {
    let percentage, step;
    while (percentage !== 0 || step !== 2) {
        const event = yield take(chan);
        percentage = (event.loaded / event.total) * 100;
        // console.log(event.loaded,event.total)
        step = 1;
        yield put(updateVideoProgress(video.key, { percentage, step }, true));
    }
}

function* uploadVideoSaga(action) {
    try {

        let emit;

        const chan = eventChannel(e => {
            emit = e;
            return () => {};
        });

        const progressFunc = (event) => {
            if (event.loaded.total === 1) {
                emit(END);
            } else {
                emit(event);
            }
        }

        yield fork(watchOnProgress, chan, action.video);

        let baseURL = `${config.development.base_url}/api/upload`;
        if (JSON.stringify(process.env.NODE_ENV) !== JSON.stringify('development')) {
            baseURL = `${config.production.base_url}/api/upload`;
        }

        const formData = new FormData();
        formData.append("metadata", { name: "name1" });
        formData.append("file", action.video.file, action.video.file.name);

        yield axios({
            method: 'post',
            url: baseURL,
            data: formData,
            onUploadProgress: progressFunc
        });

        yield put(updateVideoProgress(action.video.key, { percentage: 0, step: 2 }, true));

        yield put(uploadVideoSuccess(action.video));

    } catch (error) {
        yield put(uploadVideoFailed(action.video, error));
        if(error.response && error.response.data && error.response.data.errorType === 1){
            action.history.push('/login');
        }
    }
}

export function* watchApp() {
    yield takeLatest(FETCH_VIDEOLIST_DATA_BEGIN, fetchVideolistDataSaga);
    yield takeLatest(FETCH_USERLIST_DATA_BEGIN, fetchUserlistDataSaga);
    yield takeLatest(UPDATE_VIEW_WATCH_AMOUNT_BEGIN, updateViewWatchAmountSaga);
    yield takeLatest(FETCH_USER_SUGGESTIONS_BEGIN, fetchUserSuggestionsSaga);
    yield takeLatest(FETCH_VIDEO_CATEGORY_SUGGESTIONS_BEGIN, fetchVideoCategorySuggestionsSaga);

    yield takeLatest(FETCH_USER_SETTINGS_BEGIN, fetchUserSettingsSaga);
    yield takeEvery(UPLOAD_VIDEO_BEGIN, uploadVideoSaga);
    yield takeEvery(FETCH_SIGNUP_INFO_BEGIN, fetchSignupInfoSaga);
    yield takeEvery(FETCH_VIDEO_INFO_BEGIN, fetchVideoInfoSaga);
    yield takeEvery(FETCH_USER_PANEL_INFO_BEGIN, fetchUserPanelInfoSaga);
    yield takeEvery(EDIT_SUBSCRIPTION_INFO_BEGIN, editSubscriptionInfoSaga);
    yield takeEvery(EDIT_USER_SETTINGS_INFO_BEGIN, editUserSettingsInfoSaga);
    yield takeEvery(EDIT_CONFIG_INFO_BEGIN, editConfigInfoSaga);
    yield takeEvery(EDIT_USER_INFO_BEGIN, editUserInfoSaga);
    yield takeEvery(EDIT_PAYMENT_INFO_BEGIN, editPaymentInfoSaga);
    yield takeEvery(EDIT_VIDEO_INFO_BEGIN, editVideoInfoSaga);
    yield takeEvery(FETCH_BROWSE_VIDEO_BEGIN, fetchBrowseVideoSaga);
    yield takeEvery(FETCH_WATCH_VIDEO_BEGIN, fetchWatchVideoSaga);
    yield takeEvery(DUMMY_PAGE_INIT, dummyPageInitSaga);
    yield takeEvery(FETCH_USER_INFO_BEGIN, fetchUserInfoSaga);
    yield takeEvery(FETCH_MORE_USER_VIDEOS_BEGIN, fetchMoreUserVideosSaga);
    yield takeEvery(FETCH_SEARCH_VIDEOS_BEGIN, fetchSearchVideosSaga);
    yield takeEvery(FETCH_MORE_SEARCH_VIDEOS_BEGIN, fetchMoreSearchVideosSaga);
    yield takeEvery(UPDATE_FOLLOW_BEGIN, updateFollowSaga);
    yield takeEvery(FETCH_USER_LOGGED_IN_STATUS_BEGIN, fetchUserLoggedInStatusSaga);
    yield takeEvery(LOGOUT, logoutSaga);
    yield takeEvery(FETCH_STATISTICS_BEGIN, fetchStatisticsSaga);
}

export default reducer;

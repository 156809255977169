import React, {Component} from 'react';
import {Elements} from 'react-stripe-elements';

import InjectedPaymentForm from './PaymentForm';

class Stripe extends Component {
  render() {
    return (
      <Elements>
        <InjectedPaymentForm onBlur={this.props.onBlur} onChange={this.props.onChange} />
      </Elements>
    );
  }
}

export default Stripe;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import 'typeface-roboto';

import { StylesProvider } from '@material-ui/styles';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import store from './store/ducks/index';



const app = (
    <Provider store={store}>
        <BrowserRouter>
            <StylesProvider injectFirst>
                <App />
            </StylesProvider>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

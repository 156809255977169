import React, { Component } from "react";

import {connect} from 'react-redux';

import VideoCard from '../BrowseVideoPage/VideoScroller/VideoCard/VideoCard';
import FollowButton from '../FollowButton/FollowButton';
import {withRouter} from 'react-router-dom';

import Typography from "@material-ui/core/Typography";

import CircularProgress from "@material-ui/core/CircularProgress";

import _ from 'lodash';

import { withStyles } from "@material-ui/core/styles";

import * as AppActions from '../../../store/ducks/app';

import config from '../../../config/config';

const styles = theme => ({
    root: {
        borderBottom: '2px solid #eee'
    },
    headerText: {
        fontSize: '2rem',
        lineHeight: '2.25rem'
    },
    subHeaderText: {
        fontSize: '1rem',
        fontWeight: '500',
        color: '#888'
    },
    headerName: {
        fontSize: '2.5rem',
        fontWeight: '500',
        marginTop: '1rem'
    },
    avatar: {
        objectFit: 'cover',
        maxWidth: '100%',
        maxHeight: '100%',
        width: 'auto',
        height: 'auto'
    }
});

class UserPage extends Component {

    constructor(props) {
        super(props);
        this.handleScrollThrottle = _.debounce(this.handleScroll, 200);
    }

    handleScroll = () => {
        const offsetHeight = Math.max( document.body.scrollHeight, document.body.offsetHeight, 
            document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
        const currentVideoCount = this.props.stateUserInfo.videos ? this.props.stateUserInfo.videos.length : 0;
        const totalVideoCount = this.props.stateUserInfo.videoCount || 0;
        if(window.innerHeight + document.documentElement.scrollTop > offsetHeight - 60 && 
            !this.props.stateUserInfoLoading && 
            !this.props.stateFetchMoreUserVideosLoading &&
            currentVideoCount < totalVideoCount) {
            this.props.fetchMoreVideos(this.props.id, 12, currentVideoCount, this.props.history);
        }
    }

    componentDidUpdate(){
        this.handleScrollThrottle();
    }

    componentDidMount(){
        this.props.onUserInfoInit(this.props.id, 12, 0, this.props.history);
        window.addEventListener("scroll", this.handleScrollThrottle);
        this.handleScrollThrottle();
    }

    componentWillUnmount = () => {
        window.removeEventListener("scroll", this.handleScrollThrottle);
    };

    render() {

        let userHeader = <CircularProgress size={48} />;

        let userImageUrl = `https://${config.development.cloud_bucket}.storage.googleapis.com/users/${this.props.stateUserInfo.GCPuserID}/avatar.png?avoidCache=1`;
        if(config.demo){
            let baseUrl = JSON.stringify(process.env.NODE_ENV) === JSON.stringify('development') ? config.development.proxy : config.production.base_url;
            userImageUrl = `${baseUrl}/files/users/${this.props.stateUserInfo.GCPuserID}/avatar.png`;
        }

        if(this.props.stateUserInfoLoaded){
            userHeader = (
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '2rem'}}>
                    <div style={{width: '128px', height: '128px', display: 'flex', justifyContent: 'center', borderRadius: '50%', overflow: 'hidden', backgroundColor: '#eee'}}>
                        <img
                            className={this.props.classes.avatar}
                            alt=''
                            src={userImageUrl}>
                        </img>
                    </div>
                    <Typography className={this.props.classes.headerName}>{this.props.stateUserInfo.name}</Typography>
                    <FollowButton
                        modalOpen={this.props.stateShowUnfollowModal}
                        unfollowSending={this.props.stateUpdateFollowSending}
                        unfollowSent={this.props.stateUpdateFollowSent}
                        unfollowedName={this.props.stateUserInfo.name}
                        isFollowing={this.props.stateUserInfo.isFollowing}
                        followedId={this.props.stateUserInfo.id} 
                    />
                    <div style={{display: 'flex', marginTop: '1.5rem'}}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 1rem', boxSizing: 'border-box'}}>
                            <Typography className={this.props.classes.headerText}>{this.props.stateUserInfo.videoCount}</Typography>
                            <Typography className={this.props.classes.subHeaderText}>VIDEOS</Typography>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 1rem', boxSizing: 'border-box'}}>
                            <Typography className={this.props.classes.headerText}>{this.props.stateUserInfo.viewCount}</Typography>
                            <Typography className={this.props.classes.subHeaderText}>VIEWS</Typography>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 1rem', boxSizing: 'border-box'}}>
                            <Typography className={this.props.classes.headerText}>{this.props.stateUserInfo.followerCount}</Typography>
                            <Typography className={this.props.classes.subHeaderText}>FOLLOWERS</Typography>
                        </div>
                    </div>
                </div>
                
            );
        }

        let videoList = <CircularProgress size={48} />;

        const currentVideoCount = this.props.stateUserInfo.videos ? this.props.stateUserInfo.videos.length : 0;
        const totalVideoCount = this.props.stateUserInfo.videoCount || 0;
        let loadMoreVideos = null;
        if(currentVideoCount < totalVideoCount){
            loadMoreVideos = (
                <div style={{height: '60px'}}>
                    <CircularProgress size={48} />
                </div>
            )
        }

        if(this.props.stateUserInfoLoaded){
            videoList = (
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fill, 216px)', justifyContent: 'center', width: '100%', maxWidth: 'calc(864px + 4rem)', boxSizing: 'border-box', padding: '2rem'}}>
                        {
                        this.props.stateUserInfo.videos.map(row => ( 
                            <VideoCard
                                key={row.id}
                                id={row.id}
                                views={row.views}
                                GCPid={row.GCPid}
                                title={row.title}
                                dateUploaded={row.dateUploaded} 
                                ownerId={row.ownerId}
                                ownerName={row.ownerName}
                            />
                        ))
                        }
                    </div>
                    {loadMoreVideos}
                    
                </div>
                
            );
        }

        

        return (
            <div>
                {userHeader}
                {videoList}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        stateUserInfo: state.app.userInfo,
        stateUserInfoLoading: state.app.userInfoLoading,
        stateFetchMoreUserVideosLoading: state.app.fetchMoreUserVideosLoading,
        stateUserInfoLoaded: state.app.userInfoLoaded,
        stateShowUnfollowModal: state.app.showUnfollowModal,
        stateUpdateFollowSending: state.app.updateFollowSending,
        stateUpdateFollowSent: state.app.updateFollowSent
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onUserInfoInit: (id, top, skip, history) => dispatch(AppActions.fetchUserInfoBegin(id, top, skip, history)),
        fetchMoreVideos: (id, top, skip, history) => dispatch(AppActions.fetchMoreUserVideosBegin(id, top, skip, history)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserPage)));
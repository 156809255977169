import React, { Component } from "react";

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import { withStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

import moment from 'moment';

import * as AppActions from '../../../../store/ducks/app';

import Plot from './Plot/Plot';

import * as classes from './StatisticsTab.module.css';

const styles = theme => ({
    tableProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -24,
        marginLeft: -24,
    },
});

class StatisticsTab extends Component {

    state={
        plotWidth: 600,
        plotAxisLabelInterval: 1
    }

    determinePlotWidth = () => {
        if(document.documentElement.clientWidth < 400){
            this.setState({plotWidth: 265, plotAxisLabelInterval: 5});
        }else if(document.documentElement.clientWidth < 500){
            this.setState({plotWidth: 300, plotAxisLabelInterval: 5});
        }else if(document.documentElement.clientWidth < 600){
            this.setState({plotWidth: 400, plotAxisLabelInterval: 3});
        }else if(document.documentElement.clientWidth < 700){
            this.setState({plotWidth: 500, plotAxisLabelInterval: 2});
        }else{
            this.setState({plotWidth: 600, plotAxisLabelInterval: 1});
        }
    }

    componentDidMount(){
        this.props.onFetchStatistics(this.props.history);
        this.determinePlotWidth();
        window.addEventListener('resize', this.determinePlotWidth);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.determinePlotWidth);
    }

    plotDataFillZeros = (viewPlotData) => {
        let currentIndex = 0;
        let newViewPlotData = [];
        for(let i=30; i>=0; i--){
            if(viewPlotData[currentIndex] && viewPlotData[currentIndex].x === moment.utc().local().subtract(i, 'days').format('MM-DD-YYYY')){
                newViewPlotData.push({
                    ...viewPlotData[currentIndex],
                    y: Math.ceil(viewPlotData[currentIndex].y)
                });
                currentIndex++;
            }else{
                newViewPlotData.push({x: moment.utc().local().subtract(i, 'days').format('MM-DD-YYYY'), y: 0});
            }
        }
        return newViewPlotData;
    }

    renderPlot = (plotData, statName, dateName, title, label) => {
        let viewPlotData = [];
        if(this.props.stateStatisticsLoaded){
            viewPlotData = plotData ? plotData.map(row => ({x: moment.utc(row[dateName]).local().format('MM-DD-YYYY'), y: row[statName]})) : [];
        }
        viewPlotData = this.plotDataFillZeros(viewPlotData.filter(row => !moment(row.x).isBefore(moment.utc().local().subtract(30, 'days').format('MM-DD-YYYY'))));
        return (
            <Plot 
                plotData={viewPlotData}  
                title={title}  
                label={label}
                plotWidth={this.state.plotWidth}
                plotAxisLabelInterval={this.state.plotAxisLabelInterval}
            />
        );
    }

    render() {

        return (
            <div style={{position: 'relative'}}>
                <div style={{opacity: `${this.props.stateStatisticsLoading ? .3 : 1}`, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <div className={classes.plotRow}>
                        {this.renderPlot(this.props.stateStatistics.views, 'views', 'watch_date', 'Views', 'views')}
                        {this.renderPlot(this.props.stateStatistics.averageViewDuration, 'average_view_duration', 'watch_date', 'Average View Duration (seconds)', 'seconds')}
                    </div>
                    <div className={classes.plotRow}>
                        {this.renderPlot(this.props.stateStatistics.totalWatchTime, 'total_watch_time', 'watch_date', 'Total Watch Time (seconds)', 'seconds')}
                        {this.renderPlot(this.props.stateStatistics.follows, 'follows', 'follow_date', 'Follows', 'follows')}
                    </div>
                </div>
                {this.props.stateStatisticsLoading && <CircularProgress size={48} className={this.props.classes.tableProgress} />}
            </div>
            
        );
    }
}

const mapStateToProps = state => {
    return {
        stateStatistics: state.app.statistics,
        stateStatisticsLoading: state.app.statisticsLoading,
        stateStatisticsLoaded: state.app.statisticsLoaded
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchStatistics: (history) => dispatch(AppActions.fetchStatisticsBegin(history))
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StatisticsTab)));

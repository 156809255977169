import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { green } from '@material-ui/core/colors';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {withRouter} from 'react-router-dom';

import FieldComponent from "../../Forms/FieldComponent/FieldComponent";

import * as AppActions from "../../../store/ducks/app";
import * as classes from './UserEditPanel.module.css';

const styles = theme => ({
    root: {
        width: "100%",
        overflowX: "auto"
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    progress: {
        margin: theme.spacing(2)
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

const fields = [
    { label: "Permission Type", name: "type", componentType: "dropdown" }
];

class UserEditPanel extends Component {

    onFormSubmit = ({ type }, actions) => {
        this.props.onEditUserInfo(this.props.userEditId, type, this.props.history);
        actions.setSubmitting(false);
    };

    onFormClose = () => {
        this.props.handleModalClose();
    }

    renderFields = (values, errors, touched, handleBlur, handleChange, setFieldValue) => {
        return fields.map(({ label, name, componentType, maxAutocompleteSelections }) => {
            return (
                <FieldComponent
                    key={name}
                    name={name}
                    type="text"
                    componentType={componentType}
                    label={label}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[name]}
                    error={errors[name]}
                    touched={touched[name]}
                    setFieldValue={setFieldValue}
                    maxAutocompleteSelections={maxAutocompleteSelections}
                    userSuggestionsSearch={this.handleUserSuggestionsSearchThrottle}
                />
            );
        });
    };

    render() {
        const DialogTitle = withStyles(theme => ({
            root: {
                margin: 0,
                padding: theme.spacing(2)
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500]
            }
        }))(props => {
            const { children, classes } = props;
            return (
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">{children}</Typography>
                </MuiDialogTitle>
            );
        });

        const DialogContent = withStyles(theme => ({
            root: {
                padding: theme.spacing(2)
            }
        }))(MuiDialogContent);

        const DialogActions = withStyles(theme => ({
            root: {
                margin: 0,
                padding: theme.spacing(1)
            }
        }))(MuiDialogActions);

        let initialValue = {};
        fields.forEach(elt => {
            if(elt.componentType === "dropdown"){

                initialValue[elt.name] = this.props.formValues[elt.name] || 1;
                
            }else if(elt.componentType === "switch"){
                initialValue[elt.name] = this.props.formValues[elt.name] !== 1;
            }else{
                initialValue[elt.name] = this.props.formValues[elt.name] || "";
            }
            
        });

        return (
            <Dialog
                onClose={this.props.handleModalClose}
                open={this.props.modalOpen}
                className={this.props.classes.root}
                fullWidth
                classes={{
                    paper: classes.dialog,
                }}
            >
                {!this.props.formValuesLoaded ? (
                    <CircularProgress className={this.props.classes.progress} />
                ) : (
                    <Formik
                        initialValues={initialValue}
                        onSubmit={this.onFormSubmit}
                        validationSchema={validateSchema}
                        render={({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange, 
                            setFieldValue
                        }) => (
                            <Form>
                                <DialogTitle>{`Edit User: ${this.props.formValues.name}`}</DialogTitle>
                                <DialogContent dividers>
                                    {this.renderFields(
                                        values,
                                        errors,
                                        touched,
                                        handleBlur,
                                        handleChange,
                                        setFieldValue
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    <div className={this.props.classes.wrapper}>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            disabled={this.props.editUserInfoSending}
                                        >
                                            Save changes
                                        </Button>
                                        {this.props.editUserInfoSending && <CircularProgress size={24} className={this.props.classes.buttonProgress} />}
                                    </div>
                                    
                                    <Button
                                        onClick={this.onFormClose}
                                        color="secondary"
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    />
                )}
            </Dialog>
        );
    }
}

const validateSchema = Yup.object().shape({});

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditUserInfo: (id, type, history) =>
            dispatch(AppActions.editUserInfoBegin(id, type, history))
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(UserEditPanel)));

import React, { Component } from "react";

import {connect} from 'react-redux';

import VideoCard from '../BrowseVideoPage/VideoScroller/VideoCard/VideoCard';
import {withRouter} from 'react-router-dom';

import Typography from "@material-ui/core/Typography";

import CircularProgress from "@material-ui/core/CircularProgress";

import _ from 'lodash';

import { withStyles } from "@material-ui/core/styles";

import * as AppActions from '../../../store/ducks/app';

const styles = theme => ({
    root: {
        borderBottom: '2px solid #eee'
    },
    headerText: {
        fontSize: '2rem',
        lineHeight: '2.25rem'
    },
    subHeaderText: {
        fontSize: '1rem',
        fontWeight: '500',
        color: '#888'
    },
    headerName: {
        fontSize: '1.5rem',
        fontWeight: '400',
        margin: '1rem'
    },
    avatar: {
        objectFit: 'cover',
        maxWidth: '100%',
        maxHeight: '100%',
        width: 'auto',
        height: 'auto'
    }
});

class UserPage extends Component {

    constructor(props) {
        super(props);
        this.handleScrollThrottle = _.debounce(this.handleScroll, 200);
    }

    handleScroll = () => {
        const offsetHeight = Math.max( document.body.scrollHeight, document.body.offsetHeight, 
            document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
        const currentVideoCount = this.props.stateSearchVideos.videos ? this.props.stateSearchVideos.videos.length : 0;
        const totalVideoCount = this.props.stateSearchVideos.videoCount || 0;
        if(window.innerHeight + document.documentElement.scrollTop > offsetHeight - 60 && 
            !this.props.stateSearchVideosLoading && 
            !this.props.stateMoreSearchVideosLoading &&
            currentVideoCount < totalVideoCount) {
            let locationState = (this.props.location.state && this.props.location.state.videoSearch) || {};
            this.props.fetchMoreSearchVideos(locationState.name, locationState.category, locationState.owner, locationState.date, 12, currentVideoCount, this.props.history);
        }
    }

    componentDidUpdate(){
        this.handleScrollThrottle();
    }

    componentDidMount(){
        let locationState = (this.props.location.state && this.props.location.state.videoSearch) || {};
        this.props.fetchSearchVideos(locationState.name, locationState.category, locationState.owner, locationState.date, 12, 0, this.props.history);
        window.addEventListener("scroll", this.handleScrollThrottle);
        this.handleScrollThrottle();
    }

    componentWillUnmount = () => {
        window.removeEventListener("scroll", this.handleScrollThrottle);
    };

    render() {

        let videoList = <CircularProgress size={48} />;

        const currentVideoCount = this.props.stateSearchVideos.videos ? this.props.stateSearchVideos.videos.length : 0;
        const totalVideoCount = this.props.stateSearchVideos.videoCount || 0;
        let loadMoreVideos = null;
        if(currentVideoCount < totalVideoCount){
            loadMoreVideos = (
                <div style={{height: '60px'}}>
                    <CircularProgress size={48} />
                </div>
            )
        }

        if(this.props.stateSearchVideosLoaded){
            videoList = (
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '1rem'}}>
                    <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fill, 216px)', justifyContent: 'center', width: '100%', maxWidth: 'calc(864px + 4rem)', boxSizing: 'border-box', padding: '2rem'}}>
                        {
                        this.props.stateSearchVideos.videos.map(row => ( 
                            <VideoCard
                                key={row.id}
                                id={row.id}
                                views={row.views}
                                GCPid={row.GCPid}
                                title={row.title}
                                dateUploaded={row.dateUploaded} 
                                ownerId={row.ownerId}
                                ownerName={row.ownerName}
                            />
                        ))
                        }
                    </div>
                    {loadMoreVideos}
                    
                </div>
                
            );
        }

        

        return (
            <div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Typography className={this.props.classes.headerName}>{this.props.stateSearchVideos.videoCount + ' videos matched'}</Typography>
                </div>
                {videoList}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        stateSearchVideos: state.app.searchVideos,
        stateSearchVideosLoaded: state.app.searchVideosLoaded,
        stateSearchVideosLoading: state.app.searchVideosLoading,
        stateMoreSearchVideosLoading: state.app.moreSearchVideosLoading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        fetchSearchVideos: (name, category, owner, date, top, skip, history) => dispatch(AppActions.fetchSearchVideosBegin(name, category, owner, date, top, skip, history)),
        fetchMoreSearchVideos: (name, category, owner, date, top, skip, history) => dispatch(AppActions.fetchMoreSearchVideosBegin(name, category, owner, date, top, skip, history)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserPage)));
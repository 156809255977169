import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";

import _ from 'lodash';

const styles = theme => ({
    paper: {
        position: "absolute",
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0
    }
});

class AutoCompleteSuggestions extends Component {
    getSuggestions = (suggestionValue, { showEmpty = false } = {}) => {
        if (this.props.name === "category") {
            const inputValue = _.deburr(suggestionValue.trim()).toLowerCase();
            const inputLength = inputValue.length;
            let count = 0;

            return inputLength === 0 && !showEmpty
                ? []
                : this.props.stateVideoCategorySuggestions.filter(
                      suggestion => {
                          const keep =
                              count < 3 &&
                              suggestion.name
                                  .slice(0, inputLength)
                                  .toLowerCase() === inputValue;

                          if (keep) {
                              count += 1;
                          }

                          return keep;
                      }
                  );
        } else if (this.props.name === "owner") {
            const inputValue = _.deburr(suggestionValue.trim()).toLowerCase();
            const inputLength = inputValue.length;
            let count = 0;

            return inputLength === 0 && !showEmpty
                ? []
                : this.props.stateUserSuggestions.filter(suggestion => {
                      const keep =
                          count < 3 &&
                          suggestion.name
                              .slice(0, inputLength)
                              .toLowerCase() === inputValue;

                      if (keep) {
                          count += 1;
                      }

                      return keep;
                  });
        }
        return [];
    };

    renderSuggestion = suggestionProps => {
        const {
            suggestion,
            index,
            highlightedIndex,
            selectedItem,
            selectItem
        } = suggestionProps;
        const isHighlighted = highlightedIndex === index;
        const isSelected = (selectedItem || []).some(
            item => suggestion.id === item.id
        );
        return (
            <MenuItem
                onClick={() => selectItem(suggestion)}
                key={suggestion.id}
                selected={isHighlighted}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400
                }}
            >
                {suggestion.name}
            </MenuItem>
        );
    };

    render() {
        return (
            <Paper className={this.props.classes.paper} square>
                {(this.props.name === "owner" && !this.props.stateUserSuggestionsLoading) ||
                (this.props.name === "category" && !this.props.stateVideoCategorySuggestionsLoading) ? (
                    this.getSuggestions(this.props.inputValue).map((suggestion, index) =>
                        this.renderSuggestion({
                            suggestion,
                            index,
                            highlightedIndex: this.props.highlightedIndex,
                            selectedItem: this.props.selectedItem,
                            selectItem: this.props.selectItem
                        })
                    )
                ) : (
                    <CircularProgress size={24} />
                )}
            </Paper>
        );
    }
}

const mapStateToProps = state => {
    return {
        stateVideoCategorySuggestions: state.app.videoCategorySuggestions,
        stateVideoCategorySuggestionsLoading: state.app.videoCategorySuggestionsLoading,
        stateUserSuggestions: state.app.userSuggestions,
        stateUserSuggestionsLoading: state.app.userSuggestionsLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(AutoCompleteSuggestions));

import React, { Component } from "react";

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import { withStyles } from "@material-ui/core/styles";

import { Typography } from "@material-ui/core";

import { XYPlot,
    XAxis,
    YAxis,
    HorizontalGridLines,
    LineMarkSeries,
    Hint } from 'react-vis';

import 'react-vis/dist/style.css';

const styles = theme => ({
    root: {
        borderBottom: '2px solid #eee'
    }
});

class Plot extends Component {

    state = {
        value: null,
        hovering: false,
    };

    rememberValue = value => {
        this.setState({value});
    };

    handleMouseEnterChart = () => {
        this.setState({hovering: true});
    };

    handleMouseLeaveChart = () => {
        this.setState({hovering: false});
    };

    render() {

        return (
            <div style={{padding: '1rem'}}>
                <Typography style={{marginBottom: '1rem'}}>{this.props.title}</Typography>
                <XYPlot 
                    margin={{ bottom: 70 }} xType="ordinal" width={this.props.plotWidth} height={300}
                    onMouseEnter={this.handleMouseEnterChart}
                    onMouseLeave={this.handleMouseLeaveChart}
                >
                    <HorizontalGridLines />
                    <XAxis tickLabelAngle={-45} tickFormat={(tick, index) => index % this.props.plotAxisLabelInterval === 0 ? tick : ''} />
                    <YAxis />
                    <LineMarkSeries
                        color="#48D1CC"
                        fill="#7FFFD4"
                        onNearestX={this.rememberValue}
                        data={this.props.plotData}
                    />
                    {this.state.value && this.state.hovering ? (<Hint value={this.state.value}>
                                <div style={{backgroundColor: '#000', color: '#fff', borderRadius: '5px', boxSizing: 'border-box', padding: '.5rem'}}>
                        {`${this.state.value.y} ${this.props.label}`}
                        </div>
                    </Hint>) : null}
                </XYPlot>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Plot)));
